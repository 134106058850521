import React, { useEffect, useState } from "react";
import { FadeIn, FadeInStagger } from "../../components/utils/FadeIn";
import JobTemplate from "../../components/utils/job-template";
import GoBack from "../../components/utils/goBack";
import { Spinner } from "flowbite-react";

function ManatalPosition() {
  const [jobs, setJobs] = useState({});
  const [load, setLoad] = useState(true);

  useEffect(() => {
    (async () => {
      setLoad(false);
      const response = await fetch(
        "https://api.manatal.com/open/v3/career-page/manatal/jobs/"
      );

      if (response.ok) {
        const data = await response.json();
        setLoad(true);
        setJobs(data);
      }
    })();

    return () => setJobs({});
  }, []);

  return (
    <div className="bg-gray-300 p-4 min-h-[calc(100vh-4rem)] relative">
      <h1 className="text-3xl p-4 items-center space-x-3  rounded-2xl inline-flex bg-orange-600 w-fit text-white">
        <GoBack /> <span>Live position Manatal</span>
      </h1>

      {!load && (
        <div className=" absolute inset-0 flex justify-center items-center">
          <Spinner aria-label="Extra large spinner example" size="xl" />
        </div>
      )}

      {load && (
        <div className="mx-auto mt-10 grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 sm:mt-15 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {jobs &&
            jobs.results &&
            jobs.results.map((opening) => (
              <JobTemplate landing={false} data={opening} />
            ))}
        </div>
      )}
    </div>
  );
}

export default ManatalPosition;
