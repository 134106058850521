import {
  collection,
  deleteDoc,
  doc,
  getCountFromServer,
  getDocs,
  query,
} from "firebase/firestore";
import GoBack from "../../components/utils/goBack";
import { db } from "../../firebase";
import { useEffect, useState } from "react";
import moment from "moment";
import { Spinner } from "flowbite-react";
import { Link, useSearchParams } from "react-router-dom";

export default function ListOfArticles() {
  const [load, setLoad] = useState(true);
  const [posts, setPosts] = useState([]);
  const [count, setCount] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();

  const fetchData = async () => {
    try {
      setLoad(false);
      const q = query(collection(db, "blogs"));
      const querySnapshot = await getDocs(q);

      const postsArray = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));

      setLoad(true);
      setPosts(postsArray);
    } catch (error) {
      console.error("an error occurred while fetching", error);
    }
  };

  const fetchCount = async () => {
    try {
      setLoad(false);
      const coll = collection(db, "blogs");
      const q = query(coll);
      const snapshot = await getCountFromServer(q);
      const count = snapshot.data().count;

      setLoad(true);
      setCount(count);
    } catch (error) {
      console.error("Error fetching", error);
    }
  };

  useEffect(() => {
    const abortController = new AbortController();

    const fetchArticles = async () => {
      try {
        setLoad(false); // Commencer le chargement
        await Promise.all([fetchData(), fetchCount()]);
      } catch (error) {
        console.error("an error occurred", error);
        // Ici, gérer l'affichage d'un message d'erreur à l'utilisateur si nécessaire
      } finally {
        setLoad(true); // Terminer le chargement, que l'opération réussisse ou échoue
      }
    };

    if (!abortController.signal.aborted) {
      fetchArticles();
    }

    return () => {
      setPosts([]);
      abortController.abort(); // Optionnel : Annuler les requêtes en cours si elles n'ont pas encore abouti
    };
  }, []);

  const handleDeleteArticle = async (id) => {
    const result = window.confirm("Do you want to continue ?");

    if (result) {
      setLoad(false);
      const docRef = doc(db, "blogs", id);

      await deleteDoc(docRef).then(async () => {
        await fetchData();
      });
    }
  };

  return (
    <div className="bg-gray-300 p-4 min-h-[calc(100vh-4rem)] relative">
      <h1 className="md:text-3xl text-lg p-4 bg-orange-600 w-fit items-center flex space-x-4 rounded-2xl shadow text-white">
        <GoBack />
        <span>List of articles | {count}</span>
      </h1>

      {!load && (
        <div className="absolute flex inset-0 justify-center items-center bg-slate-400/[60%] ">
          <Spinner
            size={"xl"}
            color={"purple"}
            aria-label="Default status example"
          />
        </div>
      )}

      {load && (
        <div className="mx-auto mt-10 grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 sm:mt-15 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {posts.map((post) => (
            <article className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-gray-900 px-8 pb-8 pt-80 sm:pt-48 lg:pt-80">
              <div
                onClick={() => handleDeleteArticle(post.id)}
                className="bottom-3 absolute flex justify-center items-center bg-gray-200  right-4 border w-10 h-10 rounded-full  cursor-pointer z-50 hover:bg-gray-100"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 text-red-600"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m6 4.125 2.25 2.25m0 0 2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z"
                  />
                </svg>
              </div>
              <Link
                to={`/blog-editor?id=${post.id}`}
                className="top-3 absolute flex justify-center items-center hover:bg-gray-200 text-white hover:text-neutral-900  right-4 border w-10 h-10 rounded-full  cursor-pointer z-50 "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                  />
                </svg>
              </Link>

              {post.data.bg_url && (
                <img
                  alt={"bg_url"}
                  src={post.data.bg_url}
                  className="absolute inset-0 -z-10 h-full w-full object-cover"
                />
              )}
              {!post.data.bg_url && (
                <img
                  alt={"bg_url"}
                  src={require("../../assets/illustrations/2019.jpg")}
                  className="absolute inset-0 -z-10 h-full w-full object-cover"
                />
              )}

              <div className="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40" />
              <div className="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10" />

              <div className="flex flex-wrap items-center gap-y-1 overflow-hidden text-sm leading-6 text-gray-300">
                <time className="mr-8">
                  {moment.unix(post.data.creatAt).local().fromNow()}
                </time>
              </div>
              <h3 className="mt-3 text-lg font-semibold leading-6 text-white">
                <a>
                  <span className="absolute inset-0" />
                  {post.data.title}
                </a>
              </h3>
              <h1 className="mt-3 text-lg font-semibold pl-6 border-l-2 leading-6 text-white">
                {post.data.preview_description.slice(0, 200) + "..."}
              </h1>
            </article>
          ))}
        </div>
      )}
    </div>
  );
}
