"use client";

import {
  Button,
  Checkbox,
  Label,
  Modal,
  Spinner,
  TextInput,
  Textarea,
} from "flowbite-react";
import { useEffect, useState } from "react";
import { setOpenDi, setType, setUrl } from "../../redux/utils";
import { useDispatch, useSelector } from "react-redux";
import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useSearchParams } from "react-router-dom";

export function ModalTab({ openModal = false, setOpenModal }) {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [comment, setComment] = useState("");
  const [load, setLoad] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();

  const { urlTim, item } = useSelector((state) => state.utilsSlice);

  function onCloseModal() {
    setOpenModal(false);
    setName("");
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoad(false);

    if (searchParams.get("edit")) {
      await updateDoc(doc(db, "expertises", searchParams.get("doc")), {
        imgUrl: urlTim,
        name,
        description: comment ?? null,
      }).then((e) => {
        onCloseModal();
        setLoad(true);
        setSearchParams({
          data: JSON.stringify({
            name,
            urlTim,
            comment,
          }),
        });
      });
    } else {
      await addDoc(collection(db, "expertises"), {
        imgUrl: urlTim,
        name,
        description: comment ?? null,
      }).then((e) => {
        onCloseModal();
        setLoad(true);
        setSearchParams({
          data: e.id,
        });
      });
    }
  };

  useEffect(() => {
    setComment(item.data?.description);
    setName(item.data?.name);
    dispatch(setType("tim"));
    dispatch(setUrl(item.data?.imgUrl));
  }, [searchParams.get("doc")]);

  useEffect(() => {
    return () => {
      setComment("");
      setName("");
      dispatch(setType(""));
      dispatch(setUrl(""));
    };
  }, []);

  return (
    <>
      <Modal className=" z-[10000000000000000]" show={openModal} size="md" onClose={onCloseModal} popup>
        <Modal.Header />
        <Modal.Body>
          {!load && (
            <div className=" absolute inset-0 flex justify-center items-center">
              <Spinner aria-label="Extra large spinner example" size="xl" />
            </div>
          )}

          {load && (
            <form onSubmit={handleSubmit} className="space-y-6">
              <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                New tab
              </h3>

              <div className="grid  grid-cols-6">
                <div className="col-span-2">
                  {urlTim && (
                    <img
                      src={urlTim}
                      alt="img"
                      className="rounded-lg w-20 h-20 object-cover"
                    />
                  )}
                  {!urlTim && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-20 h-20 text-gray-200"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                      />
                    </svg>
                  )}
                </div>
                <div className=" col-span-4 p-4 flex justify-start items-center space-x-2 text-sky-800">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                    />
                  </svg>

                  <h1
                    className=" cursor-pointer"
                    type="button"
                    onClick={() => dispatch(setOpenDi("tim"))}
                  >
                    upload
                  </h1>
                </div>
              </div>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="name" value="Tab name" />
                </div>
                <TextInput
                  id="name"
                  type="text"
                  placeholder="Ai"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  required
                />
              </div>

              <div>
                <div className="mb-2 block">
                  <Label htmlFor="comment" value="Description" />
                </div>
                <Textarea
                  id="comment"
                  onChange={(event) => setComment(event.target.value)}
                  value={comment}
                  placeholder="Leave a description..."
                  rows={4}
                />
              </div>

              <div className="w-full flex justify-center">
                <Button type="submit">Save</Button>
              </div>
            </form>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
