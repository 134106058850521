import { collection, getDocs, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import { Spinner } from "flowbite-react";
import Carousel from "../components/utils/caroussel";

function Testimonal() {
  const [load, setLoad] = useState(true);
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    const abortController = new AbortController();

    const fetchTestimonials = async () => {
      try {
        setLoad(false); // Commencer le chargement
        await Promise.all([fetchData()]);
      } catch (error) {
        console.error("an error occurred", error);
        // Ici, gérer l'affichage d'un message d'erreur à l'utilisateur si nécessaire
      } finally {
        setLoad(true); // Terminer le chargement, que l'opération réussisse ou échoue
      }
    };

    if (!abortController.signal.aborted) {
      fetchTestimonials();
    }

    return () => {
      setTestimonials([]);
      abortController.abort(); // Optionnel : Annuler les requêtes en cours si elles n'ont pas encore abouti
    };
  }, []);

  const fetchData = async () => {
    try {
      setLoad(false);
      const q = query(collection(db, "testimonials"));
      const querySnapshot = await getDocs(q);

      const testimonialArray = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));

      setLoad(true);
      setTestimonials(testimonialArray);
    } catch (error) {
      console.error("an error occurred while fetching", error);
    }
  };

  return (
    testimonials.length > 0 && (
      <div class="overflow-hidden  py-12 relative  border-t-4 border-t-orange-600 sm:p-8">
        <div class="max-w-[85rem] px-4 xl:px-0 py-10 lg:pt-20  lg:pb-32 mx-auto">
          <div class="max-w-5xl mb-10 lg:mb-14">
            <h2 className="text-3xl md:text-6xl font-semibold   bg-clip-text py-2 text-transparent bg-gradient-to-r from-orange-500 to-violet-600  ">
              Loved By Companies And Candidates Across The Globe
            </h2>
          </div>
          {!load && (
            <div className="h-96">
              <div className=" absolute inset-0 flex justify-center items-center">
                <Spinner aria-label="Extra large spinner example" size="xl" />
              </div>
            </div>
          )}
 

          <Carousel items={testimonials} />
        </div>
      </div>
    )
  );
}

export default Testimonal;
