import React, { useEffect, useState } from "react";
import GoBack from "../../components/utils/goBack";
import { useDispatch, useSelector } from "react-redux";
import { setOpenDi, setUrl } from "../../redux/utils";
import { Spinner } from "flowbite-react";
import { db, storage } from "../../firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getCountFromServer,
  getDocs,
  query,
  updateDoc,
} from "firebase/firestore";
import moment from "moment";

function Item({ item, setDocument, handleDeleteEbook }) {

  
  return (
    <div className="bg-white shadow-xl  border rounded-lg ">
      <div className=" w-full flex justify-end px-2">
        <span className=" text-xs mt-2 font-semibold text-gray-600">
          {moment.unix(item.data.creatAt).local().fromNow()}
        </span>
      </div>

      <div className="flex items-center justify-center flex-col  p-4">
        <img
          src={item.data.coverUrl}
          alt=""
          className=" size-32 object-cover rounded-lg  "
        />

        <h1 className="mt-2 font-semibold truncate ">{item.data.name}</h1>

        <a
          href={item.data.url}
          className=" text-blue-500 text-sm font-semibold"
        >
          DownLoad file
        </a>
      </div>

      <div className=" mt-4">
        <div className="  inline-flex space-x-4 justify-center items-center w-full ">
          <button onClick={() => handleDeleteEbook(item.id)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="size-6 text-red-600"
            >
              <path
                fillRule="evenodd"
                d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          <button onClick={() => setDocument(item)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="size-6 text-blue-600"
            >
              <path d="M21.731 2.269a2.625 2.625 0 0 0-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 0 0 0-3.712ZM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 0 0-1.32 2.214l-.8 2.685a.75.75 0 0 0 .933.933l2.685-.8a5.25 5.25 0 0 0 2.214-1.32l8.4-8.4Z" />
              <path d="M5.25 5.25a3 3 0 0 0-3 3v10.5a3 3 0 0 0 3 3h10.5a3 3 0 0 0 3-3V13.5a.75.75 0 0 0-1.5 0v5.25a1.5 1.5 0 0 1-1.5 1.5H5.25a1.5 1.5 0 0 1-1.5-1.5V8.25a1.5 1.5 0 0 1 1.5-1.5h5.25a.75.75 0 0 0 0-1.5H5.25Z" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}

function Ebooks() 
{


  const dispatch = useDispatch();
  const { url } = useSelector((state) => state.utilsSlice);
  const [name, setName] = useState("");
  const [file, setFile] = useState("");
  const [load, setLoad] = useState(true);
  const [loadFiles, setLoadFiles] = useState(true);
  const [ebooks, setEbooks] = useState([]);
  const [count, setCount] = useState(0);
  const [document, setDocument] = useState("");



  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!document) {
      const storageRef = ref(storage, ["ebooks", file[0].name].join("/"));

      setLoad(false);
      await uploadBytes(storageRef, file[0]).then(async (uploadSnapshot) => {
        const downloadURL = await getDownloadURL(uploadSnapshot.ref);
        await addDoc(collection(db, "ebooks"), {
          name,
          url: downloadURL,
          coverUrl: url,
          creatAt: moment.utc().unix(),
        });
        setLoad(true);
        dispatch(setUrl(""));
        setName("");
        setFile("");
        fetchData();
      });
    } else {
      setLoadFiles(false);
      setLoad(false);

      await updateDoc(doc(db, "ebooks", document.id), {
        name,
        coverUrl: url,
      }).then((e) => {
        setLoadFiles(true);
        setLoad(true);
        fetchData();
        setDocument("");
      });
    }
  };

  const fetchData = async () => {
    try {
      setLoadFiles(false);
      const q = query(collection(db, "ebooks"));
      const querySnapshot = await getDocs(q);

      const postsArray = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));

      setLoadFiles(true);
      setLoad(true);
      setEbooks(postsArray);
    } catch (error) {
      console.error("an error occurred while fetching", error);
    }
  };

  const fetchCount = async () => {
    try {
      setLoadFiles(false);
      const coll = collection(db, "ebooks");
      const q = query(coll);
      const snapshot = await getCountFromServer(q);
      const count = snapshot.data().count;

      setLoadFiles(true);
      setLoad(true);
      setCount(count);
    } catch (error) {
      console.error("Error fetching", error);
    }
  };

  useEffect(() => {
    const abortController = new AbortController();

    const fetchEbooks = async () => {
      try {
        setLoadFiles(false); // Commencer le chargement
        await Promise.all([fetchData(), fetchCount()]);
      } catch (error) {
        console.error("an error occurred", error);
        // Ici, gérer l'affichage d'un message d'erreur à l'utilisateur si nécessaire
      } finally {
        setLoadFiles(true); // Terminer le chargement, que l'opération réussisse ou échoue
      }
    };

    if (!abortController.signal.aborted) {
      fetchEbooks();
    }

    return () => {
      setEbooks([]);
      abortController.abort(); // Optionnel : Annuler les requêtes en cours si elles n'ont pas encore abouti
    };
  }, []);

  useEffect(() => {
    if (document) {
      setName(document.data.name);
      dispatch(setUrl(document.data.coverUrl));
    }

    return () => dispatch(setUrl(""));
  }, [document]);

  const handleDeleteEbook = async (id) => {
    const result = window.confirm("Do you want to continue ?");

    if (result) {
      setLoad(false);
      const docRef = doc(db, "ebooks", id);

      await deleteDoc(docRef).then(async () => {
        await fetchData();
      });
    }
  };

  return (
    <div className="h-[calc(100vh-4rem)] overflow-hidden p-4 bg-white">
      <h1 className=" text-lg md:text-3xl p-4 bg-orange-600 w-fit items-center flex space-x-4 rounded-2xl shadow text-white">
        <GoBack />
        <span>Ebooks | {count} </span>
      </h1>

      <div className=" mt-10 grid gap-4 md:grid-cols-12  ">
        <div className="md:col-span-4">
          <form
            onSubmit={handleSubmit}
            className="  border  p-4 rounded-lg shadow-lg"
          >
            <div className="flex items-center space-x-3 ">
              <div className=" border rounded-lg size-20 md:size-32">
                {!url && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="size-full text-gray-400"
                  >
                    <path d="M12 9a3.75 3.75 0 1 0 0 7.5A3.75 3.75 0 0 0 12 9Z" />
                    <path
                      fillRule="evenodd"
                      d="M9.344 3.071a49.52 49.52 0 0 1 5.312 0c.967.052 1.83.585 2.332 1.39l.821 1.317c.24.383.645.643 1.11.71.386.054.77.113 1.152.177 1.432.239 2.429 1.493 2.429 2.909V18a3 3 0 0 1-3 3h-15a3 3 0 0 1-3-3V9.574c0-1.416.997-2.67 2.429-2.909.382-.064.766-.123 1.151-.178a1.56 1.56 0 0 0 1.11-.71l.822-1.315a2.942 2.942 0 0 1 2.332-1.39ZM6.75 12.75a5.25 5.25 0 1 1 10.5 0 5.25 5.25 0 0 1-10.5 0Zm12-1.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
                {url && (
                  <img
                    src={url}
                    className=" object-cover size-full rounded-lg"
                    alt=""
                    srcset=""
                  />
                )}
              </div>

              <button
                type="button"
                onClick={() => dispatch(setOpenDi("ed"))}
                className=" inline-flex items-center gap-2  animate-pulse bg-indigo-100 text-indigo-900 font-semibold p-4 rounded-lg  md:text-base text-sm"
              >
                <span className=" ">
                  Upload Picture <span className="text-red-800">*</span>
                </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                  />
                </svg>
              </button>
            </div>
            <div className=" relative mt-5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="size-10 absolute top-1/2 left-4 text-gray-400 transform -translate-y-1/2"
              >
                <path d="M11.25 4.533A9.707 9.707 0 0 0 6 3a9.735 9.735 0 0 0-3.25.555.75.75 0 0 0-.5.707v14.25a.75.75 0 0 0 1 .707A8.237 8.237 0 0 1 6 18.75c1.995 0 3.823.707 5.25 1.886V4.533ZM12.75 20.636A8.214 8.214 0 0 1 18 18.75c.966 0 1.89.166 2.75.47a.75.75 0 0 0 1-.708V4.262a.75.75 0 0 0-.5-.707A9.735 9.735 0 0 0 18 3a9.707 9.707 0 0 0-5.25 1.533v16.103Z" />
              </svg>

              <div className="mt-2">
                <input
                  type="text"
                  name="ebook"
                  id="ebook"
                  value={name}
                  onChange={(e) => setName(e.currentTarget.value)}
                  className="block w-full md:text-base text-sm pl-20 rounded-t-lg border-0 p-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600  sm:leading-6"
                  placeholder="Ai Hiring eMagine"
                />
              </div>
            </div>

            {!document && (
              <div className="">
                <button
                  type="button"
                  onClick={(e) => e.currentTarget.nextElementSibling.click()}
                  className=" bg-indigo-800 p-4 w-full  flex justify-center items-center rounded-b-lg  md:text-base text-sm"
                >
                  {" "}
                  {!file[0] && (
                    <span className=" text-white">Upload Your Ebook</span>
                  )}
                  {file[0] && (
                    <span className=" text-white">{file[0].name}</span>
                  )}
                </button>
                <input
                  type="file"
                  onChange={(e) => setFile(e.currentTarget.files)}
                  className="hidden"
                  name="ebook"
                  required
                  id=""
                />
              </div>
            )}

            <div className="mt-5 flex justify-end  ">
              {document && (
                <button
                  onClick={() => setDocument("")}
                  className="bg-red-600 shadow me-4 md:text-base text-sm flex justify-center items-center  py-2 px-6 text-white rounded-lg"
                >
                  <span>Cancel</span>
                </button>
              )}

              <button
                disabled={!url}
                className="bg-blue-600 shadow disabled:bg-gray-300  md:text-base text-sm flex justify-center items-center  py-2 px-6 text-white rounded-lg"
              >
                {load && <span>Send</span>}
                {!load && <Spinner />}
              </button>
            </div>
          </form>
        </div>

        <div className=" md:col-span-8">
          <div className=" gap-4 grid sm:grid-cols-2 md:grid-cols-3">
            {loadFiles &&
              ebooks.map((el) => (
                <Item
                  handleDeleteEbook={handleDeleteEbook}
                  setDocument={setDocument}
                  item={el}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Ebooks;
