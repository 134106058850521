import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";
import { db } from "../../firebase";

export function RequireAuth({ children }) {
  const [searchParams] = useSearchParams();
  const [invitation, setInvitation] = useState([]);
  const [isValidToken, setIsValidToken] = useState(null); // Initialement à null pour indiquer le chargement

  useEffect(() => {
    const fetchData = async () => {
      const token = searchParams.get("token");
      const email = searchParams.get("email");

      try {
        const q = query(
          collection(db, "invitation"),
          where("token", "==", token),
          where("email", "==", email)
        );

        const querySnapshot = await getDocs(q);
        const invitationArray = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }));

        if (invitationArray.length > 0) {
          setInvitation(invitationArray);
          setIsValidToken(true);
        } else {
          setIsValidToken(false);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des invitations:", error);
        setIsValidToken(false); // Considérer comme invalide en cas d'erreur
      }
    };

    fetchData();
  }, [searchParams]);

  // Gérer la redirection ou l'affichage en fonction de la validité du token
  if (isValidToken === null) {
    return <div>Chargement...</div>; // ou un composant de chargement
  } else if (!isValidToken) {
    return <Navigate to="/not-found" replace />;
  } else {
    return children; // Token valide, afficher le composant enfant
  }
}
