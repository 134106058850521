import React, { useEffect, useState } from "react";
import GoBack from "../components/utils/goBack";
import { FadeIn } from "../components/utils/FadeIn";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import { Container } from "../components/utils/Container";
import moment from "moment";
import { Spinner } from "flowbite-react";

function ArticleDetail() {
  const [post, setPost] = useState({});
  const { id } = useParams();
  const [load, setLoad] = useState(true);

  useEffect(() => {
    const abortController = new AbortController();

    const fetchPost = async () => {
      try {
        setLoad(false); // Commencer le chargement
        await Promise.all([fetchData()]);
      } catch (error) {
        console.error("an error occurred", error);
        // Ici, gérer l'affichage d'un message d'erreur à l'utilisateur si nécessaire
      } finally {
        setLoad(true); // Terminer le chargement, que l'opération réussisse ou échoue
      }
    };

    if (!abortController.signal.aborted) {
      fetchPost();
    }

    return () => {
      setPost({});
      abortController.abort(); // Optionnel : Annuler les requêtes en cours si elles n'ont pas encore abouti
    };
  }, [id]);

  console.log(id);

  const fetchData = async () => {
    try {
      setLoad(false);

      const docRef = doc(db, "blogs", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setLoad(true);
        setPost(docSnap.data());
      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }
    } catch (error) {
      console.error("an error occurred while fetching", error);
    }
  };

  console.log(post);

  return (
    <div className="relative overflow-hidden min-h-screen">
      <div className="absolute w-[700px] h-[700px] rounded-full  -z-0 bg-indigo-800 opacity-30 blur-3xl" />
      <div className="absolute w-[700px] h-[700px] rounded-full -z-0  right-1/2  bg-orange-800 opacity-30 blur-3xl" />
      <div className="absolute w-[700px] h-[700px] rounded-full -z-0  right-1/4  bg-violett-800 opacity-30 blur-3xl" />
      <div className="absolute w-[700px] h-[700px] rounded-full -z-0  bottom-1/2 left-1/2  bg-teal-800 opacity-30 blur-3xl" />
      <div className="absolute w-[700px] h-[700px] rounded-full -z-0  bottom-1/2 top-1/2 -right-20  bg-teal-800 opacity-30 blur-3xl" />
      <div className="absolute w-[900px] h-[900px] rounded-full -z-0  bottom-0 left-1/3  bg-fuchsia-800 opacity-30 blur-3xl" />
      <div className=" pt-32 overflow-hidden relative bg-gradient-to-br z-10 ">
        {!load && (
          <div className="h-96">
            <div className=" absolute inset-0 flex justify-center items-center">
              <Spinner aria-label="Extra large spinner example" size="xl" />
            </div>
          </div>
        )}

        {load && (
          <div class="max-w-[85rem] px-4   lg:px-8  mx-auto">
            <FadeIn className=" flex items-center  ">
              <div className="grid relative  sm:grid-cols-12 gap-4  ">
                <div className=" col-span-5 ">
                  <div className="  ">
                    {post.bg_url && (
                      <div className=" w-full h-full border rounded-lg">
                        <img
                          src={post.bg_url}
                          alt=""
                          className="  w-full h-full    rounded-lg  object-cover"
                        />
                      </div>
                    )}
                  </div>
                  <div className="flex col-span-8  flex-col  text-4xl md:text-5xl lg:text-7xl  ">
                    <h1 className="bg-clip-text py-2 text-transparent font-bold bg-gradient-to-r break-words whitespace-wrap from-orange-500 to-violet-600">
                      {post.title}
                    </h1>

                    <div className="flex flex-col text-sm md:text-lg mt-4">
                      <span className=" inline-flex  space-x-3 items-center text-gray-300">
                        <h1>{moment.unix(post.creatAt).local().fromNow()}</h1>{" "}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className="px-8 col-span-7 py-10 relative  bg-gradient-to-bl from-orange-600/20  to-violet-400/30  shadow-md   rounded-lg"
                  dangerouslySetInnerHTML={{ __html: post.description }}
                ></div>
              </div>
            </FadeIn>
          </div>
        )}
      </div>
    </div>
  );
}

export default ArticleDetail;
