import {
  collection,
  getCountFromServer,
  getDocs,
  limit,
  orderBy,
  query,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { db } from "../../firebase";
import { Spinner } from "flowbite-react";
import moment from "moment";
import GoBack from "../../components/utils/goBack";
import { BiLogoLinkedin } from "react-icons/bi";

function Document({ doc }) {
  return (
    <div class=" relative flex-shrink-0  p-4 pb-16 bg-white  border border-indigo-800 rounded-lg  overflow-hidden   ">
      <div className=" flex flex-col gap-4">
        <div className="   flex flex-wrap flex-col ">
          <h1 className="text-base font-semibold  text-start">
            {doc.data.candidate_name}
          </h1>
          <span className=" text-gray-500 text-sm text-wrap break-words">
            {" "}
            {doc.data.title}
          </span>
          <div className="w-full flex flex-wrap justify-end  gap-4 mt-4 ">
            <a
              href={`tel:${doc.data.candidate_contact_number}`}
              className=" bg-orange-600 rounded-lg flex justify-center items-center p-4 text-gray-100"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14.25 9.75v-4.5m0 4.5h4.5m-4.5 0 6-6m-3 18c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 0 1 4.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 0 0-.38 1.21 12.035 12.035 0 0 0 7.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 0 1 1.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 0 1-2.25 2.25h-2.25Z"
                />
              </svg>
            </a>
            <a
              href={`mailto:${doc.data.candidate_email}`}
              className=" bg-orange-600 rounded-lg flex justify-center items-center p-4 text-gray-100"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                />
              </svg>
            </a>
            <a
              href={doc.data.candidate_linkedin}
              className=" bg-orange-600 rounded-lg flex justify-center items-center p-4 text-gray-100"
            >
              <BiLogoLinkedin className="size-4" />
            </a>
          </div>
        </div>
        <div className="inline-flex justify-end w-full gap-4 col-span-full">
          <span className="text-xs">
            {moment.unix(doc.data.creatAt).local().fromNow()}
          </span>
        </div>

        {doc.data.comment && (
          <div className=" max-h-36 overflow-hidden hover:overflow-y-auto bg-neutral-800 text-wrap break-words p-4 w-full rounded-lg text-white ">
            {doc.data.comment}
          </div>
        )}

        <div className="  inline-flex justify-end text-indigo-800 w-full space-x-1 col-span-full  ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244"
            />
          </svg>

          <a
            href={doc.data.link}
            target="_blank"
            className="text-sm  font-medium   first-letter:uppercase"
          >
            Download cv
          </a>
        </div>

        <div className=" absolute text-gray-200 font-semibold bottom-0 flex items-center h-12 p-4 right-0 w-full  bg-orange-600">
          <h1 className=" text-sm">
            {doc.data.position_name
              ? [doc.data.position_name, "Applied"].join(" | ")
              : "Droped Cv"}
          </h1>
        </div>
      </div>

      {/*      <div className="absolute shadow-lg right-3 border border-b-0 bg-blue-600 rounded-t-md bottom-0 w-fit border-gray-300/25  py-1 px-2 ">
        <a
          href={doc.data.link}
          target="_blank"
          className="text-xs font-medium animate-pulse text-gray-100 first-letter:uppercase"
        >
          Download cv
        </a>
      </div>
      <div className="absolute shadow-lg right-[calc(0.75rem*10)] border border-b-0 bg-teal-900 rounded-t-md -bottom-0 w-fit border-gray-300/25  py-1 px-2 ">
        <a
          href={doc.data.link}
          target="_blank"
          className="text-xs font-medium animate-pulse text-gray-100 first-letter:uppercase"
        >
          {moment.unix(doc.data.creatAt).local().fromNow()}
        </a>
      </div> */}

      {/*  <div>
        <div class="flex flex-col justify-between p-4 leading-normal">
          <div>
            <h5 class=" text-xl font-bold tracking-tight truncate text-gray-900 dark:text-white">
              {doc.data.candidate_name}
            </h5>
            <h1 className="text-sm text-gray-400">{doc.data.title}</h1>
          </div>

          {doc.data.position_id && (
            <Link
              to={`/one-client/${doc.data.position_id}`}
              class="mb-2 text-sm  tracking-tight text-gray-500 dark:text-white"
            >
              <span>Applied for : {doc.data.position_name} </span>{" "}
              <span>|</span>
              {doc.data.organization_name}
            </Link>
          )}

          <div className="flex flex-col text-sm text-gray-400 mt-3 border-orange-600 border-l-2 pl-4 ">
            <a href={`mailto:${doc.data.candidate_email}`}>
              Email : {doc.data.candidate_email}
            </a>
            <a href={`tel:${doc.data.candidate_contact_number}`}>
              Tel : {doc.data.candidate_contact_number}{" "}
            </a>
            <a href={doc.data.candidate_linkedin}>
              Linkedin : {doc.data.candidate_linkedin}{" "}
            </a>
          </div>
        </div>
      </div> */}
    </div>
  );
}

function Candidate() {
  const [load, setLoad] = useState(true);
  const [candidate, setCandidate] = useState([]);

  const [count, setCount] = useState(0);
  const [lastVisible, setLastVisible] = useState("");
  const [firstVisible, setFirstVisible] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();

  console.log(searchParams.get("where"));

  const fetchData = async () => {
    try {
      setLoad(false);

      const q = query(collection(db, "candidate"), orderBy("creatAt", "desc"));

      const querySnapshot = await getDocs(q);
      const last = querySnapshot.docs[querySnapshot.docs.length - 1];
      const first = querySnapshot.docs[0];
      setLastVisible(last);
      setFirstVisible(first);
      const candidateArray = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));

      setLoad(true);
      setCandidate(candidateArray); // Mise à jour de l'état en une seule opération
    } catch (error) {
      console.error("Erreur lors de la récupération des clients:", error);
      // Gérer l'erreur comme il convient (par exemple, en définissant un état d'erreur)
    }
  };

  const fetchCount = async () => {
    try {
      setLoad(false);
      const coll = collection(db, "candidate");
      const q = query(coll);
      const snapshot = await getCountFromServer(q);
      const count = snapshot.data().count;

      setLoad(true);
      setCount(count);
    } catch (error) {
      console.error("Error fetching", error);
    }
  };

  useEffect(() => {
    const abortController = new AbortController();

    const fetchCandidate = async () => {
      try {
        setLoad(false); // Commencer le chargement
        await Promise.all([fetchData(), fetchCount()]);
      } catch (error) {
        console.error("an error occurred", error);
        // Ici, gérer l'affichage d'un message d'erreur à l'utilisateur si nécessaire
      } finally {
        setLoad(true); // Terminer le chargement, que l'opération réussisse ou échoue
      }
    };

    if (!abortController.signal.aborted) {
      fetchCandidate();
    }

    return () => {
      abortController.abort(); // Optionnel : Annuler les requêtes en cours si elles n'ont pas encore abouti
    };
  }, []);

  console.log(candidate);

  return (
    <div className="bg-gray-300 p-4 min-h-[calc(100vh-4rem)] relative">
      <h1 className="text-lg md:text-3xl p-4 bg-orange-600 space-x-3 rounded-2xl w-fit inline-flex items-center shadow text-white">
        <GoBack />
        <span>Candidates</span> <span>|</span>{" "}
        <span className=" font-bold"> {count}</span>
      </h1>

      {!load && (
        <div className=" absolute inset-0 flex justify-center items-center">
          <Spinner aria-label="Extra large spinner example" size="xl" />
        </div>
      )}

      <div className=" grid md:grid-cols-12">
        <div className="  col-span-4 h-[calc(100vh-14rem)]  border p-4 rounded-lg  overflow-hidden hover:overflow-y-scroll flex flex-col gap-4 mt-4">
          {candidate && candidate.map((person) => <Document doc={person} />)}
        </div>
      </div>
    </div>
  );
}

export default Candidate;
