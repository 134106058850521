import { collection, deleteDoc, doc, getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { Spinner } from "flowbite-react";
import { Link, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setItem } from "../../redux/utils";

function ExpertiseList({ setOpenModal }) {
  const [load, setLoad] = useState(true);
  const [tabs, setTabs] = useState([]);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const abortController = new AbortController();

    const fetchTabs = async () => {
      try {
        setLoad(false); // Commencer le chargement
        await Promise.all([fetchData()]);
      } catch (error) {
        console.error("an error occurred", error);
        // Ici, gérer l'affichage d'un message d'erreur à l'utilisateur si nécessaire
      } finally {
        setLoad(true); // Terminer le chargement, que l'opération réussisse ou échoue
      }
    };

    if (!abortController.signal.aborted) {
      fetchTabs();
    }

    return () => {
      setTabs([]);

      abortController.abort(); // Optionnel : Annuler les requêtes en cours si elles n'ont pas encore abouti
    };
  }, [searchParams.get("data")]);

  const fetchData = async () => {
    try {
      setLoad(false);
      const querySnapshot = await getDocs(collection(db, "expertises"));

      const postsArray = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));

      setLoad(true);
      setTabs(postsArray);
    } catch (error) {
      console.error("an error occurred while fetching", error);
    }
  };

  const handleDelete = async (id) => {
    const result = window.confirm("Do you want to continue ?");

    if (result) {
      setLoad(false);
      await deleteDoc(doc(db, "expertises", id)).then(async () => {
        await Promise.all([fetchData()]);
      });
    }
  };

  return (
    <div className="p-4 min-h-[calc(100vh-4rem)] bg-slate-50 grid grid-cols-12 ">
      {!load && (
        <div className=" absolute inset-0 flex justify-center items-center">
          <Spinner aria-label="Extra large spinner example" size="xl" />
        </div>
      )}
      {load && (
        <div className="col-span-full    gap-4 sm:p-4">
          <h1 className="text-lg font-bold">Expertise Tabs</h1>
          <div className="flex justify-end">
            <div
              onClick={() => setOpenModal(true)}
              className="border-2 border-dashed my-4 cursor-pointer text-center text-lg text-orange-800 p-4 rounded-lg animate-pulse"
            >
              Add new tab
            </div>
          </div>

          <div className="grid  lg:grid-cols-2 xl:grid-cols-4 gap-4  ">
            {tabs &&
              tabs.map((el) => (
                <div className="border-2  pb-10 cursor-pointer border-dashed flex flex-col gap-3  justify-center items-center text-center text-lg text-orange-800 p-4 rounded-lg">
                  <Link
                    className="flex justify-center flex-col items-center"
                    to={`.?id=${el.id}`}
                  >
                    <div className="w-16 h-16  rounded-lg">
                      <img
                        src={el.data.imgUrl}
                        alt=""
                        className="w-full h-full rounded-lg object-contain"
                        srcset=""
                      />
                    </div>

                    <h1>{el.data.name}</h1>
                    <p className="text-sm ">{el.data.description}</p>
                  </Link>

                  <div className=" inline-flex space-x-4 z-[100000] ">
                    <div
                      onClick={(e) => {
                        e.stopPropagation();

                        dispatch(setItem(el));
                        setOpenModal(true);
                        setSearchParams({
                          edit: true,
                          doc: el.id,
                        });
                      }}
                      className=" cursor-pointer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                        />
                      </svg>
                    </div>

                    <div
                      onClick={() => handleDelete(el.id)}
                      className=" cursor-pointer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m6 4.125 2.25 2.25m0 0 2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default ExpertiseList;
