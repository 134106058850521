import Home from "./landing/index";
import "./assets/css/index.css";
import "./assets/css/typography.css";
import { LandingRoute } from "./landing/Route";
import AdminRoute from "./admin/route";
import { IStaticMethods } from "preline/preline";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { auth, db } from "./firebase";
import { onAuthStateChanged } from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import { setIsConnected, setUser, setUserExtraData } from "./redux/utils";
import { doc, getDoc } from "firebase/firestore";
function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setUseSearchParams] = useSearchParams();
  const { userData, isConnected } = useSelector((state) => state.utilsSlice);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const abortController = new AbortController();

    const isConnectedStored = localStorage.getItem("isConnected") === "true";
    setIsConnected(isConnectedStored);

    onAuthStateChanged(
      auth,
      (user) => {
        if (user) {
          dispatch(setIsConnected(true));
          localStorage.setItem("isConnected", "true");
          dispatch(setUser(user));
        } else {
          dispatch(setIsConnected(false));

          localStorage.removeItem("isConnected");
        }

        setIsLoading(false);
      },
      (error) => {
        console.log(error);
        setIsLoading(false);
      }
    );

    return () => {
      abortController.abort(); // Optionnel : Annuler les requêtes en cours si elles n'ont pas encore abouti
    };
  }, []);

  return !isLoading ? (
    <div className="App bg-black">
      {!isConnected && <LandingRoute />}
      {isConnected && <AdminRoute />}
    </div>
  ) : (
    <div className="h-screen w-screen flex justify-center items-center">
      <svg
        className="w-28 h-28  transition-all duration-500 ease-in-out animate-pulse fill-slate-100 hover:fill-orange-600"
        viewBox="0 0 81 90"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M5.15924 18.8315L17.0255 27.8804L42.0478 14.1848L64.2325 27.8804L75.3248 18.8315L42.0478 0L5.15924 18.8315Z" />
        <path d="M0 27.8804L12.6401 36.6848V60.8967L42.0478 76.7935L81 53.0707V66.2772L42.0478 90L0 66.2772V27.8804Z" />
        <path d="M22.7006 55.0272V43.0435L42.0478 55.0272L81 29.5924V40.3533L42.0478 66.2772L22.7006 55.0272Z" />
        <path d="M25.7962 32.7717L42.0478 23.7228L55.9777 32.7717L42.0478 43.0435L25.7962 32.7717Z" />
      </svg>
    </div>
  );
}

export default App;
