import React, { useEffect, useRef, useState } from "react";
import { Container } from "../../components/utils/Container";
import GoBack from "../../components/utils/goBack";
import { Border } from "../../components/utils/Border";
import { currencies } from "currencies.json";
import { BiItalic } from "react-icons/bi";
import {
  AiOutlineUnorderedList,
  AiOutlineOrderedList,
  AiOutlineUnderline,
} from "react-icons/ai";
import { db } from "../../firebase";
import { addDoc, collection, doc, getDoc, getDocs } from "firebase/firestore";
import { Spinner, Toast } from "flowbite-react";
import moment from "moment";
import JobEditor from "./editor-job";
import ToggleEnabled from "./Toggle";

function CreatePosition() {
  const editorRef = useRef(null);
  const submitRef = useRef(null);
  const resetRef = useRef(null);
  const [isRemote, setIsRemote] = useState(false);
  const [contentHtml, setContentHtml] = useState("");
  const [getEditorRef, setGetEditorRef] = useState("");
  const [clients, setClients] = useState([]);
  const [load, setLoad] = useState(true);
  const [showToast, setShowToast] = useState(false);
  const [jobShow, setJobShow] = useState("job_preview");
  const [enabledSalary, setEnabledSalary] = useState(false);
  const [enabledManatal, setEnabledManatal] = useState(false);
  const [enableHeadCount, setEnabledHeadCount] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "clients"));
        const clientsArray = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }));
        setClients(clientsArray); // Mise à jour de l'état en une seule opération
      } catch (error) {
        console.error("Erreur lors de la récupération des clients:", error);
        // Gérer l'erreur comme il convient (par exemple, en définissant un état d'erreur)
      }
    };

    fetchData();
  }, []); // Le tableau de dépendances vide assure que l'effet ne s'exécute qu'au montage

  console.log(contentHtml);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoad(false);
    let formData = new FormData(e.currentTarget);
    const formProps = Object.fromEntries(formData);

    const params = {
      position_name: formProps.position,
      gmp: formProps.gmp ?? null,
      location: formProps.country,
      country: formProps.country,
      city: formProps.city,
      is_remote: isRemote,
      salary_shown: enabledSalary,
      manatalLinked: enabledManatal,
      frequency: formProps.frequency ?? null,
      min_salary: formProps.min_salary ?? null,
      max_salary: formProps.max_salary ?? null,
      currency: formProps.currency ?? null,
      description: getEditorRef.current.innerHTML,
      organization: formProps.client_id,
      preview_description: formProps.preview_description,
      open: true,
      headcount: !enableHeadCount ? false : formProps.headcount,
      zone: formProps.zone,
      experience: formProps.experience,
      contract_details: formProps.contract_details,
      creatAt: moment.utc().unix(),
    };

    console.log(params);

    const docRef = await addDoc(collection(db, "jobs"), params).then(() => {
      setLoad(true);
      resetRef.current.click();
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
      }, 3000);
    });
  };

  const handleChangePage = (name) => {
    setJobShow(name);
  };

  return (
    <div className="bg-gray-300 relative   min-h-[calc(100vh-4rem)]">
      <div className="  flex flex-col p-4">
        <h1 className="text-sm space-x-4  inline-flex items-center">
          {" "}
          <GoBack /> <span>New position</span>
        </h1>
        <div className=" inline-flex w-full justify-end items-center  ">
          <button
            disabled={!getEditorRef.current?.innerHTML}
            onClick={() => submitRef.current.click()}
            className="rounded-md disabled:bg-slate-400  bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          >
            {!load && <Spinner aria-label="Spinner button example" size="sm" />}
            {load && <span>Publish</span>}
          </button>
        </div>
      </div>

      {showToast && (
        <Toast className="fixed bottom-4 right-2 bg-green-50">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="w-6 h-6 text-green-500 -rotate-45 "
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
            />
          </svg>
          <div className="pl-4 text-sm font-normal">Published with success</div>
        </Toast>
      )}

      <form onSubmit={handleSubmit} className=" p-4">
        <div className=" py-4 flex gap-2">
          <button
            type="button"
            onClick={() => handleChangePage("job_preview")}
            className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          >
            Job Preview
          </button>
          <button
            type="button"
            onClick={() => handleChangePage("job_details")}
            className={`rounded-md bg-indigo-500 px-3 ${
              !getEditorRef.current?.innerHTML && "animate-pulse"
            } py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500`}
          >
            Job Details
          </button>
        </div>

        <div className={`${jobShow === "job_preview" ? "" : "hidden"}`}>
          <div className="grid md:grid-cols-4 gap-4  ">
            <div className="bg-white border rounded-lg shadow-sm">
              <input
                type="text"
                name="position"
                required
                id="position"
                className="block   border-0 bg-transparent  w-full h-20  text-lf font-semibold "
                placeholder="Engineer"
              />
            </div>
            <div className="bg-white rounded-lg border shadow-sm">
              <select
                id="client_id"
                required
                name="client_id"
                className="block    border-0 bg-transparent  w-full h-20  text-lg font-semibold"
                defaultValue="Canada"
              >
                {clients &&
                  clients.map((client) => (
                    <option value={client.id}>{client.data.name}</option>
                  ))}
              </select>
            </div>
            <div className="bg-white rounded-lg border shadow-sm">
              <div className="">
                <select
                  id="zone"
                  name="zone"
                  required
                  className="block  capitalize  border-0 bg-transparent  w-full h-20  text-lg font-semibold"
                  defaultValue="Middle East"
                >
                  <option value={"middle_east"}>Middle East</option>
                  <option value={"europe"}>Europe</option>
                  <option value={"africa"}>Africa</option>
                </select>
              </div>
            </div>

            <div className=" flex items-center px-4 h-20 bg-white rounded-lg border shadow-sm">
              <div className="inline-flex items-center space-x-4 ext-xl font-semibold">
                <input
                  id="is_remote"
                  aria-describedby="comments-description"
                  name="is_remote"
                  type="checkbox"
                  onChange={(e) => setIsRemote(e.currentTarget.checked)}
                  className=" size-8 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />

                <label
                  htmlFor="is_remote"
                  className="block   leading-6 text-gray-900"
                >
                  Remote ?
                </label>
              </div>
            </div>
          </div>

          <div className="grid md:grid-cols-4 mt-4 gap-4">
            <div className=" bg-white p-4 rounded-lg relative">
              <div className=" absolute right-4 top-4">
                <ToggleEnabled
                  enabled={enabledSalary}
                  setEnabled={setEnabledSalary}
                />
              </div>

              <h1 className=" text-xl font-semibold text-gray-400">Salary</h1>

              <div className="my-6  space-y-4">
                <div>
                  <label
                    htmlFor="currency"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Currency
                  </label>
                  <div className="mt-2">
                    <select
                      disabled={!enabledSalary}
                      id="currency"
                      name="currency"
                      className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      defaultValue="Canada"
                    >
                      {currencies.map((currency) => (
                        <option>{currency.symbol}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="min_salary"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Add minimum salary
                  </label>
                  <div className="mt-2">
                    <input
                      type="number"
                      disabled={!enabledSalary}
                      min={0}
                      name="min_salary"
                      id="min_salary"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="2200"
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="max_salary"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Add maximum salary
                  </label>
                  <div className="mt-2">
                    <input
                      type="number"
                      disabled={!enabledSalary}
                      min={1}
                      name="max_salary"
                      id="max_salary"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="3000"
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="frequency"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Frequency
                  </label>
                  <div className="mt-2">
                    <select
                      id="frequency"
                      disabled={!enabledSalary}
                      name="frequency"
                      className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      defaultValue="Canada"
                    >
                      <option>Monthly</option>
                      <option>Annually</option>
                      <option>Weekly</option>
                      <option>Daily</option>
                      <option>Hourly</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className=" bg-white p-4 rounded-lg">
              <h1 className=" text-xl font-semibold text-gray-400">
                Expectation
              </h1>

              <div className="my-6 space-y-4">
                <div>
                  <label
                    htmlFor="headcount"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Headcount
                  </label>
                  <div className="mt-2 relative">
                    <div className="absolute  left-2 top-1/2 -translate-y-1/2">
                      <ToggleEnabled
                        enabled={enableHeadCount}
                        setEnabled={setEnabledHeadCount}
                      />
                    </div>
                    <input
                      type="number"
                      min={1}
                      disabled={!enableHeadCount}
                      name="headcount"
                      id="headcount"
                      defaultValue={1}
                      className="block w-full pl-16 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="1"
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="experience"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Experience
                  </label>
                  <div className="mt-2">
                    <select
                      id="experience"
                      name="experience"
                      className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      defaultValue="Mid Level"
                    >
                      <option value={"mid-level"}>Mid Level</option>
                      <option value={"director"}>Director</option>
                      <option value={"senior-level"}>Senior Level</option>
                    </select>
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="contract_details"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Contract details
                  </label>
                  <div className="mt-2">
                    <select
                      id="contract_details"
                      name="contract_details"
                      className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      defaultValue="Full-time"
                    >
                      <option>Full-time</option>
                      <option>Part-time</option>
                      <option>Temporary</option>
                      <option>Freelance</option>
                      <option>Internship</option>
                      <option>Apprenticeship</option>
                      <option>Contractor</option>
                      <option>Consultancy</option>
                    </select>
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="preview_description"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Leave a preview description
                  </label>
                  <div className="mt-2">
                    <textarea
                      rows={3}
                      name="preview_description"
                      id="preview_description"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      defaultValue={""}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className=" bg-white p-4 rounded-lg">
              <h1 className=" text-xl font-semibold text-gray-400">Location</h1>

              <div className="my-6 space-y-4">
                <div>
                  <label
                    htmlFor="country"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Country
                  </label>
                  <input
                    type="text"
                    name="country"
                    id="country"
                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="UAE"
                  />

                  <div className="mt-2">
                    <label
                      htmlFor="city"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      City
                    </label>
                    <input
                      type="text"
                      name="city"
                      id="city"
                      className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="Dubaï"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className=" bg-white p-4 rounded-lg relative">
              <div className=" absolute right-4 top-4">
                <ToggleEnabled
                  enabled={enabledManatal}
                  setEnabled={setEnabledManatal}
                />
              </div>
              <h1 className=" text-xl font-semibold text-gray-400">Manatal</h1>

              <div className="my-6 space-y-4">
                <div className=" ">
                  <label
                    htmlFor="gmp"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Job’s email
                  </label>
                  <div className="mt-2">
                    <input
                      disabled={!enabledManatal}
                      type="text"
                      name="gmp"
                      required={enabledManatal}
                      id="gmp"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="emagine.solutions+candidate+....@mail.manatal.com"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <JobEditor
          getHtml={setContentHtml}
          hidden={jobShow !== "job_details"}
          getEditorRef={setGetEditorRef}
        />

        <button ref={submitRef} type="submit" className="hidden"></button>
        <button ref={resetRef} type="reset" className="hidden"></button>
      </form>
    </div>
  );
}

export default CreatePosition;
