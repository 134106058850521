import { collection, getDocs, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import { Spinner } from "flowbite-react";
import { ModalGetData } from "../components/modals/modal-get-data";

function Item({ item, setDocument, setBrochureId, setOpenModal }) {
  return (
    <div className=" cursor-pointer relative shrink-0  transform bg-gray-800 size-80 rounded-lg border ">
      <div className=" absolute inset-0 bg-gradient-to-t from-orange-800 rounded-lg p-4 flex-col flex justify-end">
        <div className="w-full   h-20  flex flex-col ">
          <h1 className=" text-gray-200 text-sm font-medium md:text-xl">
            {item.data.name}
          </h1>
        </div>

        <div className=" flex  w-full justify-end mt-5">
          <button
            onClick={() => {
              setOpenModal(true);
              setDocument(item.data.url);
              setBrochureId(item.data.name);
            }}
            className="text-orange-400  inline-flex space-x-2 font-semibold cursor-pointer"
          >
            <span> Get it</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="size-6"
            >
              <path
                fillRule="evenodd"
                d="M12 2.25a.75.75 0 0 1 .75.75v11.69l3.22-3.22a.75.75 0 1 1 1.06 1.06l-4.5 4.5a.75.75 0 0 1-1.06 0l-4.5-4.5a.75.75 0 1 1 1.06-1.06l3.22 3.22V3a.75.75 0 0 1 .75-.75Zm-9 13.5a.75.75 0 0 1 .75.75v2.25a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5V16.5a.75.75 0 0 1 1.5 0v2.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V16.5a.75.75 0 0 1 .75-.75Z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>

      <img
        src={item.data.coverUrl}
        className=" size-full rounded-lg object-cover"
        alt=""
        srcset=""
      />
    </div>
  );
}

function Ebooks() {
  const [ebooks, setEbooks] = useState([]);
  const [loadFiles, setLoadFiles] = useState(true);
  const [brochureId, setBrochureId] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [document, setDocument] = useState(null);

  const fetchData = async () => {
    try {
      setLoadFiles(false);
      const q = query(collection(db, "ebooks"));
      const querySnapshot = await getDocs(q);

      const postsArray = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));

      setLoadFiles(true);
      setEbooks(postsArray);
    } catch (error) {
      console.error("an error occurred while fetching", error);
    }
  };

  useEffect(() => {
    const abortController = new AbortController();

    const fetchEbooks = async () => {
      try {
        setLoadFiles(false); // Commencer le chargement
        await Promise.all([fetchData()]);
      } catch (error) {
        console.error("an error occurred", error);
        // Ici, gérer l'affichage d'un message d'erreur à l'utilisateur si nécessaire
      } finally {
        setLoadFiles(true); // Terminer le chargement, que l'opération réussisse ou échoue
      }
    };

    if (!abortController.signal.aborted) {
      fetchEbooks();
    }

    return () => {
      setEbooks([]);
      abortController.abort(); // Optionnel : Annuler les requêtes en cours si elles n'ont pas encore abouti
    };
  }, []);

  return (
    <div className="from-black border-t-4 border-t-orange-600 to-orange-800/15 bg-gradient-to-b relative min-h-[600px] sm:p-8   overflow-hidden -mt-[0.85rem] z-50">
      <div className="absolute w-[700px] h-[700px] rounded-full  -z-0 bg-indigo-800 opacity-30 blur-3xl" />
      <div className="absolute w-[700px] h-[700px] rounded-full -z-0  right-2  bg-orange-800 opacity-30 blur-3xl" />
      <div className="absolute w-[700px] h-[700px] rounded-full -z-0  bottom-1/2 left-1/2  bg-teal-800 opacity-30 blur-3xl" />
      <div class="max-w-[85rem] px-4 xl:px-0   mx-auto">
        <div className="flex">
          <h1 className="text-3xl sm:text-4xl md:text-6xl font-semibold  bg-clip-text py-2 text-transparent bg-gradient-to-r from-orange-500 to-violet-600 ">
            Our Brochures
          </h1>
        </div>
        <p className="text-gray-400  sm:text-lg text-sm  mt-1   block    ">
          Your guide to best practices and processes
        </p>
        <div className="mt-10   flex overflow-hidden ">
          {!loadFiles && (
            <div className="h-96 w-full">
              <div className="  flex justify-center items-center">
                <Spinner aria-label="Extra large spinner example" size="xl" />
              </div>
            </div>
          )}
          {loadFiles && (
            <div className=" flex overflow-x-scroll gap-4">
              {ebooks &&
                ebooks.map((item) => (
                  <Item
                    item={item}
                    setOpenModal={setOpenModal}
                    setBrochureId={setBrochureId}
                    setDocument={setDocument}
                  />
                ))}
            </div>
          )}
        </div>
      </div>
      <ModalGetData
        brochures
        brochure_id={brochureId}
        openModal={openModal}
        setOpenModal={setOpenModal}
        document={document}
      />
    </div>
  );
}

export default Ebooks;
