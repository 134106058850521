import { updateProfile } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { auth, db } from "../../firebase";
import { useDispatch, useSelector } from "react-redux";
import { Spinner, Toast } from "flowbite-react";
import { setOpenDi, setUserExtraData } from "../../redux/utils";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";

function Profile() {
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [emailToInvite, setEmailToInvite] = useState("");
  const { userData, urlProfileImg, userExtraData } = useSelector(
    (state) => state.utilsSlice
  );
  const [role, setRole] = useState("");
  const [load, setLoad] = useState(true);
  const [isExist, setIsExist] = useState(false);
  const dispatch = useDispatch();
  const [showToast, setShowToast] = useState(false);

  const handleSaveProfile = async () => {
    setLoad(false);
    const docRef = doc(db, "users", auth.currentUser.uid);
    const docSnap = await getDoc(docRef);

    if (!role) {
      await updateProfile(auth.currentUser, {
        displayName: fullName ? fullName : userData.displayName,
        photoURL: urlProfileImg ? urlProfileImg : userData.photoURL,
      });

      await updateDoc(docRef, {
        displayName: fullName ? fullName : userData.displayName,
        photoURL: urlProfileImg ? urlProfileImg : userData.photoURL,
      }).then(() => {
        (async () => {
          const docRef = doc(db, "users", auth.currentUser.uid);
          const docSnap = await getDoc(docRef);
          const userDatas = {
            id: docSnap.id,
            data: docSnap.data(),
          };
          dispatch(setUserExtraData(userDatas));
        })().then(() => setLoad(true));
      });
    } else {
      if (docSnap.exists()) {
        await updateDoc(docRef, {
          role: role,
        }).then(() => {
          (async () => {
            const docRef = doc(db, "users", auth.currentUser.uid);
            const docSnap = await getDoc(docRef);
            const userDatas = {
              id: docSnap.id,
              data: docSnap.data(),
            };
            dispatch(setUserExtraData(userDatas));
          })().then(() => setLoad(true));
        });
      } else {
        // docSnap.data() will be undefined in this case
        await setDoc(doc(db, "users", auth.currentUser.uid), {
          role,
        }).then(() => {
          (async () => {
            const docRef = doc(db, "users", auth.currentUser.uid);
            const docSnap = await getDoc(docRef);
            const userDatas = {
              id: docSnap.id,
              data: docSnap.data(),
            };
            dispatch(setUserExtraData(userDatas));
          })().then(() => {
            setLoad(true);
            setShowToast(true);
            setTimeout(() => {
              setShowToast(false);
            }, 3000);
          });
        });
      }
    }
  };

  function generateRandomHash(length) {
    // Use combination of Math.random() and high-resolution time for randomness
    const randomString =
      Math.random().toString(36).substring(2) +
      performance.now().toString(36).substring(2);

    // Ensure at least the requested length
    let hash = randomString.slice(0, Math.max(length, 0));

    // Optionally add characters for better distribution
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = hash.length; i < length; i++) {
      hash += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    return hash;
  }

  const handleCheckExists = async () => {
    try {
      const q = query(
        collection(db, "users"),
        where("email", "==", emailToInvite)
      );

      const querySnapshot = await getDocs(q);
      const usersExist = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));

      if (usersExist.length > 0) {
        setIsExist(true);
        return true;
      } else {
        setIsExist(false);
        return false;
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des users:", error);
      setIsExist(false); // Considérer comme invalide en cas d'erreur
      return false;
    }
  };

  const handleSendInvitation = async (e) => {
    setLoad(false);

    e.preventDefault();

    const exist = await handleCheckExists();

    console.log(exist);

    if (exist) {
      setLoad(true);
      return null;
    } else {
      const randomHash = generateRandomHash(16);
      await addDoc(collection(db, "invitation"), {
        token: randomHash,
        email: emailToInvite,
      });

      await addDoc(collection(db, "mail"), {
        to: emailToInvite,
        message: {
          subject: `You've been invited by ${userData.displayName} | ${userExtraData.data?.role} to join the eMagine Solutions team`,
          html: `
        
        <div style='display=flex , justify-content = center , align-items = center'>
        
          <p style='font-size=1.4rem'>Hi</p><br />
        
       <p>You've been invited by ${userData.displayName} to join the eMagine Solutions team  click on the following link to join us.</p> 
        <br />

        <a href='https://e-solutions-10e47328b4c9.herokuapp.com/sign-up?token=${randomHash}&email=${emailToInvite}' style="font-size =  1.2rem">
        
        join us 
        </a>
        </div>
        
      

        `,
        },
      }).then(() => {
        setLoad(true);
        setEmailToInvite("");
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
        }, 3000);
      });
    }
  };

  return (
    <div className="h-[calc(100vh-4rem)] relative flex justify-center items-center bg-slate-300">
      {showToast && (
        <Toast className="fixed bottom-4 right-2 bg-green-50">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="w-6 h-6 text-green-500 -rotate-45 "
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
            />
          </svg>
          <div className="pl-4 text-sm font-normal">Done with success</div>
        </Toast>
      )}
      <div className="w-[50rem] relative rounded-lg p-8 h-fit bg-white">
        <h1 className="text-xl text-gray-500">Profile settings</h1>

        {!load && (
          <div className=" absolute inset-0 flex justify-center items-center">
            <Spinner aria-label="Extra large spinner example" size="xl" />
          </div>
        )}

        {load && (
          <div className="mt-10 grid md:grid-cols-2 gap-6 ">
            <div>
              <div className="flex  space-x-4 items-center">
                <div className="w-24 h-24 border rounded-md  ">
                  {urlProfileImg ? (
                    <img
                      src={urlProfileImg}
                      alt=""
                      srcset=""
                      className="w-full h-full rounded-md object-cover"
                    />
                  ) : !urlProfileImg &&
                    userExtraData &&
                    userExtraData.data?.photoURL ? (
                    <img
                      src={userExtraData.data?.photoURL}
                      alt=""
                      srcset=""
                      className="w-full h-full rounded-md object-cover"
                    />
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-full h-full text-gray-300"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                      />
                    </svg>
                  )}
                </div>
                <button
                  onClick={() => dispatch(setOpenDi("pfil"))}
                  type="button"
                  className="rounded-md inline-flex justify-center items-center bg-orange-600 w-fit px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                    />
                  </svg>

                  <span>Upload</span>
                </button>
              </div>

              <div className="mt-10">
                <div>
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Full name
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      onChange={(e) => setFullName(e.target.value)}
                      defaultValue={userExtraData.data?.displayName}
                      autoComplete="full-name"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                      placeholder="john doe"
                    />
                  </div>
                </div>
                <div className="mt-6">
                  <label
                    htmlFor="role"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Role
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="role"
                      id="role"
                      defaultValue={userExtraData.data?.role}
                      onChange={(e) => setRole(e.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                      placeholder="CEO"
                    />
                  </div>
                </div>
                <div className="mt-6">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Email
                  </label>
                  <div className="mt-2">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      defaultValue={userExtraData.data?.email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                      placeholder="you@example.com"
                    />
                  </div>
                </div>
              </div>
            </div>
            <form
              onSubmit={handleSendInvitation}
              className=" bg-gradient-to-br from-gray-100 flex justify-center flex-col items-center"
            >
              <span className="my-10 text-xl text-center font-medium">
                Invite someone to join your team
              </span>
              <div className="flex gap-2 flex-col items-center justify-center">
                <div className="relative">
                  <input
                    type="email"
                    onChange={(e) => setEmailToInvite(e.target.value)}
                    value={emailToInvite}
                    name="email_to_invite"
                    id="email_to_invite"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                    placeholder="janesmith@exemple.com"
                  />
                  {isExist && (
                    <span className="text-xs text-orange-700">
                      User already exists
                    </span>
                  )}
                </div>

                <button
                  type="submit"
                  className="rounded-md inline-flex space-x-4 justify-center items-center  mt-5 text-blue-500  font-semibold   "
                >
                  <span>Send</span>
                </button>
              </div>
            </form>
          </div>
        )}

        <div className="mt-10 flex justify-end items-center">
          <button
            onClick={() => handleSaveProfile()}
            type="button"
            className="rounded-md bg-orange-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm   hover:bg-gray-50"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default Profile;
