import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import { setClose, setOpen } from "../redux/utils";
import { motion } from "framer-motion";
import { Footer } from "../components/footer/footer";
import { Link } from "react-router-dom";

const Logo = () => (
  <svg
    className="w-10 h-10  transition-all duration-500 ease-in-out fill-slate-100 "
    viewBox="0 0 81 90"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.15924 18.8315L17.0255 27.8804L42.0478 14.1848L64.2325 27.8804L75.3248 18.8315L42.0478 0L5.15924 18.8315Z" />
    <path d="M0 27.8804L12.6401 36.6848V60.8967L42.0478 76.7935L81 53.0707V66.2772L42.0478 90L0 66.2772V27.8804Z" />
    <path d="M22.7006 55.0272V43.0435L42.0478 55.0272L81 29.5924V40.3533L42.0478 66.2772L22.7006 55.0272Z" />
    <path d="M25.7962 32.7717L42.0478 23.7228L55.9777 32.7717L42.0478 43.0435L25.7962 32.7717Z" />
  </svg>
);

export default function DialogOp() {
  const { dialogOp } = useSelector((state) => state.utilsSlice);
  const dispatch = useDispatch();
  const navigation = [
    { name: "Home", href: "/" },
    { name: "Live positions", href: "/find-a-job" },
    { name: "About us", href: "/about-us" },
    { name: "Insights", href: "/insights" },
    { name: "Our expertise", href: "/our-expertise" },
    { name: "Contact", href: "/contact" },
    { name: "Privacy policy", href: "/privacy-policy" },
  ];

  return (
    <Transition.Root show={dialogOp} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[10000000000000000]"
        onClose={() => dispatch(setClose())}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-x-hidden overflow-y-scroll  bg-neutral-950 px-4  text-left shadow-xl transition-all  w-screen h-screen ">
                <div className="absolute w-[700px] h-[700px] rounded-full  -z-0 bg-indigo-800 opacity-30 blur-3xl" />
                <div className="absolute w-[700px] h-[700px] rounded-full -z-0  right-2  bg-orange-800 opacity-30 blur-3xl" />
                <div className="absolute w-[700px] h-[700px] rounded-full -z-0  bottom-1/2 left-1/2  bg-teal-800 opacity-30 blur-3xl" />

                <div className="absolute right-0 top-0 z-[100000000]  pr-4 pt-4 ">
                  <motion.a
                    role="button"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    transition={{ type: "spring", stiffness: 400, damping: 17 }}
                  >
                    <XMarkIcon
                      onClick={() => dispatch(setClose())}
                      className="h-10 w-10 text-white"
                      aria-hidden="true"
                    />
                  </motion.a>
                </div>
                <div className="absolute md:left-20  flex items-center left-5 top-0  pr-4 pt-4 ">
                  <Logo />
                  <a href="/" className="flex  text-white lg:w-1/3 px-2 ">
                    <div className="   sm:text-3xl font-bold ">eMagine</div>
                    <div className="   sm:text-3xl ms-2 font-extralight ">
                      {" "}
                      solutions
                    </div>
                  </a>
                </div>

                <div className=" relative flex md:block  justify-center  md:mt-36 mx-auto px-4 sm:px-6 lg:px-8">
                  <div className="max-w-[85rem]    px-4 xl:px-0 mx-auto     ">
                    <div className="  text-gray-300 mt-28">
                      <div>
                        <h1 className="  text-xl  text-gray-100 font-medium ">
                          {" "}
                          Sitemap
                        </h1>

                        <ul className="mt-8 space-y-4">
                          {navigation.map((e) => (
                            <li className="lowercase hover:ms-8 text-gray-300 transition-all ease-in duration-500  first-letter:capitalize text-2xl md:text-4xl">
                              <Link
                                onClick={() => dispatch(setClose())}
                                to={e.href}
                              >
                                {e.name}
                              </Link>
                            </li>
                          ))}
                          <li className="lowercase hover:ms-8 text-gray-300 transition-all ease-in duration-500  first-letter:capitalize text-2xl md:text-4xl">
                            <Link
                              onClick={() => dispatch(setClose())}
                              to={"/cv-drop"}
                            >
                              Cv drop
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
