import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { setClose } from "../redux/utils";
import { useDispatch } from "react-redux";

const VideoBackground = (props) => {
  const [isLoaded, setIsLoaded] = useState(true);
  const [canPlay, setCanPlay] = useState(false);
  const dispatch = useDispatch();

  const videoRef = useRef(null);

  useEffect(() => {
    console.log(navigator.connection?.downlink);

    if (navigator?.connection && navigator?.connection?.downlink > 1) {
      setCanPlay(true);
    }
  }, []);

  const handleVideoLoad = () => {
    setIsLoaded(true);
  };

  const handlePlay = () => {
    if (videoRef.current) {
      videoRef.current.muted = true; // Mute the video initially
      videoRef.current.play();
    }
  };

  const handleStop = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  };

  return (
    <div {...props}>
      <div className=" overflow-hidden min-h-dvh  grid   xtall:bg-gradient-to-bl xtall:from-gray-500/30  py-28  z-50">
        <div className=" h-full  flex justify-start lg:pl-24 md:pl-18 pl-4 items-center relative">
          <div className="   w-full z-[80]">
            <div class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
              <div className="  font-bold  ">
                <h1 className=" text-6xl    transition-all ease-in-out lg:text-9xl bg-clip-text text-transparent bg-gradient-to-r from-orange-500 to-violet-600">
                  Talent
                </h1>
                <h1 className=" text-6xl  transition-all ease-in-out  lg:text-9xl sm:ms-24 m text-white">
                  Specialists
                </h1>
              </div>
              <p className="md:mt-28 mt-8 font-medium  md:text-2xl text-white text-sm  ">
                eMagine recruitment differently, we understand that people are your
                greatest asset
              </p>

              <div className="flex  mt-20  sm:text-sm text-xs   ">
                <motion.a
                  role="button"
                  href="/find-a-job"
                  className="mt-auto   font-semibold  xs:inline flex flex-col  rounded-md "
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  transition={{ type: "spring", stiffness: 400, damping: 17 }}
                >
                  <span className=" bg-orange-600  xs:rounded-s-md text-white p-4">
                    {" "}
                    We're hiring{" "}
                  </span>{" "}
                  <span className="p-4 bg-slate-50 xs:rounded-e-md">
                    {" "}
                    See live position
                  </span>
                </motion.a>
              </div>
            </div>
          </div>
        </div>
        {/*    */}
      </div>
      <div className="absolute z-10  inset-0 xtall:hidden   ">
        <div className="absolute inset-0 bg-gradient-to-b z-10 from-neutral-900/60  to-orange-600/20 block" />
        <video
          ref={videoRef}
          onLoadedData={handlePlay}
          poster={require("../assets/illustrations/bulle.jpg")}
          loop
          playsinline
          preload="auto"
          className=" h-full w-full  object-cover aspect-auto   exposure-video "
          src={require("../assets/illustrations/tempme-hiring.mp4")}
        >
          <p>
            Votre navigateur ne prend pas en charge les vidéos HTML5. Voici
            <a href="myVideo.mp4">un lien pour télécharger la vidéo</a>.
          </p>
        </video>
      </div>
    </div>
  );
};

export default VideoBackground;
