import React, { useEffect, useState } from "react";
import ChartA from "./chart";
import Visitors from "./visitors";
import Stats from "./stats";
import TableVisitors from "./chart";
import { auth, db } from "../../firebase";

import {
  collection,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { Spinner } from "flowbite-react";
import moment from "moment";
import {
  BuildingOffice2Icon,
  FolderIcon,
  UserIcon,
  UsersIcon,
  BriefcaseIcon,
} from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import { setUserExtraData } from "../../redux/utils";

function EventListenner(props) {
  const [load, setLoad] = useState(true);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      setLoad(false);
      const q = query(
        collection(db, "behaviour"),
        where("type", "==", "event_listner")
      );
      const querySnapshot = await getDocs(q);

      const dataArray = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));

      setLoad(true);
      setData(dataArray);
    } catch (error) {
      console.error("an error occurred while fetching", error);
    }
  };

  useEffect(() => {
    const abortController = new AbortController();

    const fetchDatas = async () => {
      try {
        setLoad(false); // Commencer le chargement
        await Promise.all([fetchData()]);
      } catch (error) {
        console.error("an error occurred", error);
        // Ici, gérer l'affichage d'un message d'erreur à l'utilisateur si nécessaire
      } finally {
        setLoad(true); // Terminer le chargement, que l'opération réussisse ou échoue
      }
    };

    if (!abortController.signal.aborted) {
      fetchDatas();
    }

    return () => {
      setData([]);
      abortController.abort(); // Optionnel : Annuler les requêtes en cours si elles n'ont pas encore abouti
    };
  }, []);

  return (
    <div {...props}>
      <h1 className="text-gray-400">Events listener</h1>

      <div className=" overflow-y-auto ">
        {!load && (
          <div className="absolute flex inset-0 justify-center items-center  ">
            <Spinner
              size={"xl"}
              color={"purple"}
              aria-label="Default status example"
            />
          </div>
        )}

        {load && (
          <div className="flex mt-10 flex-col gap-4">
            {data.length === 0 && (
              <h1 className="text-center text-purple-600 text-sm animate-pulse">
                Data not available
              </h1>
            )}
            {data &&
              data.map((el) => (
                <div className="h-fit w-full rounded-md border border-dashed  text-sm p-4 flex flex-col">
                  <div className="flex justify-start">
                    <div className="flex flex-col">
                      <span>Action : {el.data.format}</span>
                      <span className=" space-x-2  inline-flex text-sm  rounded-xl ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-4 h-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                          />
                        </svg>

                        <span className=" whitespace-nowrap  ">
                          {el.data.page_name}
                        </span>
                      </span>
                    </div>
                  </div>

                  <div className="flex justify-end space-x-4">
                    <span className="text-sm p-4 space-x-2  inline-flex bg-green-100 text-green-600 px-4 rounded-xl ">
                      <span>{el.data.number}</span>
                    </span>
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
}

function DownloadedDocument(props) {
  const [load, setLoad] = useState(true);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      setLoad(false);
      const q = query(collection(db, "visitors"));
      const querySnapshot = await getDocs(q);

      const dataArray = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));

      setLoad(true);
      setData(dataArray);
    } catch (error) {
      console.error("an error occurred while fetching", error);
    }
  };

  useEffect(() => {
    const abortController = new AbortController();

    const fetchDatas = async () => {
      try {
        setLoad(false); // Commencer le chargement
        await Promise.all([fetchData()]);
      } catch (error) {
        console.error("an error occurred", error);
        // Ici, gérer l'affichage d'un message d'erreur à l'utilisateur si nécessaire
      } finally {
        setLoad(true); // Terminer le chargement, que l'opération réussisse ou échoue
      }
    };

    if (!abortController.signal.aborted) {
      fetchDatas();
    }

    return () => {
      setData([]);
      abortController.abort(); // Optionnel : Annuler les requêtes en cours si elles n'ont pas encore abouti
    };
  }, []);
  return (
    <div {...props}>
      <h1 className="text-gray-400"> Downloaded Documents</h1>

      <div className=" overflow-y-auto  ">
        {!load && (
          <div className="absolute flex inset-0  justify-center items-center  ">
            <Spinner
              size={"xl"}
              color={"purple"}
              aria-label="Default status example"
            />
          </div>
        )}
        {data.length === 0 && (
          <h1 className="text-center text-purple-600 text-sm animate-pulse">
            Data not available
          </h1>
        )}

        {load && (
          <div className="flex  mt-10 flex-col gap-4">
            {data &&
              data.map((el) => (
                <div className="h-fit w-full rounded-md border border-dashed  text-lg p-4  flex-col justify-center flex">
                  <a
                    href={el.data?.url}
                    target="_blank"
                    className=" flex justify-start font-light text-indigo-600"
                  >
                    {el.data.type}
                  </a>
                  <div className=" flex flex-col border-l-2 pl-4 text-gray-600">
                    <span className="text-sm">{el.data.name}</span>
                    <span className="text-sm">{el.data.email}</span>
                    <span className="text-sm">{el.data.phone}</span>
                    <span className="text-xs flex justify-end ">
                      {moment.unix(el.data.creatAt).local().fromNow()}
                    </span>
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
}

function UserAverageTimePerPage(props) {
  const [load, setLoad] = useState(true);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      setLoad(false);
      const q = query(
        collection(db, "behaviour"),
        where("type", "==", "time_spent_per_page")
      );
      const querySnapshot = await getDocs(q);

      const dataArray = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));

      setLoad(true);
      setData(dataArray);
    } catch (error) {
      console.error("an error occurred while fetching", error);
    }
  };

  useEffect(() => {
    const abortController = new AbortController();

    const fetchDatas = async () => {
      try {
        setLoad(false); // Commencer le chargement
        await Promise.all([fetchData()]);
      } catch (error) {
        console.error("an error occurred", error);
        // Ici, gérer l'affichage d'un message d'erreur à l'utilisateur si nécessaire
      } finally {
        setLoad(true); // Terminer le chargement, que l'opération réussisse ou échoue
      }
    };

    if (!abortController.signal.aborted) {
      fetchDatas();
    }

    return () => {
      setData([]);
      abortController.abort(); // Optionnel : Annuler les requêtes en cours si elles n'ont pas encore abouti
    };
  }, []);

  const calculateAverageTimeSpent = (timeSpentArray) => {
    const total = timeSpentArray.reduce((acc, cur) => acc + cur, 0);
    return timeSpentArray.length > 0 ? total / timeSpentArray.length : 0;
  };

  const formatTime = (milliseconds) => {
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    seconds = seconds % 60;

    return `${minutes} min : ${seconds}s`;
  };

  return (
    <div {...props}>
      <h1 className="text-gray-400">Average time per page</h1>

      <div className=" overflow-y-auto  ">
        {!load && (
          <div className="absolute flex inset-0 justify-center items-center  ">
            <Spinner
              size={"xl"}
              color={"purple"}
              aria-label="Default status example"
            />
          </div>
        )}
        {load && (
          <div className="flex mt-10 flex-col gap-4">
            {data.length === 0 && (
              <h1 className="text-center text-purple-600 text-sm animate-pulse">
                Data not available
              </h1>
            )}
            {data &&
              data.map((el) => (
                <div className="h-fit w-full rounded-md border space-y-2 text-sm p-4 flex-col  flex border-dashed">
                  <div className=" flex justify-start whitespace-nowrap text-indigo-600 font-semibold ">
                    <span className="inline-flex items-center space-x-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-4 h-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                        />
                      </svg>{" "}
                      <span>{el.data.page_name}</span>
                    </span>
                  </div>
                  <div className="flex justify-end">
                    <span className="text-lg  space-x-2  inline-flex bg-orange-100 text-orange-600 px-6 py-2 rounded-xl ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-4 h-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                        />
                      </svg>

                      <span className="text-sm">
                        {formatTime(
                          calculateAverageTimeSpent(el.data.timeSpent)
                        )}
                      </span>
                    </span>
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
}

function UserPerCountry(props) {
  const [load, setLoad] = useState(true);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      setLoad(false);
      const q = query(
        collection(db, "behaviour"),
        where("type", "==", "user_per_country")
      );
      const querySnapshot = await getDocs(q);

      const dataArray = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));

      setLoad(true);
      setData(dataArray);
    } catch (error) {
      console.error("an error occurred while fetching", error);
    }
  };

  useEffect(() => {
    const abortController = new AbortController();

    const fetchDatas = async () => {
      try {
        setLoad(false); // Commencer le chargement
        await Promise.all([fetchData()]);
      } catch (error) {
        console.error("an error occurred", error);
        // Ici, gérer l'affichage d'un message d'erreur à l'utilisateur si nécessaire
      } finally {
        setLoad(true); // Terminer le chargement, que l'opération réussisse ou échoue
      }
    };

    if (!abortController.signal.aborted) {
      fetchDatas();
    }

    return () => {
      setData([]);
      abortController.abort(); // Optionnel : Annuler les requêtes en cours si elles n'ont pas encore abouti
    };
  }, []);
  return (
    <div {...props}>
      <h1 className="text-gray-400"> Users Per Country</h1>

      <div className=" overflow-y-auto  ">
        {!load && (
          <div className="absolute flex inset-0  justify-center items-center  ">
            <Spinner
              size={"xl"}
              color={"purple"}
              aria-label="Default status example"
            />
          </div>
        )}
        {data.length === 0 && (
          <h1 className="text-center text-purple-600 text-sm animate-pulse">
            Data not available
          </h1>
        )}
        {load && (
          <div className="flex  mt-10 flex-col gap-4">
            {data &&
              data.map((el) => (
                <div className="h-fit w-full rounded-md border border-dashed  text-sm p-4  flex-col justify-center flex">
                  <div className=" flex justify-start font-semibold text-indigo-600">
                    {el.data.country}
                  </div>
                  <div className=" flex justify-end">
                    <span className="text-lg space-x-2 items-center justify-center inline-flex bg-orange-100 text-orange-600 px-6 py-2 rounded-xl ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-4 h-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
                        />
                      </svg>
                      <span className="text-sm">{el.data.number}</span>
                    </span>
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
}

function SessionPerDate(props) {
  const [load, setLoad] = useState(true);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      setLoad(false);
      const q = query(
        collection(db, "behaviour"),
        where("type", "==", "session_per_date")
      );
      const querySnapshot = await getDocs(q);
  
      const dataArray = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));
  
      // Sort dataArray by date in descending order
      dataArray.sort((a, b) => {
        const dateA = new Date(a.data.date);
        const dateB = new Date(b.data.date);
        return dateB - dateA;
      });
  
      setLoad(true);
      setData(dataArray);
    } catch (error) {
      console.error("An error occurred while fetching", error);
    }
  };

  console.log("========>",data);

  useEffect(() => {
    const abortController = new AbortController();

    const fetchDatas = async () => {
      try {
        setLoad(false); // Commencer le chargement
        await Promise.all([fetchData()]);
      } catch (error) {
        console.error("an error occurred", error);
        // Ici, gérer l'affichage d'un message d'erreur à l'utilisateur si nécessaire
      } finally {
        setLoad(true); // Terminer le chargement, que l'opération réussisse ou échoue
      }
    };

    if (!abortController.signal.aborted) {
      fetchDatas();
    }

    return () => {
      setData([]);
      abortController.abort(); // Optionnel : Annuler les requêtes en cours si elles n'ont pas encore abouti
    };
  }, []);

  return (
    <div {...props}>
      <h1 className="text-gray-400">Session per date</h1>

      <div className=" overflow-y-auto ">
        {!load && (
          <div className="absolute flex inset-0 justify-center items-center  ">
            <Spinner
              size={"xl"}
              color={"purple"}
              aria-label="Default status example"
            />
          </div>
        )}

        {data.length === 0 && (
          <h1 className="text-center text-purple-600 text-sm animate-pulse">
            Data not available
          </h1>
        )}
        {load && (
          <div className="flex mt-10 flex-col gap-4">
            {data &&
              data.map((el) => (
                <div className="h-20 w-full rounded-md border items-center text-sm px-3 border-dashed inline-flex">
                  <div className="w-1/2 flex justify-start">{el.data.date}</div>
                  <div className="w-1/2 flex justify-end">
                    <span className="text-lg  space-x-2 items-center inline-flex bg-orange-100 text-orange-600 px-6 py-2 rounded-xl ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-4 h-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
                        />
                      </svg>

                      <span className="text-sm">{el.data.number}</span>
                    </span>
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
}

function Candidate(props) {
  const [load, setLoad] = useState(true);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      setLoad(false);
      const q = query(collection(db, "candidate"), orderBy("creatAt", "desc"));
      const querySnapshot = await getDocs(q);

      const dataArray = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));

      setLoad(true);
      setData(dataArray);
    } catch (error) {
      console.error("an error occurred while fetching", error);
    }
  };

  useEffect(() => {
    const abortController = new AbortController();

    const fetchDatas = async () => {
      try {
        setLoad(false); // Commencer le chargement
        await Promise.all([fetchData()]);
      } catch (error) {
        console.error("an error occurred", error);
        // Ici, gérer l'affichage d'un message d'erreur à l'utilisateur si nécessaire
      } finally {
        setLoad(true); // Terminer le chargement, que l'opération réussisse ou échoue
      }
    };

    if (!abortController.signal.aborted) {
      fetchDatas();
    }

    return () => {
      setData([]);
      abortController.abort(); // Optionnel : Annuler les requêtes en cours si elles n'ont pas encore abouti
    };
  }, []);

  return (
    <div {...props}>
      <h1 className="text-gray-400">Candidates</h1>

      <div className="max- overflow-y-auto ">
        {!load && (
          <div className="absolute flex inset-0 justify-center items-center  ">
            <Spinner
              size={"xl"}
              color={"purple"}
              aria-label="Default status example"
            />
          </div>
        )}
        {data && (
          <div className="flex mt-10 flex-col gap-4">
            {data &&
              data.map((el) => (
                <div className=" w-full rounded-md border border-dashed  text-sm  p-4 flex flex-col">
                  <div className=" truncate text-purple-600 font-semibold flex justify-start">
                    <div className="flex flex-col">
                      <span>
                        {el.data.candidate_name} | {el.data.title}
                      </span>

                      <span className=" text-gray-400 text-sm font-normal">
                        {moment.unix(el.data.creatAt).local().fromNow()}
                      </span>
                    </div>
                  </div>
                  <a
                    href={el.data.link}
                    target="_blank"
                    className="flex justify-end"
                  >
                    <span className="  space-x-2  items-center text-xs inline-flex bg-blue-100 text-blue-600 px-6 py-2 rounded-xl ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-4 h-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                        />
                      </svg>

                      <span>Download cv</span>
                    </span>
                  </a>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
}

const UserCard = (props) => {
  const { userData, userExtraData } = useSelector((state) => state.utilsSlice);
  const [countContact, setCountContact] = useState(0);
  const [countJobs, setCountJobs] = useState(0);
  const [countClient, setCountClient] = useState(0);
  const [countCandidate, setCountCandidate] = useState(0);
  const [load, setLoad] = useState(true);

  const fetch = async () => {
    try {
      setLoad(false);
      const collContact = collection(db, "contact");
      const collJobs = collection(db, "jobs");
      const collClient = collection(db, "clients");
      const collCandidate = collection(db, "candidate");
      const q1 = query(collContact, where("status", "==", "pending"));
      const q2 = query(collJobs);
      const q3 = query(collClient);
      const q4 = query(collCandidate);
      const snapshot1 = await getCountFromServer(q1);
      const snapshot2 = await getCountFromServer(q2);
      const snapshot3 = await getCountFromServer(q3);
      const snapshot4 = await getCountFromServer(q4);

      const count1 = snapshot1.data().count;
      const count2 = snapshot2.data().count;
      const count3 = snapshot3.data().count;
      const count4 = snapshot4.data().count;

      setLoad(true);
      setCountContact(count1);
      setCountJobs(count2);
      setCountClient(count3);
      setCountCandidate(count4);
    } catch (error) {
      console.error("Error fetching", error);
    }
  };

  useEffect(() => {
    const abortController = new AbortController();

    const fetchData = async () => {
      try {
        setLoad(false); // Commencer le chargement
        await Promise.all([fetch()]);
      } catch (error) {
        console.error("an error occurred", error);
        // Ici, gérer l'affichage d'un message d'erreur à l'utilisateur si nécessaire
      } finally {
        setLoad(true); // Terminer le chargement, que l'opération réussisse ou échoue
      }
    };

    if (!abortController.signal.aborted) {
      fetchData();
    }

    return () => {
      abortController.abort(); // Optionnel : Annuler les requêtes en cours si elles n'ont pas encore abouti
    };
  }, []);

  return (
    <>
      <div {...props}>
        <div className="flex justify-center items-center flex-col py-6">
          <h1 className="my-5 text-gray-600">Welcome back !</h1>
          <div className="border flex justify-center items-center rounded-full w-10 h-10 md:w-20 md:h-20">
            {userData.photoURL && (
              <img
                src={userData.photoURL}
                alt=""
                className=" object-cover w-full h-full rounded-full"
              />
            )}

            {!userData.photoURL && (
              <UserIcon className="w-[60%] h-[60%] text-gray-600" />
            )}
          </div>
          <div className="mt-4 text-sm font-display text-gray-500">
            {userData.displayName} | {userExtraData.data?.role}
          </div>
          <div className="grid grid-cols-4 mt-10 text-gray-400 justify-center items-center gap-4">
            <div className="w-8 h-8 md:w-16 md:h-16 flex justify-center items-center  relative">
              <div className=" w-2 h-2 md:w-4 md:h-4  p-2 md:p-4 rounded-full text-white flex justify-center bg-blue-600 items-center text-xs md:text-sm absolute -right-3 top-0">
                <span>{countContact}</span>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-[60%] h-[60%] "
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 3.75H6.912a2.25 2.25 0 0 0-2.15 1.588L2.35 13.177a2.25 2.25 0 0 0-.1.661V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 0 0-2.15-1.588H15M2.25 13.5h3.86a2.25 2.25 0 0 1 2.012 1.244l.256.512a2.25 2.25 0 0 0 2.013 1.244h3.218a2.25 2.25 0 0 0 2.013-1.244l.256-.512a2.25 2.25 0 0 1 2.013-1.244h3.859M12 3v8.25m0 0-3-3m3 3 3-3"
                />
              </svg>
            </div>
            <div className="w-8 h-8 md:w-16 md:h-16 flex justify-center items-center relative">
              <div className=" w-2 h-2 md:w-4 md:h-4  p-2 md:p-4 rounded-full text-white flex justify-center bg-indigo-600 text-xs md:text-sm items-center absolute -right-3 top-0">
                <span>{countJobs}</span>
              </div>
              <FolderIcon className="w-[60%] h-[60%] " />
            </div>
            <div className="w-8 h-8 md:w-16 md:h-16 flex justify-center items-center relative">
              <div className=" w-2 h-2 md:w-4 md:h-4  p-2 md:p-4 rounded-full text-white flex justify-center bg-teal-600 text-xs md:text-sm items-center absolute -right-3 top-0">
                <span>{countCandidate}</span>
              </div>
              <BriefcaseIcon className="w-[60%] h-[60%] " />
            </div>
            <div className="w-8 h-8 md:w-16 md:h-16 flex justify-center items-center relative">
              <div className=" w-2 h-2 md:w-4 md:h-4  p-2 md:p-4 rounded-full text-white flex justify-center bg-orange-600 text-xs md:text-sm items-center absolute -right-3 top-0">
                <span>{countClient}</span>
              </div>
              <BuildingOffice2Icon className="w-[60%] h-[60%] " />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function Dashboard() {
  return (
    <div className="grid  xl:grid-cols-3  gap-8 xl:h-[calc(100vh-4rem)] z-20 p-4 last:col-span-full bg-slate-200">
      <UserCard
        className="  h-96 border p-4 flex flex-col text-xl
      cursor-pointer relative overflow-hidden rounded-xl bg-white shadow-sm"
      />
      <UserPerCountry
        className="  h-96 border p-4 flex flex-col text-xl
      cursor-pointer relative overflow-hidden rounded-xl bg-white shadow-sm"
      />
      <UserAverageTimePerPage className="   h-96 border p-4 flex flex-col text-xl cursor-pointer relative  overflow-hidden   rounded-xl bg-white shadow-sm" />
      <DownloadedDocument className=" border  h-96 p-4 flex flex-col text-xl cursor-pointer relative  overflow-hidden   rounded-xl bg-white shadow-sm" />
      {/*       <EventListenner className=" border  h-96 p-4 flex flex-col text-xl cursor-pointer relative  overflow-hidden   rounded-xl bg-white shadow-sm" /> */}
      <SessionPerDate className=" row-span-2 border  h-96 p-4 flex flex-col text-xl cursor-pointer relative  overflow-hidden   rounded-xl bg-white shadow-sm" />
      <Candidate className="  border p-4  h-96 flex flex-col text-xl cursor-pointer relative  overflow-hidden   rounded-xl bg-white shadow-sm" />
    </div>
  );
}

export default Dashboard;
