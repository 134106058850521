import clsx from "clsx";
import { Container } from "../components/utils/Container";
import { FadeIn } from "../components/utils/FadeIn";

export function PageIntro({
  eyebrow,
  title,
  children,
  centered = false,
  coloredText,
  blog = false,
}) {
  return (
    <Container
      className={clsx(
        !blog && "mt-24 sm:mt-32 lg:mt-40",
        centered && "text-center"
      )}
    >
      <FadeIn>
        <h1>
          <span className="block font-display text-base font-semibold text-white">
            {eyebrow}
          </span>
          <span className="sr-only"> - </span>
          <span
            className={clsx(
              "mt-6  max-w-5xl font-display text-5xl font-medium   bg-clip-text text-transparent bg-gradient-to-r from-orange-500 to-violet-600 py-2 [text-wrap:balance] sm:text-6xl",
              centered && "mx-auto"
            )}
          >
            {title} {coloredText}
          
          </span>
        </h1>
        <div
          className={clsx(
            "mt-6 max-w-3xl text-xl text-neutral-100",
            centered && "mx-auto"
          )}
        >
          {children}
        </div>
      </FadeIn>
    </Container>
  );
}
