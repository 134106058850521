// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage, ref } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDDU06U3-67LEZPgI_mB2RObCF8yUWOYCI",
  authDomain: "e-solutions-25db2.firebaseapp.com",
  projectId: "e-solutions-25db2",
  storageBucket: "e-solutions-25db2.appspot.com",
  messagingSenderId: "454856400012",
  appId: "1:454856400012:web:9025a9f3b1598b5f48efa9",
  measurementId: "G-9H5GQ521PW",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app, "gs://e-solutions-25db2.appspot.com");
const db = getFirestore(app);
const auth = getAuth(app);



export { storage, db, auth };
