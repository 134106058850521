import React from "react";
import { Link } from "react-router-dom";

function Blog() {
  return (
    <div className="min-h-[calc(100vh-4rem)] flex justify-center items-center bg-gray-300">
      <div className="grid md:grid-cols-2 gap-6">
        <Link
          to="/blog-editor"
          className="w-96 md:h-96 h-32 rounded-lg flex justify-center items-center text-3xl  hover:underline cursor-pointer text-white bg-orange-600"
        >
          Create new article
        </Link>
        <Link
          to={"/list-of-articles"}
          className="w-96 md:h-96 h-32  rounded-lg flex justify-center items-center text-3xl hover:underline cursor-pointer text-white bg-orange-600"
        >
          List of articles
        </Link>
      </div>
    </div>
  );
}

export default Blog;
