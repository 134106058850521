import { Route, Routes } from "react-router-dom";
import About from "./about";
import LandingLayout from ".";
import Blog from "./blog";
import Contact from "./Contact";
import NotFound from "../components/not-found/not-found";
import Team from "./team";
import Jobs from "./jobs";
import CvDrop from "./cv-drop";
import AboutUs from "./about-us";
import PrivacyPolicy from "./privacy-policy";
import JobsDetails from "./jobs-details";
import useBehaviourTracking from "../hook/behaviour";
import ArticleDetail from "./article-detail";
import SignIn from "./sign-in";
import Profile from "../admin/profile/profile";
import SignUp from "./sign-up";
import { RequireAuth } from "../components/utils/authreq";
import OurExpertise from "./our-expertise";
import JoinUsForm from "./join-us-form";

export function LandingRoute() {
  useBehaviourTracking();

  return (
    <Routes>
      <Route exact path="/" element={<LandingLayout />}>
        <Route index element={<About />} />
        <Route path="/insights" element={<Blog />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/cv-drop" element={<CvDrop />} />
        <Route path="/join-us" element={<JoinUsForm />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/find-a-job" element={<Jobs />} />
        <Route path="/article-detail/:id" element={<ArticleDetail />} />
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/job-details/:id" element={<JobsDetails />} />
        <Route path="/our-expertise" element={<OurExpertise />} />
        <Route
          path="/sign-up"
          element={
            <RequireAuth>
              <SignUp />
            </RequireAuth>
          }
        />

        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}
