/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  ArrowPathIcon,
  ChevronRightIcon,
  CloudArrowUpIcon,
  LockClosedIcon,
  ServerIcon,
} from "@heroicons/react/20/solid";
import Header from "../components/header/header";

import { Outlet, Router } from "react-router-dom";
import { Footer } from "../components/footer/footer";
import Cookies from "./cookies";
import DialogOp from "./Dialog";

export default function LandingLayout() {
  return (
    <div className="bg-black scroll-smooth  ">
    
      {/* Header */}
      <Header />
      <Outlet />
      {/* Footer */}
      <Cookies />
      <DialogOp />
      <Footer />
    </div>
  );
}
