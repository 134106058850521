import { createSlice } from "@reduxjs/toolkit";

export const utilsSlice = createSlice({
  name: "utilsSlice",
  initialState: {
    userData: {},
    dialogOp: false,
    openDi: false,
    type: "ed",
    urlBg: "",
    url: "",
    urlLogo: "",
    urlClient: "",
    urlAuth: "",
    urlProfileImg: "",
    urlTestimonial: "",
    userExtraData: {},
    isConnected: false,
    urlTim: "",
    urlEditor: "",
    item: {},
  },
  reducers: {
    setOpen: (state, action) => {
      state.dialogOp = true;
    },
    setIsConnected: (state, action) => {
      state.isConnected = action.payload;
    },
    setUrl: (state, action) => {
      if (state.type === "ed") {
        state.url = action.payload;
      }
      if (state.type === "bg") {
        state.urlBg = action.payload;
      }
      if (state.type === "imgC") {
        state.urlClient = action.payload;
      }
      if (state.type === "auth") {
        state.urlAuth = action.payload;
      }
      if (state.type === "pfil") {
        state.urlProfileImg = action.payload;
      }
      if (state.type === "testifile") {
        state.urlTestimonial = action.payload;
      }
      if (state.type === "testilogo") {
        state.urlLogo = action.payload;
      }
      if (state.type === "tim") {
        state.urlTim = action.payload;
      } else {
        state.urlEditor = action.payload;
      }
    },
    setUser: (state, action) => {
      state.userData = action.payload;
    },
    setUserExtraData: (state, action) => {
      state.userExtraData = action.payload;
    },
    setClose: (state, action) => {
      state.dialogOp = false;
    },
    setOpenDi: (state, action) => {
      state.openDi = true;
      state.type = action.payload;
    },
    setCloseDi: (state, action) => {
      state.openDi = false;
      state.type = "ed";
    },
    setType: (state, action) => {
      state.type = action.payload;
    },
    setItem: (state, action) => {
      state.item = action.payload;
    },
  },
});

export const {
  setClose,
  setOpen,
  setOpenDi,
  setCloseDi,
  setUrl,
  setUser,
  setUserExtraData,
  setIsConnected,
  setItem,
  setType,
} = utilsSlice.actions;
