import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { Container } from "../../components/utils/Container";
import {
  collection,
  deleteDoc,
  doc,
  endBefore,
  getCountFromServer,
  getDocs,
  limit,
  query,
  startAfter,
  updateDoc,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../../firebase";
import GoBack from "../../components/utils/goBack";
import moment from "moment";
import { Link } from "react-router-dom";
import { Spinner } from "flowbite-react";

function CountJobs({ id }) {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
      try {
        const coll = collection(db, "jobs");
        const q = query(coll, where("organization", "==", id));
        const snapshot = await getCountFromServer(q);
        const count = snapshot.data().count;

        setCount(count);
      } catch (error) {
        console.error("Error fetching", error);
      }
    })();

    return abortController.abort();
  }, []);

  return (
    <Link
      to={`/one-client/${id}`}
      className=" underline  font-semibold uppercase tracking-wide text-blue-700 dark:text-gray-200"
    >
      {count}
    </Link>
  );
}

function Item({ item, fetchData, setLoad }) {
  // Fetch jobs based on organization id and update their status to false
  const updateJobs = async () => {
    const q = query(
      collection(db, "jobs"),
      where("organization", "==", item.id)
    );

    try {
      const querySnapshot = await getDocs(q);
      // Update all fetched jobs
      const updatePromises = querySnapshot.docs.map((doc) =>
        updateDoc(doc.ref, { open: false })
      );
      await Promise.all(updatePromises);
    } catch (error) {
      console.error(
        "An error occurred while fetching and updating jobs",
        error
      );
    }
  };

  const handleDelete = async () => {
    const result = window.confirm("Do you want to continue ?");

    if (result) {
      setLoad(false);
      updateJobs();
      await deleteDoc(doc(db, "clients", item.id)).then(async () => {
        await Promise.all([fetchData()]);
      });
    }
  };

  return (
    <div className=" bg-white p-4 rounded-lg border border-indigo-800 shadow-md">
      <Link
        class="inline-flex items-center justify-end w-full    gap-x-1 text-sm text-blue-600 decoration-2 hover:underline font-medium dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
        to={`/one-client/${item.id}`}
      >
        Open
      </Link>
      <div className=" grid  gap-4">
        <div className=" col-span-1">
          <div className=" size-24 rounded-lg">
            {item.data.logo && (
              <img
                className=" size-full object-cover rounded-lg"
                src={item.data.logo}
                alt="logo"
              />
            )}
          </div>
        </div>
        <div className=" col-span-2">
          <h1 className=" text-lg font-semibold  "> {item.data.name}</h1>
          <p className=" font- text-gray-400 text-sm"> {item.data.owner}</p>
          <p className=" font- text-gray-400 text-sm"> {item.data.email}</p>
          <p className=" font- text-gray-400 text-sm">{item.data.phone} </p>
          <p className=" font- text-gray-400 text-sm">
            Job(s) : <CountJobs id={item.id} />
          </p>
        </div>
      </div>
      <div>
        <span class="text-sm text-gray-500">
          {moment.unix(item.data.creatAt).local().fromNow()}
        </span>
      </div>

      <div className=" flex justify-end gap-4">
        <Link
          to={`/new-client?id=${item.id}`}
          className=" cursor-pointer font-semibold text-blue-600"
        >
          Update
        </Link>
        <span
          onClick={() => handleDelete()}
          className=" cursor-pointer font-semibold text-red-600"
        >
          Delete
        </span>
      </div>
    </div>
  );
}

export default function ListOfClients() {
  const [clients, setClients] = useState([]);
  const [load, setLoad] = useState(true);
  const [lastVisible, setLastVisible] = useState("");
  const [firstVisible, setFirstVisible] = useState("");

  const fetchData = async () => {
    try {
      setLoad(false);

      const q = query(collection(db, "clients"), limit(10));

      const querySnapshot = await getDocs(q);
      const last = querySnapshot.docs[querySnapshot.docs.length - 1];
      const first = querySnapshot.docs[0];
      setLastVisible(last);
      setFirstVisible(first);
      const clientsArray = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));

      setLoad(true);
      setClients(clientsArray); // Mise à jour de l'état en une seule opération
    } catch (error) {
      console.error("Erreur lors de la récupération des clients:", error);
      // Gérer l'erreur comme il convient (par exemple, en définissant un état d'erreur)
    }
  };

  async function fetchClients(direction = "next") {
    setLoad(false);
    let newQuery;
    if (direction === "next") {
      newQuery = query(
        collection(db, "clients"),
        startAfter(lastVisible),
        limit(10)
      );
    } else {
      // prev
      newQuery = query(
        collection(db, "clients"),
        endBefore(firstVisible),
        limit(10)
      );
    }

    try {
      const querySnapshot = await getDocs(newQuery);
      if (!querySnapshot.empty) {
        const last = querySnapshot.docs[querySnapshot.docs.length - 1];
        const first = querySnapshot.docs[0];
        setLastVisible(last);
        setFirstVisible(first);

        const clientsArray = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }));
        setClients(clientsArray);
      }
    } catch (error) {
      console.error("an error occurred while fetching jobs", error);
    } finally {
      setLoad(true);
    }
  }

  useEffect(() => {
    const abortController = new AbortController();

    const fetchClients = async () => {
      try {
        setLoad(false); // Commencer le chargement
        await Promise.all([fetchData()]);
      } catch (error) {
        console.error("an error occurred", error);
        // Ici, gérer l'affichage d'un message d'erreur à l'utilisateur si nécessaire
      } finally {
        setLoad(true); // Terminer le chargement, que l'opération réussisse ou échoue
      }
    };

    if (!abortController.signal.aborted) {
      fetchClients();
    }

    return () => {
      abortController.abort(); // Optionnel : Annuler les requêtes en cours si elles n'ont pas encore abouti
    };
  }, []); // Le tableau de dépendances vide assure que l'effet ne s'exécute qu'au montage

  return (
    <div className="bg-white  min-h-[calc(100vh-4rem)] relative">
      <div className=" p-4">
        {!load && (
          <div className=" absolute inset-0 flex justify-center items-center">
            <Spinner aria-label="Extra large spinner example" size="xl" />
          </div>
        )}

        {load && (
          <div class="flex flex-col gap-4">
            <h2 class="text-lg inline-flex items-center   space-x-3 font-semibold text-gray-800 dark:text-gray-200">
              <GoBack /> <span>Clients</span>
            </h2>

            <div>
              <div class="inline-flex w-full justify-end gap-x-2">
                <button
                  type="button"
                  onClick={() => fetchClients("prev")}
                  class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                >
                  <svg
                    class="flex-shrink-0 size-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="m15 18-6-6 6-6" />
                  </svg>
                  Prev
                </button>

                <button
                  type="button"
                  onClick={() => fetchClients("next")}
                  class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                >
                  Next
                  <svg
                    class="flex-shrink-0 size-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="m9 18 6-6-6-6" />
                  </svg>
                </button>
              </div>
            </div>

            <div className=" grid md:grid-cols-3 lg:grid-cols-4 gap-4">
              {clients &&
                clients.map((client) => (
                  <Item fetchData={fetchData} setLoad={setLoad} item={client} />
                ))}

              {/*    
                      <tr>
                        <td class="size-px pl-10 whitespace-nowrap">
                          <div class="ps-6 lg:ps-3 xl:ps-0 pe-6 py-3">
                            <div class="flex items-center gap-x-3">
                              <img
                                class="inline-block w-8 h-8 rounded-full"
                                src={client.data.logo}
                                alt="Image Description"
                              />
                              <div class="grow">
                                <span class="block text-sm font-semibold text-gray-800 dark:text-gray-200">
                                  {client.data.name}
                                </span>
                                <span class="block text-sm text-gray-500">
                                  {client.data.email}
                                </span>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td class="h-px w-72 whitespace-nowrap">
                          <div class="px-6 py-3">
                            <span class="block text-sm font-semibold text-gray-800 dark:text-gray-200">
                              {client.data.owner}
                            </span>
                            <span class="block text-sm text-gray-500">
                              Owner
                            </span>
                          </div>
                        </td>
                        <td class="size-px whitespace-nowrap">
                          <div class="px-6 py-3">
                            <span class="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-teal-100 text-teal-800 rounded-full dark:bg-teal-500/10 dark:text-teal-500">
                              <svg
                                class="size-2.5"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                viewBox="0 0 16 16"
                              >
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                              </svg>
                              Active
                            </span>
                          </div>
                        </td>
                        <td class="pl-10 text-start whitespace-nowrap">
                          {" "}
                          <div class="flex  gap-x-2">
                            <CountJobs id={client.id} />
                          </div>
                        </td>
                        <td class="size-px whitespace-nowrap">
                          <div class="px-6 py-3">
                            
                          </div>
                        </td>
                        <td class="size-px whitespace-nowrap">
                          <div class="px-6 py-1.5">
                          
                          </div>
                        </td>
                      </tr>
                    ))} */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
