import { Fragment, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import {
  ChevronDownIcon,
  PhoneIcon,
  PlayCircleIcon,
  RectangleGroupIcon,
} from "@heroicons/react/20/solid";
import {
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  ArrowPathIcon,
} from "@heroicons/react/24/outline";
import { collection, getDocs, query } from "firebase/firestore";
import { db } from "../../firebase";
import { Link } from "react-router-dom";
import { Spinner } from "flowbite-react";

const solutions = [
  {
    name: "Analytics",
    description: "Get a better understanding of your traffic",
    href: "#",
    icon: ChartPieIcon,
  },
  {
    name: "Engagement",
    description: "Speak directly to your customers",
    href: "#",
    icon: CursorArrowRaysIcon,
  },
  {
    name: "Security",
    description: "Your customers' data will be safe and secure",
    href: "#",
    icon: FingerPrintIcon,
  },
  {
    name: "Integrations",
    description: "Connect with third-party tools",
    href: "#",
    icon: SquaresPlusIcon,
  },
  {
    name: "Automations",
    description: "Build strategic funnels that will convert",
    href: "#",
    icon: ArrowPathIcon,
  },
];
const callsToAction = [
  { name: "Watch demo", href: "#", icon: PlayCircleIcon },
  { name: "Contact sales", href: "#", icon: PhoneIcon },
];

export default function ButtonExpertise({ name }) {
  const [load, setLoad] = useState(true);
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    const abortController = new AbortController();

    const fetchTabs = async () => {
      try {
        setLoad(false); // Commencer le chargement
        await Promise.all([fetchData()]);
      } catch (error) {
        console.error("an error occurred", error);
        // Ici, gérer l'affichage d'un message d'erreur à l'utilisateur si nécessaire
      } finally {
        setLoad(true); // Terminer le chargement, que l'opération réussisse ou échoue
      }
    };

    if (!abortController.signal.aborted) {
      fetchTabs();
    }

    return () => {
      setTabs([]);
      abortController.abort(); // Optionnel : Annuler les requêtes en cours si elles n'ont pas encore abouti
    };
  }, []);

  const fetchData = async () => {
    try {
      setLoad(false);
      const q = query(collection(db, "expertises"));
      const querySnapshot = await getDocs(q);

      const tabsArray = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));

      setLoad(true);
      setTabs(tabsArray);
    } catch (error) {
      console.error("an error occurred while fetching", error);
    }
  };

  return (
    <Popover className="  z-10 ">
      <div className="">
        <Popover.Button
          onClick={(e) => console.log(e)}
          className="inline-flex items-center outline-none hover:text-orange-500 uppercase gap-x-1 text-sm xl:text-base font-semibold  text-white"
        >
          {name}
          <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
        </Popover.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 -translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 -translate-y-1"
      >
        <Popover.Panel className="absolute left-3/4 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4">
          <div className="w-screen max-w-md max-h-[30rem]  flex-auto overflow-y-auto overflow-hidden rounded-3xl bg-gray-800 text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
            <div className="p-4">
              <h1 className="text-white  text-base">Discover our expertise</h1>
              <hr className="my-2 bg-yellow-50" />
              <ul>
                {tabs &&
                  tabs.map((item) => (
                    <li>
                      <a
                        href={`our-expertise?id=${item.id}`}
                        key={item.data.name}
                        className="group relative -mx-3 flex gap-6 rounded-lg p-3 text-sm leading-6 hover:bg-gray-700  sm:p-6 "
                      >
                        <div className="flex h-12 w-12 flex-none items-center justify-center rounded-lg  group-hover:bg-white">
                          <img
                            src={item.data.imgUrl}
                            className="h-full w-full object-contain rounded-lg"
                            alt="img"
                          />
                        </div>
                        <div>
                          <h1 className="text-gray-200">{item.data.name}</h1>
                          {item.data.description && (
                            <p className="text-gray-400">
                              {item.data.description.slice(0, 50) + "..."}
                            </p>
                          )}
                        </div>
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </Popover.Panel>
        {/*     <Popover.Panel className="absolute border-b-4  inset-x-0 top-0 -z-10 bg-gradient-to-br from-orange-800 bg-black pt-16 shadow-lg ring-1 ring-gray-900/5">
          {!load && (
            <div className="h-96">
              <div className=" absolute inset-0 flex justify-center items-center">
                <Spinner aria-label="Extra large spinner example" size="xl" />
              </div>
            </div>
          )}

          {load && (
            <div className="mx-auto max-w-7xl    gap-2 px-6 py-6 s sm:gap-x-6 sm:gap-y-0 sm:py-10 flex lg:gap-4 lg:px-8 xl:gap-8">
              <div className="flex w-full">
                {tabs &&
                  tabs.map((item) => (
                    <div
                      key={item.data.name}
                      className="group relative -mx-3 flex gap-6 rounded-lg p-3 text-sm leading-6 hover:bg-gray-700 sm:flex-col sm:p-6 "
                    >
                      <div className="flex h-16 w-16 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                        <img
                          src={item.data.imgUrl}
                          className="h-full w-full object-cover rounded-lg"
                          alt="img"
                        />
                      </div>
                      <div>
                        <Link
                          to={`our-expertise?id=${item.id}`}
                          className="font-semibold text-gray-200"
                        >
                          {item.data.name}
                          <span className="absolute inset-0" />
                        </Link>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </Popover.Panel> */}
      </Transition>
    </Popover>
  );
}
