import React, { useEffect, useRef } from "react";
import { ContactSection } from "../components/utils/ContactSection";
import JoinUs from "./join-us";
import { Container } from "../components/utils/Container";
import {
  CloudArrowUpIcon,
  LockClosedIcon,
  ServerIcon,
} from "@heroicons/react/20/solid";
import { useSearchParams } from "react-router-dom";
import { motion } from "framer-motion";
import Ebooks from "./ebooks";

function AboutUs() {
  const scrollToRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const scrollToDiv = () => {
    // Ensure the element is there
    if (scrollToRef.current) {
      scrollToRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  useEffect(() => {
    if (searchParams.get("s")) {
      scrollToDiv();
    }
  }, []);

  return (
    <>
      <div className="z-10  relative py-32 bg-gradient-to-bl from-orange-800/25 overflow-hidden ">
        <div className="absolute w-[700px] h-[700px] rounded-full    -z-30 bg-indigo-800 opacity-30 blur-3xl" />
        <div className="absolute w-[700px] h-[700px] rounded-full  right-2 -z-30 bg-orange-800 opacity-30 blur-3xl" />
        <div className="absolute w-[700px] h-[700px] rounded-full   bottom-0 left-1/2 -z-30 bg-teal-800 opacity-30 blur-3xl" />

        <div>
          <Container className={"md:mt-10 "}>
            <div className="grid  gap-4 ">
              <div className="rounded-lg text-sm md:text-lg  text-white   space-y-6">
                <div className="  flex justify-start items-center  ">
                  <h1 className="lg:text-8xl md:text-6xl text-4xl   font-bold  bg-clip-text py-2 text-transparent bg-gradient-to-r from-orange-500 to-violet-600">
                    About us
                  </h1>
                </div>

                <div className=" text-sm  sm:text-lg">
                  <p className="    ">
                    eMagine Solutions is one of the leading authorities on
                    leadership and talent globally. Our combined resources of
                    over 100 years professional experience mean that our clients
                    trust us with finding them the top talent globally and
                    candidates trust us to find them the right opportunity in a
                    timely and confidential manner.
                  </p>

                  <p>
                    Throughout our offices we provide complete solutions for our
                    clients, including traditional temporary and permanent
                    recruitment, executive search, recruitment process
                    outsourcing (RPO), salary survey’s, market mapping and
                    training and development courses.
                  </p>

                  <p>
                    Our global client list work with us not only because of our
                    expertise but also because of our honesty, integrity and
                    passion.
                  </p>
                </div>
              </div>

              <div className=" mt-10 ">
                <ul className="text-sm  sm:text-lg grid md:grid-cols-3 gap-5 ">
                  <li className="  p-4 border text-white  bg-gradient-to-br from-orange-700/25 shadow  rounded-lg ">
                    <div className="flex space-x-2 items-center">
                      <p className=" ">
                        Of the candidates we have placed over 80% have seen
                        positive career growth within 2 years
                      </p>
                    </div>
                  </li>
                  <li className=" p-4 border text-white  bg-gradient-to-br from-orange-700/25 shadow rounded-lg">
                    <div className="flex space-x-2 items-center">
                      <p className=" ">
                        72% of our clients engage eMagine Solutions across
                        multiple business units (recruitment, RPO, consulting
                        etc)
                      </p>
                    </div>
                  </li>
                  <li className=" border text-white  bg-gradient-to-br from-orange-700/25 shadow p-4 rounded-lg ">
                    <div className="flex space-x-2 items-center">
                      <p className=" ">
                        In our last client and candidate survey over 90% of
                        respondents rated eMagine Solutions service as
                        exceptional or very good.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </Container>

          <div ref={scrollToRef} className=" py-24 ">
            <div className="mx-auto grid max-w-7xl grid-cols-1 gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-3">
              <div className="mx-auto max-w-2xl lg:mx-0">
                <h2 className="lg:text-8xl md:text-6xl text-4xl font-bold tracking-tight text-gray-100 ">
                  The{" "}
                  <span className=" bg-clip-text text-transparent bg-gradient-to-r from-orange-500 to-violet-600">
                    {" "}
                    eMagine Way
                  </span>
                </h2>
                <p className="mt-6 text-sm sm:text-lg  text-gray-200">
                  The eMagine Way combines passion with precision in hiring,
                  connecting jobseekers with ideal opportunities and helping
                  companies achieve strategic success through tailored talent
                  solutions.
                </p>
              </div>
              <ul
                role="list"
                className="mx-auto  grid max-w-2xl grid-cols-1 gap-x-6 gap-y-14  lg:mx-0 lg:max-w-none lg:gap-x-8 xl:col-span-2"
              >
                <div className="border rounded-lg p-4 text-white space-y-6 bg-gradient-to-br from-indigo-600/25 shadow">
                  <h1 className=" font-semibold tracking-tight  text-lg sm:text-2xl ">
                    Discover Your Potential
                  </h1>
                  <p className=" text-sm sm:text-lg text-gray-200">
                    Experience a hiring process that goes beyond your resume. We
                    understand your career aspirations and align them with right
                    opportunities.
                  </p>

                  <ul class="space-y-4 text-left  text-gray-300">
                    <li class="flex items-center space-x-3 rtl:space-x-reverse">
                      <svg
                        class="flex-shrink-0 w-3.5 h-3.5  text-orange-600"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 16 12"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M1 5.917 5.724 10.5 15 1.5"
                        />
                      </svg>
                      <span>Personalized consulting</span>
                    </li>
                    <li class="flex items-center space-x-3 rtl:space-x-reverse">
                      <svg
                        class="flex-shrink-0 w-3.5 h-3.5  text-orange-600"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 16 12"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M1 5.917 5.724 10.5 15 1.5"
                        />
                      </svg>
                      <span> Transparent engagement</span>
                    </li>
                    <li class="flex items-center space-x-3 rtl:space-x-reverse">
                      <svg
                        class="flex-shrink-0 w-3.5 h-3.5  text-orange-600"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 16 12"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M1 5.917 5.724 10.5 15 1.5"
                        />
                      </svg>{" "}
                      <span>Alignment with career stages</span>
                    </li>
                  </ul>
                </div>
                <div className="border rounded-lg p-4 text-white space-y-6 bg-gradient-to-tl from-indigo-600/25 shadow">
                  <h1 className=" font-semibold tracking-tight  sm:text-2xl ">
                    Drive Your Business Forward
                  </h1>
                  <p className=" text-sm sm:text-lg text-gray-200">
                    Partner with us to achieve tangible results through tailored
                    talent solutions. Let’s deliver projects and foster growth
                    together.
                  </p>

                  <ul class="space-y-4 text-left  text-gray-300">
                    <li class="flex items-center space-x-3 rtl:space-x-reverse">
                      <svg
                        class="flex-shrink-0 w-3.5 h-3.5  text-orange-600"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 16 12"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M1 5.917 5.724 10.5 15 1.5"
                        />
                      </svg>
                      <span>Strategic business support</span>
                    </li>
                    <li class="flex items-center space-x-3 rtl:space-x-reverse">
                      <svg
                        class="flex-shrink-0 w-3.5 h-3.5  text-orange-600"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 16 12"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M1 5.917 5.724 10.5 15 1.5"
                        />
                      </svg>
                      <span>Insightful market analysis</span>
                    </li>
                    <li class="flex items-center space-x-3 rtl:space-x-reverse">
                      <svg
                        class="flex-shrink-0 w-3.5 h-3.5  text-orange-600"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 16 12"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M1 5.917 5.724 10.5 15 1.5"
                        />
                      </svg>
                      <span> Timely project execution</span>
                    </li>
                  </ul>
                </div>

                <li>
                  <div className=" flex justify-end">
                    <motion.a
                      role="button"
                      href="/contact"
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                      transition={{
                        type: "spring",
                        stiffness: 400,
                        damping: 17,
                      }}
                      className=" w-full rounded-lg    h-14 px-8 pt-5 pb-6 left-rounded border-2 border-orange-600 justify-center items-center inline-flex"
                    >
                      <div className="SubmitResume w-36 h-4 text-center text-white text-sm font-medium  uppercase leading-tight tracking-widest">
                        Contact Us
                      </div>
                    </motion.a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Ebooks />
      <JoinUs />
      <ContactSection />
    </>
  );
}

export default AboutUs;
