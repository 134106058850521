import {
  AcademicCapIcon,
  BanknotesIcon,
  CheckBadgeIcon,
  ClockIcon,
  PlusIcon,
  ReceiptRefundIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import GoBack from "../../components/utils/goBack";
import {
  Button,
  Checkbox,
  Label,
  Modal,
  Spinner,
  TextInput,
  Textarea,
} from "flowbite-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setOpenDi, setType, setUrl } from "../../redux/utils";
import { BiUserCircle } from "react-icons/bi";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  query,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import moment from "moment";
import { useSearchParams } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Recommendation({ item, handleDelete }) {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <div className="bg-white p-4  rounded-lg">
      <div className=" grid lg:grid-cols-6">
        <div className=" col-span-2">
          <div className=" rounded-full size-20">
            <img
              src={item.data.imgUrl}
              alt=""
              className=" size-full object-cover rounded-full "
            />
          </div>
        </div>
        <div className=" col-span-4 mt-2">
          <h1 className="text-lg font-semibold">{item.data.name}</h1>
          <span className=" text-gray-500 text-sm">
            {" "}
            {item.data.title} | {item.data.company}
          </span>
          <hr className="my-4" />
          <p className="mt-1 text-sm text-gray-600">{item.data.comment}</p>

          <div className=" flex gap-4 mt-4 justify-end ">
            <a
              onClick={() =>
                setSearchParams({
                  id: item.id,
                })
              }
              className=" text-blue-600 cursor-pointer"
            >
              Edit
            </a>
            <a
              onClick={() => handleDelete(item.id)}
              className=" text-orange-600"
            >
              Archive
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function Testimonial() {
  const [openModal, setOpenModal] = useState(false);
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [company, setCompany] = useState("");
  const [comment, setComment] = useState("");
  const [load, setLoad] = useState(true);
  const [testimonials, setTestimonials] = useState([]);
  const [count, setCount] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const { urlTestimonial, urlLogo } = useSelector((state) => state.utilsSlice);
  const dispatch = useDispatch();

  function onCloseModal() {
    setOpenModal(false);
    setName("");
    setTitle("");
    setCompany("");
    setComment("");
    dispatch(setUrl(""));
    dispatch(setType(""));
    setSearchParams({});
  }

  const handleSubmit = async () => {
    setLoad(false);

    if (searchParams.get("id")) {
      const id = searchParams.get("id");
      const docRef = doc(db, "testimonials", id);
      await updateDoc(docRef, {
        name,
        title,
        imgUrl: urlTestimonial,
        company,
        logo: urlLogo,
        comment,
        creatAt: moment.utc().unix(),
      }).then(async () => {
        onCloseModal();
        await fetchData();
      });
    } else {
      await addDoc(collection(db, "testimonials"), {
        name,
        title,
        imgUrl: urlTestimonial,
        company,
        logo: urlLogo,
        comment,
        creatAt: moment.utc().unix(),
      }).then(async () => {
        onCloseModal();
        await fetchData();
      });
    }
  };

  const handleDelete = async (id) => {
    const result = window.confirm("Do you want to continue ?");

    if (result) {
      setLoad(false);
      await deleteDoc(doc(db, "testimonials", id)).then(async () => {
        await Promise.all([fetchData(), fetchCount()]);
      });
    }
  };

  useEffect(() => {
    const abortController = new AbortController();

    const fetchTestimonials = async () => {
      try {
        setLoad(false); // Commencer le chargement
        await Promise.all([fetchData(), fetchCount()]);
      } catch (error) {
        console.error("an error occurred", error);
        // Ici, gérer l'affichage d'un message d'erreur à l'utilisateur si nécessaire
      } finally {
        setLoad(true); // Terminer le chargement, que l'opération réussisse ou échoue
      }
    };

    if (!abortController.signal.aborted) {
      fetchTestimonials();
    }

    return () => {
      setTestimonials([]);
      abortController.abort(); // Optionnel : Annuler les requêtes en cours si elles n'ont pas encore abouti
    };
  }, []);

  const fetchData = async () => {
    try {
      setLoad(false);
      const q = query(collection(db, "testimonials"));
      const querySnapshot = await getDocs(q);

      const testimonialArray = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));

      setLoad(true);
      setTestimonials(testimonialArray);
    } catch (error) {
      console.error("an error occurred while fetching", error);
    }
  };

  const fetchCount = async () => {
    try {
      setLoad(false);
      const coll = collection(db, "testimonials");
      const q = query(coll);
      const snapshot = await getCountFromServer(q);
      const count = snapshot.data().count;

      setLoad(true);
      setCount(count);
    } catch (error) {
      console.error("Error fetching", error);
    }
  };

  useEffect(() => {
    if (searchParams.get("id")) {
      (async () => {
        setOpenModal(true);
        const id = searchParams.get("id");

        const docRef = doc(db, "testimonials", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          if (docSnap.data()) {
            dispatch(setType("testifile"));
            dispatch(setUrl(docSnap.data().imgUrl));
            dispatch(setType("testilogo"));
            dispatch(setUrl(docSnap.data().logo));

            setName(docSnap.data().name);
            setTitle(docSnap.data().title);
            setCompany(docSnap.data().company);
            setComment(docSnap.data().comment);
          }
        } else {
          // docSnap.data() will be undefined in this case
          console.log("No such document!");
        }
      })();
    }
  }, [searchParams.get("id")]);

  return (
    <div className="min-h-[calc(100vh-4rem)] relative bg-gray-300 p-4 ">
      <h1 className=" text-lg md:text-3xl p-4 shadow items-center space-x-3  rounded-2xl inline-flex bg-orange-600 w-fit text-white">
        <GoBack /> <span>Testimonial | {count}</span>
      </h1>

      {!load && (
        <div className="h-96">
          <div className=" absolute inset-0 flex justify-center items-center">
            <Spinner aria-label="Extra large spinner example" size="xl" />
          </div>
        </div>
      )}

      {load && (
        <>
          <div className=" mt-10 flex justify-end">
            <button
              type="button"
              onClick={() => setOpenModal(true)}
              className="rounded-full self-end bg-orange-600 p-2 text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
            >
              <PlusIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
          <div className=" grid gap-4  md:grid-cols-3 mt-3">
            {testimonials &&
              testimonials.map((el) => (
                <Recommendation handleDelete={handleDelete} item={el} />
              ))}
          </div>
        </>
      )}

      <Modal show={openModal} size="xl" onClose={onCloseModal} popup>
        <Modal.Header />
        <Modal.Body className="relative">
          {!load && (
            <div className="h-96">
              <div className=" absolute inset-0 flex justify-center items-center">
                <Spinner aria-label="Extra large spinner example" size="xl" />
              </div>
            </div>
          )}
          {load && (
            <form onSubmit={handleSubmit} className="space-y-6">
              <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                Post new testimonial
              </h3>

              <div className="w-full ">
                <div className="flex items-center space-x-3">
                  {urlTestimonial && (
                    <img
                      src={urlTestimonial}
                      alt=""
                      srcset=""
                      className="w-20 h-20 rounded-lg object-cover"
                    />
                  )}

                  {!urlTestimonial && (
                    <div className="w-20 h-20 border rounded-lg">
                      <BiUserCircle className="w-full h-full text-gray-400 animate-pulse" />
                    </div>
                  )}

                  <button
                    type="button"
                    onClick={() => dispatch(setOpenDi("testifile"))}
                    className="rounded-md inline-flex items-center text-sm bg-orange-500 px-3.5 py-2.5  space-x-2 font-semibold text-white shadow-sm hover:bg-orange-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-500"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                      />
                    </svg>
                    <span>Upload Profile</span>
                  </button>
                </div>
              </div>

              <div>
                <div className="mb-2 block">
                  <Label htmlFor="name" value="Full name" />
                </div>
                <TextInput
                  id="name"
                  onChange={(e) => setName(e.target.value)}
                  placeholder="John Smith"
                  value={name}
                  required
                />
              </div>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="title" value="Title" />
                </div>
                <TextInput
                  id="title"
                  onChange={(e) => setTitle(e.target.value)}
                  value={title}
                  placeholder="Product Manager"
                  required
                />
              </div>

              <hr />
              <div className="mb-2 block">
                <Label htmlFor="company" value="Company" />
              </div>
              <div className="w-full ">
                <div className="flex items-center space-x-3">
                  {urlLogo && (
                    <img
                      src={urlLogo}
                      alt=""
                      srcset=""
                      className="w-20 h-20 rounded-lg object-contain"
                    />
                  )}

                  {!urlLogo && (
                    <div className="w-20 h-20 border rounded-lg">
                      <BiUserCircle className="w-full h-full text-gray-400 animate-pulse" />
                    </div>
                  )}

                  <button
                    type="button"
                    onClick={() => dispatch(setOpenDi("testilogo"))}
                    className="rounded-md inline-flex items-center text-sm bg-teal-500 px-3.5 py-2.5  space-x-2 font-semibold text-white shadow-sm hover:bg-orange-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-500"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                      />
                    </svg>
                    <span>Upload Logo</span>
                  </button>
                </div>
              </div>

              <div>
                <TextInput
                  id="company"
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                  placeholder="Capsule"
                  required
                />
              </div>
              <hr />
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="password" value="Comment" />
                </div>
                <Textarea
                  id="comment"
                  value={comment}
                  placeholder="Leave a comment..."
                  onChange={(e) => setComment(e.target.value)}
                  required
                  rows={4}
                />
              </div>

              <div className="w-full flex justify-end">
                <Button type="submit" className="bg-orange-600">
                  Post
                </Button>
              </div>
            </form>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}
