import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { auth, db } from "../firebase";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { Spinner } from "flowbite-react";

function SignUp() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [password, setPassword] = useState("");
  const [passwordR, setPasswordR] = useState("");
  const [load, setLoad] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoad(false);

    const create = await createUserWithEmailAndPassword(
      auth,
      searchParams.get("email"),
      password
    );

    if (create.user) {
      await sendEmailVerification(create.user).then(async () => {
        try {
          const q = query(
            collection(db, "invitation"),
            where("token", "==", searchParams.get("token")),
            where("email", "==", searchParams.get("email"))
          );

          const querySnapshot = await getDocs(q);
          const invitationArray = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }));

          await setDoc(doc(db, "users", create.user.uid), {
            email: create.user.email,
          });

          await deleteDoc(doc(db, "invitation", invitationArray[0].id)).then(
            () => setLoad(true)
          );
        } catch (error) {
          console.error(
            "Erreur lors de la récupération des invitations:",
            error
          );
        }
      });
    }
  };

  return (
    <div className="h-screen bg-gradient-to-b flex justify-center items-center from-orange-800/30">
      <div class="w-full lg:max-w-xl p-6 space-y-8 sm:p-8 bg-white rounded-lg shadow-xl dark:bg-gray-800">
        <h2 class="text-2xl font-bold text-gray-900 dark:text-white">
          Sign up for {searchParams.get("email")}
        </h2>

        <form onSubmit={handleSubmit} class="space-y-6">
          <div>
            <label
              for="email"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Your email
            </label>
            <input
              type="email"
              id="email"
              readOnly
              value={searchParams.get("email")}
              class="shadow-sm bg-gray-50 border cursor-not-allowed border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
              placeholder="name@flowbite.com"
              required
            />
          </div>
          <div>
            <label
              for="password"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Your password
            </label>
            <input
              type="password"
              id="password"
              onChange={(e) => setPassword(e.currentTarget.value)}
              class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
              required
            />
          </div>
          <div>
            <label
              for="repeat-password"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Repeat password
            </label>
            <input
              type="password"
              id="repeat-password"
              onChange={(e) => setPasswordR(e.currentTarget.value)}
              class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
              required
            />
          </div>
          <div class="flex items-start mb-5">
            <div class="flex items-center h-5">
              <input
                id="terms"
                type="checkbox"
                value=""
                class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
                required
              />
            </div>
            <label
              for="terms"
              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              I agree with the{" "}
              <a
                target="_blank"
                href="/privacy-policy"
                class="text-blue-600 hover:underline dark:text-blue-500"
              >
                terms and conditions
              </a>
            </label>
          </div>
          <button
            type="submit"
            disabled={password !== passwordR}
            class="text-white disabled:bg-slate-200 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            {!load && <Spinner aria-label="spin" />}
            {load && <span> Complete my invitation</span>}
          </button>
        </form>
      </div>
    </div>
  );
}

export default SignUp;
