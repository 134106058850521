import React from "react";
import LayoutAdmin from "./layout";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./dasboard";

import NotFound from "../components/not-found/not-found";
import Jobs from "../landing/jobs";

import Settings from "./settings";
import Blog from "./blog";
import BlogEditor from "./blog/blog-editor";
import ListOfArticles from "./blog/list-of-articles";
import Openning from "./openning/index";
import ManatalPosition from "./openning/manatal-position";
import LivePosition from "./openning/live-position";
import CreatePosition from "./openning/create-position";
import Clients from "./clients";
import Newclient from "./clients/new-client";
import ListOfClients from "./clients/list";
import OneClient from "./clients/one-client";
import Candidate from "./candidate.js/candidate";
import Profile from "./profile/profile";
import Testimonial from "./testimonial/testimonial";
import Contact from "./contact/contact";
import ExpertiseEditor from "./expertise/expertise-editor";
import Expertise from "./expertise";
import Ebooks from "./ebooks/ebooks";

function AdminRoute() {
  return (
    <Routes>
      <Route exact path="/" element={<LayoutAdmin />}>
        <Route index element={<Dashboard />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/list-of-articles" element={<ListOfArticles />} />
        <Route path="/blog-editor" element={<BlogEditor />} />
        <Route path="/openning" element={<Openning />} />
        <Route path="/mantal-live-position" element={<ManatalPosition />} />
        <Route path="/create-live-position" element={<CreatePosition />} />
        <Route path="/live-position" element={<LivePosition />} />
        <Route path="/clients" element={<Clients />} />
        <Route path="/client-list" element={<ListOfClients />} />
        <Route path="/new-client" element={<Newclient />} />
        <Route path="/one-client/:id" element={<OneClient />} />
        <Route path="/candidate" element={<Candidate />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/expertise" element={<Expertise />} />
        <Route path="/ebooks" element={<Ebooks />} />

        <Route path="/testimonial" element={<Testimonial />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}

export default AdminRoute;
