import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function Cookies() {
  const [cookieStatus, setCookieStatus] = useState(true);
  function handleCookies() {
    setCookieStatus(true);
    window.localStorage.setItem("cookie", true);
  }

  useEffect(() => {
    if (!window.localStorage.getItem("cookie")) {
      setCookieStatus(false);
    }
  }, []);

  return (
    <div
      className={`pointer-events-none ${
        cookieStatus ? "hidden" : ""
      } transition-all fixed inset-x-0 bottom-0 px-6 z-[10000000000000000] pb-6`}
    >
      <div className="pointer-events-auto ml-auto max-w-xl  border border-orange-600 bg-black text-white p-6 shadow-lg ring-1 ring-gray-900/10">
        <p className="text-sm leading-6 0">
          This website uses cookies to supplement a balanced diet and provide a
          much deserved reward to the senses after consuming bland but
          nutritious meals. Accepting our cookies is optional but recommended,
          as they are delicious. See our{" "}
          <Link
            to={"/privacy-policy?s=cookie"}
            className="font-semibold text-orange-600"
          >
            cookie policy
          </Link>
          .
        </p>
        <div className="mt-4 flex items-center gap-x-5">
          <button
            type="button"
            onClick={handleCookies}
            className="rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
          >
            Accept all
          </button>
          <button
            onClick={handleCookies}
            type="button"
            className="text-sm font-semibold leading-6 "
          >
            Reject all
          </button>
        </div>
      </div>
    </div>
  );
}
