import React, { useEffect, useState } from "react";
import GoBack from "../../components/utils/goBack";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getCountFromServer,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import moment from "moment";
import { Spinner } from "flowbite-react";
import { Button, Modal } from "flowbite-react";

const Item = ({
  setOpenPanel,
  item,
  setItemToget,
  setLoad,
  fetchData,
  setOpenModal,
}) => {
  const handleDelete = async () => {
    const result = window.confirm("Do you want to continue ?");

    if (result) {
      setLoad(false);

      await deleteDoc(doc(db, "contact", item.id)).then(async () => {
        await fetchData();
      });
    }
  };

  return (
    <>
      <div className="border border-dashed rounded-lg p-4 h-fit bg-white">
        <h1 className="md:text-xl text-sm">
          {item.data.name} |{" "}
          <span className="font-bold"> {item.data.company}</span>{" "}
        </h1>

        <div className="border-l pl-3 text-xs md:text-sm mt-4 text-gray-400">
          <h1>{item.data.email} </h1>
          <h1>{item.data.phone} </h1>
        </div>
        <p className=" text-end md:text-xs text-[0.65rem] my-2">
          sent {moment.unix(item.data.creatAt).local().fromNow()}
        </p>

        <p className="p-2 bg-slate-200 text-xs md:text-sm rounded-lg">
          {item.data.message}
        </p>

        <div className="flex justify-end space-x-4 mt-6">
          <span
            onClick={() => handleDelete()}
            className="md:text-sm font-semibold text-xs text-orange-600 cursor-pointer"
          >
            Delete
          </span>
          <span
            className="md:text-sm font-semibold text-xs text-green-600 cursor-pointer "
            onClick={() => {
              setOpenPanel(true);
              setItemToget(item);
              setOpenModal(true);
            }}
          >
            Reply
          </span>
        </div>
      </div>
    </>
  );
};

const ConversationPanel = ({
  item,
  setLoad,
  load,
  fetchData,
  setOpenModal,
}) => {
  const docRef = doc(db, "contact", item.id);
  const [message, setMessage] = useState("");

  const handleReply = async (e) => {
    e.preventDefault();
    setLoad(false);
    await updateDoc(docRef, {
      status: "success",
    });

    await addDoc(collection(db, "mail"), {
      to: item.data.email,
      message: {
        subject: "eMagine solutions replies to your inquiry",
        html: message,
      },
    }).then(() => {
      setOpenModal(false);
      fetchData();
    });
  };

  return (
    <>
      {!load && (
        <div className="h-96">
          <div className=" absolute inset-0 flex justify-center items-center">
            <Spinner aria-label="Extra large spinner example" size="xl" />
          </div>
        </div>
      )}
      {load && (
        <form onSubmit={handleReply} className=" max-w-3xl">
          <div className="flex flex-col">
            <h1 className="md:text-xl text-sm">
              {item.data.name} | {item.data.company}
            </h1>
            <p className="p-4 mt-4 md:text-sm text-xs bg-gray-300 rounded-lg h-fit">
              {item.data.message}
            </p>

            <div className="mt-4">
              <div className="mt-2">
                <textarea
                  rows={4}
                  onChange={(e) => setMessage(e.target.value)}
                  name="comment"
                  placeholder="Write your answer here..."
                  id="comment"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm text-xs sm:leading-6"
                  defaultValue={""}
                />
              </div>
            </div>

            <button
              type="submit"
              className="text-sm text-green-600 font-semibold cursor-pointer text-end mt-5"
            >
              Reply
            </button>
          </div>
        </form>
      )}
    </>
  );
};

function Contact() {
  const [openPanel, setOpenPanel] = useState(false);
  const [itemToget, setItemToget] = useState({});
  const [count, setCount] = useState(0);
  const [load, setLoad] = useState(true);
  const [contacts, setContacts] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const fetchData = async () => {
    try {
      setLoad(false);

      const q = query(
        collection(db, "contact"),
        where("status", "==", "pending"),
        orderBy("creatAt", "desc")
      );

      const querySnapshot = await getDocs(q);

      const contactArray = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));


      setLoad(true);
      setOpenPanel(false);
      setItemToget({});
      setContacts(contactArray); // Mise à jour de l'état en une seule opération
    } catch (error) {
      console.error("Erreur lors de la récupération des clients:", error);
      // Gérer l'erreur comme il convient (par exemple, en définissant un état d'erreur)
    }
  };

  const fetchCount = async () => {
    try {
      setLoad(false);
      const coll = collection(db, "contact");
      const q = query(coll, where("status", "==", "pending"));
      const snapshot = await getCountFromServer(q);
      const count = snapshot.data().count;

      setLoad(true);
      setCount(count);
    } catch (error) {
      console.error("Error fetching", error);
    }
  };

  useEffect(() => {
    const abortController = new AbortController();

    const fetchContact = async () => {
      try {
        setLoad(false); // Commencer le chargement
        await Promise.all([fetchData(), fetchCount()]);
      } catch (error) {
        console.error("an error occurred", error);
        // Ici, gérer l'affichage d'un message d'erreur à l'utilisateur si nécessaire
      } finally {
        setLoad(true); // Terminer le chargement, que l'opération réussisse ou échoue
      }
    };

    if (!abortController.signal.aborted) {
      fetchContact();
    }

    return () => {
      abortController.abort(); // Optionnel : Annuler les requêtes en cours si elles n'ont pas encore abouti
    };
  }, []);

  return (
    <div className="h-[calc(100vh-4rem)] overflow-hidden p-4 bg-white">
      <h1 className="md:text-3xl text-lg p-4 shadow items-center space-x-3  rounded-2xl inline-flex bg-orange-600 w-fit text-white">
        <GoBack /> <span>Contact | {count}</span>
      </h1>

      {!load && (
        <div className="h-96">
          <div className=" absolute inset-0 flex justify-center items-center">
            <Spinner aria-label="Extra large spinner example" size="xl" />
          </div>
        </div>
      )}

      {load && (
        <div className="grid md:grid-cols-12 mt-4 h-[90%] ">
          <div className=" md:col-span-3 gap-4 flex flex-col p-2 overflow-y-scroll">
            {contacts &&
              contacts.map((item) => (
                <Item
                  item={item}
                  fetchData={fetchData}
                  setLoad={setLoad}
                  setItemToget={setItemToget}
                  setOpenPanel={setOpenPanel}
                  setOpenModal={setOpenModal}
                />
              ))}
          </div>

          {/* {!openPanel && (
            <div className="md:col-span-9 md:flex hidden justify-center items-center overflow-y-scroll ">
              <h1 className="text-xl">Reply inquire</h1>
            </div>
          )}
          {openPanel && (
            <div className="md:col-span-9 md:flex hidden justify-center p-6 overflow-y-scroll ">
              {" "}
              <ConversationPanel
                fetchData={fetchData}
                setLoad={setLoad}
                item={itemToget}
                load={load}
                setOpenModal={setOpenModal}
              />{" "}
            </div>
          )} */}
        </div>
      )}

      <Modal dismissible show={openModal} onClose={() => setOpenModal(false)}>
        <Modal.Header>Reply</Modal.Header>
        <Modal.Body>
          <ConversationPanel
            fetchData={fetchData}
            setLoad={setLoad}
            item={itemToget}
            load={load}
            setOpenModal={setOpenModal}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Contact;
