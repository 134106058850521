import React, { useState } from "react";
import ExpertiseList from "./expertise-list";
import { ModalTab } from "./modal-add-tab";
import { useSearchParams } from "react-router-dom";
import ExpertiseEditor from "./expertise-editor";
import GoBack from "../../components/utils/goBack";

function Expertise() {
  const [openModal, setOpenModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const id = searchParams.get("id");

  return (
    <div>
      {openModal && (
        <ModalTab setOpenModal={setOpenModal} openModal={openModal} />
      )}

      {!id && <ExpertiseList setOpenModal={setOpenModal} />}

      {id && (
        <div>
          <ExpertiseEditor />
        </div>
      )}
    </div>
  );
}

export default Expertise;
