import React, { useState } from "react";

const Carousel = ({ items }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [direction, setDirection] = useState("");

  const previousSlide = () => {
    if (isAnimating) return;
    setIsAnimating(true);
    setDirection("left");
    setTimeout(() => {
      setCurrentIndex((currentIndex - 1 + items.length) % items.length);
      setIsAnimating(false);
    }, 500); // Durée de l'animation
  };

  const nextSlide = () => {
    if (isAnimating) return;
    setIsAnimating(true);
    setDirection("right");
    setTimeout(() => {
      setCurrentIndex((currentIndex + 1) % items.length);
      setIsAnimating(false);
    }, 500); // Durée de l'animation
  };

  const getItemIndex = (position) => {
    return (currentIndex + position + items.length) % items.length;
  };
  return (
    <div className="relative w-full flex-col flex justify-center items-center">
      <div className="absolute w-[700px] h-[700px] rounded-full  -z-0 -left-20 bg-indigo-800 opacity-30 blur-3xl" />
      <div className="absolute w-[700px] h-[700px] rounded-full -z-0  right-2  bg-orange-800 opacity-30 blur-3xl" />
      <div className="absolute w-[700px] h-[700px] rounded-full -z-0  bottom-1/2 left-1/2  bg-teal-800 opacity-30 blur-3xl" />

      <div className="flex space-x-4 overflow-hidden">
        {[-1, 0, 1].map((position) => {
          const itemIndex = getItemIndex(position);

          const item = items[itemIndex];
          const isCenter = position === 0;
          const isLeft = position === -1;
          const isRight = position === 1;

          return (
            <div
              onClick={() => (isLeft ? previousSlide() : nextSlide())}
              key={itemIndex}
              className={`
              transition-transform duration-500 ease-in-out cursor-pointer
              
            ${
              isAnimating && direction === "left" ? "animate-roulette-left" : ""
            } ${
                isAnimating && direction === "right"
                  ? "animate-roulette-right"
                  : ""
              }

              ${isCenter ? "" : "transform scale-80 "}  ${
                !isCenter && " translate-y-1/2"
              } ${!isCenter ? "lg:block hidden" : ""}  ${
                isCenter && "lg:w-4/6 grow"
              }  ${!isCenter && "lg:w-2/6 shrink"} `}
              style={{ left: `${(position + 1) * 33.33}%` }}
            >
              <div
                class={`flex rounded-lg relative border transition-transform duration-500 ease-in-out `}
              >
                <div
                  class={`flex rounded-lg  ${
                    isCenter ? " h-[600px]" : "h-full"
                  }  relative flex-col  items-center w-full bg-slate-900 z-30`}
                >
                  <div className="  h-40 rounded-lg  flex items-center justify-center bg-gradient-to-tl p-6 from-orange-600/70 w-full z-10">
                    <div className=" inline-flex p-4 items-center space-x-4">
                      <div className="size-20 shrink-0   z-30  border bg-gray-300 border-gray-100 rounded-full ">
                        <img
                          class=" rounded-full w-full object-cover h-full"
                          src={item.data.imgUrl}
                          alt="Image Description"
                        />
                      </div>

                      <div
                        class={` flex ${
                          isCenter && "justify-start items-start"
                        }  ${
                          isLeft && "justify-start items-start"
                        }  flex-col   ms-3`}
                      >
                        <p class="text-lg sm:text-xl font-semibold  text-gray-100">
                          {item.data.name}
                        </p>
                        {item.data.company && isCenter && (
                          <p class="text-sm text-gray-300">
                            {item.data.title} | {item.data.company}
                          </p>
                        )}
                        {item.data.company && !isCenter && (
                          <p class="text-sm text-gray-300">
                            {item.data.title}
                          </p>
                        )}
                        {!item.data.company && (
                          <>
                            <p class="text-sm text-gray-300">
                              {item.data.title}
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  {isCenter && (
                    <div className=" flex justify-center items-center flex-col  h-full ">
                      <p class=" text-sm mt-4 p-4 text-center  w-full italic md:text-lg text-gray-200">
                        {item.data.comment}
                      </p>

                      {item.data.logo && (
                        <div className=" my-3">
                          <div className="   size-24  lg:size-30 p-2 rounded-sm">
                            <img
                              src={item.data.logo}
                              className=" size-full object-contain aspect-square rounded-sm "
                              alt="logo"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className=" inline-flex mt-4 rounded-lg ">
        <button
          className="p-2 z-30 text-gray-300 w-20 text-lg bg-gradient-to-tl rounded-s-lg from-orange-700/70  hover:text-gray-900  hover:bg-gray-300 transition-colors duration-300"
          onClick={previousSlide}
        >
          ‹
        </button>
        <button
          className=" z-30 -right-4 p-2 w-20 text-lg text-gray-300 bg-gradient-to-tr rounded-e-lg from-orange-700/70 hover:text-gray-900  hover:bg-gray-300 transition-colors duration-300"
          onClick={nextSlide}
        >
          ›
        </button>
      </div>
    </div>
  );
};

export default Carousel;
