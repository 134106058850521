import { Border } from "../components/utils/Border";
import { Button } from "../components/utils/Button";
import { ContactSection } from "../components/utils/ContactSection";
import { Container } from "../components/utils/Container";
import { FadeIn } from "../components/utils/FadeIn";
import Ebooks from "./ebooks";

import InsightSection from "./insight-section";
import JoinUs from "./join-us";

export default function Blog() {
  return (
    <div className="relative overflow-hidden">
      <div className="absolute w-[700px] h-[700px] rounded-full  -z-0 bg-indigo-800 opacity-30 blur-3xl" />
      <div className="absolute w-[700px] h-[700px] rounded-full -z-0  right-2  bg-orange-800 opacity-30 blur-3xl" />

      <div className="absolute w-[700px] h-[700px] rounded-full -z-0  bottom-1/2 left-1/2  bg-teal-800 opacity-30 blur-3xl" />
      <div className=" pt-32 overflow-hidden relative bg-gradient-to-br z-10 ">
        <div class="max-w-[85rem] px-4   lg:px-8  mx-auto">
          <FadeIn className=" flex items-center  ">
            <div className="flex  flex-col  text-4xl md:text-8xl  text-gray-300">
              <h1 className="bg-clip-text py-2 text-transparent font-bold bg-gradient-to-r from-orange-500 to-violet-600">
                Insights & Surveys
              </h1>

              <p className="text-sm md:text-lg  max-w-2xl mt-1 ">
                We continuously strive to showcase our proficiency in refining
                not just your recruitment plans but also your overall business
                strategy.
              </p>
            </div>
          </FadeIn>
        </div>
      </div>

      <div className=" mt-10  ">
        <div class="max-w-[85rem] px-4   lg:px-8  mx-auto">
          <InsightSection
            all
            color="orange"
            desiredType="News"
            className="  w-full  overflow-hidden z-[100] relative     "
          />
        </div>
      </div>

      <div className="border-t-4 px-4 py-10 border-t-orange-600">
        <div class="max-w-[85rem] relative  px-10 xl:px-0  mx-auto  flex justify-center items-center">
          <div className=" absolute inset-0 -z-0 bg-gradient-to-r blur-2xl from-orange-600 to-violet-800"></div>

          <iframe
            className=" rounded-lg aspect-video z-10 p-4 object-cover  w-full "
            src="https://www.youtube.com/embed/8ZtONbRlsRs?si=y98c5Ugpnn2hZRWS"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <Ebooks />
      <JoinUs />
      <ContactSection />
    </div>
  );
}
