import React, { useEffect, useState } from "react";
import GoBack from "../../components/utils/goBack";
import { useDispatch, useSelector } from "react-redux";
import { setOpenDi, setType, setUrl } from "../../redux/utils";
import { db } from "../../firebase";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { Spinner } from "flowbite-react";
import moment from "moment/moment";
import { useSearchParams } from "react-router-dom";

function Newclient() {
  const { urlClient } = useSelector((state) => state.utilsSlice);
  const [clientName, setClientName] = useState("");
  const [location, setLocation] = useState("");
  const [website, setWebsite] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [owner, setOwner] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  const [load, setLoad] = useState(true);

  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    setLoad(false);
    e.preventDefault();

    if (searchParams.get("id")) {
      const clientRef = doc(db, "clients", searchParams.get("id"));

      await updateDoc(clientRef, {
        name: clientName,
        logo: urlClient,
        location: location,
        website: website,
        phone: phone,
        email: email,
        owner: owner,
      });
    } else {
      const client = await addDoc(collection(db, "clients"), {
        name: clientName,
        logo: urlClient,
        location: location,
        website: website,
        phone: phone,
        email: email,
        owner: owner,
        creatAt: moment.utc().unix(),
      });
    }

    setLoad(false);
    dispatch(setType(""));
    dispatch(setUrl(""));
    
    setTimeout(() => {
      window.history.back();
    }, 3000);
  };

  useEffect(() => {
    if (searchParams.get("id")) {
      fetchClient();
    }
  }, [searchParams.get("id")]);

  const fetchClient = async () => {
    try {
      setLoad(false);
      const docRef = doc(db, "clients", searchParams.get("id"));
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        setLoad(true);
        setClientName(data.name);
        setPhone(data.phone);
        setEmail(data.email);
        setLocation(data.location);
        setWebsite(data.website);
        setOwner(data.owner);
        dispatch(setType("imgC"));
        dispatch(setUrl(data.logo));
      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }
    } catch (error) {
      console.error("an error occurred while fetching", error);
    }
  };

  return (
    <div className="min-h-[calc(100vh-4rem)] p-4 bg-gray-300">
      <h1 className="text-3xl p-4 items-center space-x-3  rounded-2xl shadow inline-flex bg-orange-600 w-fit text-white">
        <GoBack />{" "}
        {searchParams.get("id") ? (
          <span>Update Client</span>
        ) : (
          <span>Create New Client</span>
        )}
      </h1>

      <div className="flex justify-center items-center mt-10">
        <div className="bg-white w-[40rem] rounded-lg p-4">
          <h1 className="text-xl">New Client</h1>

          <form onSubmit={handleSubmit} className="mt-10">
            <div className="flex mb-10">
              <div className="grid grid-cols-2 ">
                <div className="flex items-center space-x-4 ">
                  <div className="h-20 w-20 rounded flex items-center justify-center bg-gray-100">
                    {!urlClient && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z"
                        />
                      </svg>
                    )}

                    {urlClient && (
                      <img
                        className="h-full w-full rounded object-cover"
                        src={urlClient}
                        alt=""
                      />
                    )}
                  </div>
                  <button
                    onClick={() => dispatch(setOpenDi("imgC"))}
                    type="button"
                    className="rounded-md inline-flex items-center space-x-3 bg-indigo-50 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                  >
                    <span>Upload</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                      />
                    </svg>
                  </button>
                </div>
              </div>

              <div className="flex items-end">
                <div className="inline-flex items-center space-x-3 ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-8 h-8 text-gray-300 animate-bounce"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                    />
                  </svg>

                  <input
                    type="text"
                    name="location"
                    id="location"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    className="block w-full placeholder:italic italic rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="location"
                  />
                </div>
              </div>
            </div>
            <div className="mt-3 grid md:grid-cols-2 gap-3">
              <div>
                <label
                  htmlFor="client-name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Client name <span className="text-red-400">*</span>
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="client-name"
                    id="client-name"
                    value={clientName}
                    onChange={(e) => setClientName(e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="blackrock"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="owner"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Owner name <span className="text-red-400">*</span>
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="owner"
                    value={owner}
                    id="owner"
                    onChange={(e) => setOwner(e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="john doe"
                  />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-2 mt-3 gap-3">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="exemple@emagine.com"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Phone
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="+123456789"
                  />
                </div>
              </div>
            </div>

            <div className="mt-3">
              <label
                htmlFor="website"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Website
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  onChange={(e) => setWebsite(e.target.value)}
                  name="website"
                  id="website"
                  value={website}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="https://www.google.com"
                />
              </div>
            </div>

            <div className=" inline-flex justify-end w-full mt-3">
              <button
                type="submit"
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {!load && (
                  <Spinner aria-label="Spinner button example" size="sm" />
                )}
                {load && <span>Continue</span>}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Newclient;
