import React, { useEffect, useRef, useState } from "react";
import JoinUs from "./join-us";
import { ContactSection } from "../components/utils/ContactSection";
import { FadeIn } from "../components/utils/FadeIn";
import { Container } from "../components/utils/Container";
import { storage } from "../firebase";
import { getDownloadURL, ref } from "firebase/storage";
import { useSearchParams } from "react-router-dom";
import { ModalGetData } from "../components/modals/modal-get-data";

function PrivacyPolicy() {
  const [tbs, setTbs] = useState(null);
  const [cppw, setCppw] = useState(null);
  const scrollToRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [openModal, setOpenModal] = useState(false);
  const [document, setDocument] = useState(null);

  const scrollToDiv = () => {
    // Ensure the element is there
    if (scrollToRef.current) {
      scrollToRef.current.classList.add("bg-green-400", "p-4", "rounded-lg");

      setTimeout(() => {
        scrollToRef.current.classList.remove(
          "bg-green-400",
          "p-4",
          "rounded-lg"
        );
      }, 10000);

      scrollToRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  useEffect(() => {
    if (searchParams.get("s")) {
      scrollToDiv();
    }
  }, []);

  useEffect(() => {
    (async () => {
      const gsReferenceTBS = ref(
        storage,
        "gs://e-solutions-25db2.appspot.com/tob.pdf"
      );
      const gsReferenceCPPW = ref(
        storage,
        "gs://e-solutions-25db2.appspot.com/cppw.pdf"
      );

      await getDownloadURL(gsReferenceTBS)
        .then((url) => {
          setTbs(url);
        })
        .catch((error) => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/object-not-found":
              // File doesn't exist
              break;
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;
            case "storage/canceled":
              // User canceled the upload
              break;

            // ...

            case "storage/unknown":
              // Unknown error occurred, inspect the server response
              break;
          }
        });
      await getDownloadURL(gsReferenceCPPW)
        .then((url) => {
          setCppw(url);
        })
        .catch((error) => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/object-not-found":
              // File doesn't exist
              break;
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;
            case "storage/canceled":
              // User canceled the upload
              break;

            // ...

            case "storage/unknown":
              // Unknown error occurred, inspect the server response
              break;
          }
        });
    })();
  }, []);

  return (
    <div className=" relative overflow-hidden">
      <div className="absolute w-[700px] h-[700px] rounded-full  -z-0 bg-indigo-800 opacity-30 blur-3xl" />
      <div className="absolute w-[700px] h-[700px] rounded-full -z-0  right-1/2  bg-orange-800 opacity-30 blur-3xl" />
      <div className="absolute w-[700px] h-[700px] rounded-full -z-0  right-1/4  bg-violett-800 opacity-30 blur-3xl" />
      <div className="absolute w-[700px] h-[700px] rounded-full -z-0  bottom-1/2 left-1/2  bg-teal-800 opacity-30 blur-3xl" />
      <div className="absolute w-[700px] h-[700px] rounded-full -z-0  bottom-0    bg-teal-800 opacity-30 blur-3xl" />
      <div className="absolute w-[900px] h-[900px] rounded-full -z-0   left-1/2 bottom-5  bg-orange-800 opacity-30 blur-3xl" />
      <div className=" py-32 overflow-hidden relative bg-gradient-to-br z-10 ">
        <div class="max-w-[85rem] px-4   lg:px-8  mx-auto">
          <FadeIn className=" flex items-center  ">
            <div className="flex  flex-col  text-4xl md:text-8xl  text-gray-300">
              <h1 className="bg-clip-text py-2 text-transparent font-bold bg-gradient-to-r from-orange-500 to-violet-600">
                Privacy Policy
              </h1>

              <p className="text-sm md:text-lg  max-w-3xl mt-1 ">
                At eMagine, we prioritize your privacy and are committed to
                protecting your personal information. Our Privacy Policy
                outlines how we collect, use, and safeguard your data, ensuring
                transparency and trust in all our interactions.
              </p>
            </div>
          </FadeIn>

          <div className=" shadow-2xl rounded-lg p-6 my-6 bg-slate-900/40 text-white mx-auto py-8">
            <h1 className="text-3xl font-bold mb-4">
              eMagine Solutions DMCC Privacy Policy
            </h1>
            <p className="mb-4">
              Welcome to EMagine Solutions DMCC's Privacy Policy. This document
              explains how our organization uses the personal data we collect
              when you use our website.
            </p>

            <div className="mb-8">
              <h2 className="text-xl font-bold mb-2">Data Collection:</h2>
              <p>
                We collect various types of data to provide our services
                effectively, including personal information necessary for job
                applications and job search services.
              </p>
            </div>

            <div className="mb-8">
              <h2 className="text-xl font-bold mb-2">
                Data Collection Methods:
              </h2>
              <p>
                Your data is collected directly when you register online,
                complete surveys, or interact with our website. We may also
                receive data via job boards and referrals.
              </p>
            </div>

            <div className="mb-8">
              <h2 className="text-xl font-bold mb-2">Data Usage:</h2>
              <p>
                We use your data to process job applications, provide services,
                and offer relevant products and services. With your consent, we
                may also send you marketing information.
              </p>
            </div>

            <div className="mb-8">
              <h2 className="text-xl font-bold mb-2">Data Storage:</h2>
              <p>
                Your data is securely stored in the United States of America. We
                retain data for up to 36 months, unless otherwise stated.
              </p>
            </div>

            <div className="mb-8">
              <h2 className="text-xl font-bold mb-2">Marketing:</h2>
              <p>
                We may send you information about products and services we think
                you might like, as well as those of our partner companies. You
                can opt out at any time.
              </p>
            </div>

            <div className="mb-8">
              <h2 className="text-xl font-bold mb-2">
                Data Protection Rights:
              </h2>
              <p>
                You have the right to access, rectify, erase, restrict
                processing, object to processing, and data portability. Please
                contact us for any inquiries.
              </p>
            </div>

            <div ref={scrollToRef} className="mb-8">
              <h2 className="text-xl font-bold mb-2">Cookies:</h2>
              <p>
                Cookies are used to collect standard Internet log information
                and visitor behavior information. We use cookies to improve your
                website experience.
              </p>
            </div>

            <div className="mb-8">
              <h2 className="text-xl font-bold mb-2">
                Changes to Privacy Policy:
              </h2>
              <p>
                We regularly review our privacy policy and update it
                accordingly. The last update was on January 9, 2019.
              </p>
            </div>

            <div className="mb-8">
              <h2 className="text-xl font-bold mb-2">Contact Us:</h2>
              <p>
                If you have any questions about our privacy policy or your data,
                please contact us via email, phone, or mail.
              </p>
              <p>Email: data@e-solutions.ae</p>
              <p>Phone: +97145754780</p>
              <p>
                Address: Office 201 Goldcrest Executive, Cluster C, JLT, Dubai,
                UAE
              </p>
            </div>

            <div className="mb-8">
              <h2 className="text-xl font-bold mb-2">
                Contact Appropriate Authorities:
              </h2>
              <p>
                If you have complaints or concerns, you may contact the UAE
                authorities.
              </p>
            </div>

            <div className="mb-8">
              <h2 className="text-xl font-bold mb-2">
                Personal Data Protection Law:
              </h2>
              <p>
                The Personal Data Protection Law, Federal Decree Law No. 45 of
                2021, ensures the confidentiality of information and protects
                individuals' privacy in the UAE.
              </p>
            </div>

            <div className="mb-8">
              <h2 className="text-xl font-bold mb-2">Related Links:</h2>
              <p>
                Find more information on related laws and regulations concerning
                data protection and privacy in the UAE.
              </p>
            </div>

            <div className="mb-8">
              <h2 className="text-xl font-bold mb-2">
                Terms, Business Agreement:
              </h2>
              <p>
                Please download our Business Agreement Document from the
                provided link.
              </p>
            </div>

            <div className="flex md:flex-row flex-col md:space-x-5 md:justify-end md:tems-center">
              <a
                className=" md:mt-10 border p-4 cursor-pointer rounded-lg text-orange-400 hover:underline"
                onClick={() => {
                  setOpenModal(true);
                  setDocument(cppw);
                }}
              >
                More details
              </a>
              <a
                className=" md:mt-10 mt-5 border p-4 cursor-pointer rounded-lg text-orange-400 hover:underline"
                onClick={() => {
                  setOpenModal(true);
                  setDocument(tbs);
                }}
              >
                Business Agreement
              </a>
            </div>
          </div>
        </div>
      </div>
      <ModalGetData
        brochures={false}
        document={document}
        setOpenModal={setOpenModal}
        openModal={openModal}
      />
      <JoinUs />
      <ContactSection />
    </div>
  );
}

export default PrivacyPolicy;
