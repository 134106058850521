import React, { useEffect, useRef } from "react";
import { FadeIn, FadeInStagger } from "../components/utils/FadeIn";
import { motion } from "framer-motion";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useState } from "react";
import { db } from "../firebase";
import moment from "moment";
import { Spinner } from "flowbite-react";
import CarouselBlog from "../components/utils/caroussel-blog";

export function InsightContent({
  content,
  isCenter = false,
  isLeft = false,
  isRight = false,
  previousSlide,
  nextSlide,
}) {
  console.warn(content);

  return isCenter ? (
    <a
      href={`/article-detail/${content.id}`}
      className="group flex relative flex-col border  h-full w-full    overflow-hidden  rounded-lg shadow-inner  z-30 transition-all ease-in duration-200  "
    >
      {content.data.bg_url && (
        <img
          loading="eager"
          class=" object-cover w-full h-full sm:min-w-96"
          src={content.data.bg_url}
          alt="Image Description"
        />
      )}

      {!content.data.bg_url && <div className=" size-full sm:min-w-96"></div>}

      <div
        className={`absolute inset-0 bg-gradient-to-t from-indigo-900 to-transparent`}
      ></div>

      <div className="absolute flex items-end p-4 inset-0 text-gray-200">
        <div>
          <h1 className="text-xl font-bold ">{content.data.title}</h1>
          <span className=" inline-flex space-x-3 items-center">
            <h1>{moment.unix(content.data.creatAt).local().fromNow()}</h1>{" "}
          </span>
          <p className="text-sm  border-l-2  pl-6 mt-3">
            {content.data.preview_description.slice(0, 200) + "..."}
          </p>
        </div>
      </div>
    </a>
  ) : (
    <div
      onClick={() => (isRight ? nextSlide() : previousSlide())}
      className="rounded-lg p-4 border cursor-pointer bg-gradient-to-br from-indigo-900"
    >
      <div className=" inline-flex space-x-4 ">
        <div className=" w-20 h-20 rounded-lg border ">
          <img
            src={content.data.bg_url}
            className="w-full h-full aspect-auto object-cover rounded-lg"
            alt=""
            srcset=""
          />
        </div>
        <div className="text-white  lg:block hidden ">
          <h1 className="text-lg">{content.data.title}</h1>

          <p className=" text-sm">
            {content.data.preview_description.slice(0, 50) + "..."}
          </p>
        </div>
      </div>
    </div>
  );
}

function InsightSection({
  all = false,
  desiredType = "",
  title = "",
  color,
  ...props
}) {
  const [load, setLoad] = useState(true);
  const [posts, setPosts] = useState([]);

  const scrollRef = useRef(null);

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft -= 600; // Adjust the value as needed
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft += 600; // Adjust the value as needed
    }
  };

  const fetchData = async () => {
    try {
      setLoad(false);
      let q;

      q = query(collection(db, "blogs"));

      const querySnapshot = await getDocs(q);

      const postsArray = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));

      setLoad(true);
      setPosts(postsArray);
    } catch (error) {
      console.error("an error occurred while fetching", error);
    }
  };

  useEffect(() => {
    const abortController = new AbortController();

    const fetchArticles = async () => {
      try {
        setLoad(false); // Commencer le chargement
        await Promise.all([fetchData()]);
      } catch (error) {
        console.error("an error occurred", error);
        // Ici, gérer l'affichage d'un message d'erreur à l'utilisateur si nécessaire
      } finally {
        setLoad(true); // Terminer le chargement, que l'opération réussisse ou échoue
      }
    };

    if (!abortController.signal.aborted) {
      fetchArticles();
    }

    return () => {
      setPosts([]);
      abortController.abort(); // Optionnel : Annuler les requêtes en cours si elles n'ont pas encore abouti
    };
  }, []);

  console.warn(posts);

  return (
    <div {...props}>
      {!all && (
        <div>
          <div className="  text-3xl sm:text-4xl md:text-6xl  font-semibold   bg-clip-text py-2 text-transparent bg-gradient-to-r from-orange-500 to-violet-600   ">
            Latest Insights & news
          </div>
          <p className=" mt-1 sm:text-lg text-sm text-neutral-400">
            See how game-changing companies are making the most of every
            engagement with <span className="text-orange-500">eMagine</span>{" "}
            Solutions.
          </p>
        </div>
      )}

      <div
        ref={scrollRef}
        className="overflow-x-scroll hidden-scrollbar  scroll-smooth w-full py-10"
      >
        {!load && (
          <div className="h-32">
            <div className=" absolute inset-0 flex justify-center items-center">
              <Spinner aria-label=" spinner example" size="xl" />
            </div>
          </div>
        )}

        {/*         {load && (
          <FadeInStagger className="  items-center flex flex-col md:flex-row gap-4 md:inline-flex mt-4 ">
            {posts &&
              posts.map((post) => (
                <InsightContent content={post} color={color} />
              ))}
          </FadeInStagger>
        )} */}

        {load && <CarouselBlog items={posts} />}
      </div>

      {!all && (
        <div className="mt-10 inline-flex space-x-4 justify-end items-center w-full">
          <motion.a
            role="button"
            href="/insights"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            transition={{ type: "spring", stiffness: 400, damping: 17 }}
            className=" md:w-48 w-full h-14 px-10 pt-5 pb-6   bg-white-600 rounded-lg border-2 border-orange-600 justify-center items-center inline-flex"
          >
            <div className=" w-32  inline-flex  justify-center items-center  uppercase whitespace-nowrap text-center text-orange-50 text-sm font-medium   leading-tight tracking-widest">
              <span>View all</span>
            </div>
          </motion.a>
        </div>
      )}
    </div>
  );
}

export default InsightSection;
