import React, { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";

function SearchBar({ search, landing = true }) {
  const [searchParams, setSearchParams] = useState("");
  const [s, setS] = useSearchParams();

  const handleChange = (e) => {
    if (!landing) {
      search(e.target.value);
      setS({});
    } else {
      setSearchParams(e.target.value);
    }
  };

  return (
    <div className="md:px-3 py-3 z-10 ">
      <div class="max-w-[85rem]  mx-auto">
        <div className="w-full overflow-hidden h-40 relative flex md:flex-row flex-col ">
          <input
            onChange={handleChange}
            type="text"
            placeholder="Search jobs by title or location"
            className="flex-1 bg-transparent border-none text-xl md:text-4xl  caret-orange-600 focus:outline-none  ring-0  text-gray-300"
          />

          <Link
            to={`/find-a-job?search=${searchParams}`}
            className=" md:w-60 rounded-lg  h-1/2 mt-10 space-x-4 inline-flex hover:text-orange-600 hover:shadow-inner text-white bg-slate-600/30  justify-center items-center text-sm md:text-2xl "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="md:w-8 md:h-8 w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
              />
            </svg>
            <span>Search jobs</span>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default SearchBar;
