import React, { useEffect, useState } from "react";
import GoBack from "../../components/utils/goBack";
import Jobs from "../../landing/jobs";
import {
  collection,
  deleteDoc,
  doc,
  endBefore,
  getCountFromServer,
  getDoc,
  getDocs,
  limit,
  query,
  startAfter,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import { Link, useParams } from "react-router-dom";
import { Spinner } from "flowbite-react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setOpenDi } from "../../redux/utils";
import { Switch } from "@headlessui/react";
import ToogleSwitch from "../../components/utils/toogleSwitch";
import JobTemplate from "../../components/utils/job-template";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Item({ job, setLoad, fetchData }) {
  const handleDelete = async () => {
    const result = window.confirm("Do you want to continue ?");

    if (result) {
      setLoad(false);

      await deleteDoc(doc(db, "jobs", job.id)).then(async () => {
        await Promise.all([fetchData()]);
      });
    }
  };

  return (
    <JobTemplate
      job={job}
      admin
      landing={false}
      Extracomponent1={
        <button
          type="button"
          onClick={() => handleDelete()}
          className="text-red-600"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
            />
          </svg>
        </button>
      }
      Extracomponent={
        <ToogleSwitch
          setLoad={setLoad}
          state={job.data.open}
          id={job.id}
          f={fetchData}
        />
      }
    />
  );
}

function OneClient() {
  const [jobs, setJobs] = useState([]);
  const [client, setClient] = useState({});
  const [load, setLoad] = useState(true);
  const [count, setCount] = useState(0);
  const [lastVisible, setLastVisible] = useState("");
  const [firstVisible, setFirstVisible] = useState("");
  const { urlClient } = useSelector((state) => state.utilsSlice);

  const dispatch = useDispatch();

  const { id } = useParams();

  const updateLogo = async () => {
    const washingtonRef = doc(db, "clients", id);

    setLoad(false);

    await updateDoc(washingtonRef, {
      logo: urlClient,
    }).then(() => {
      window.location.reload();
    });
  };

  const fetchCount = async () => {
    try {
      setLoad(false);
      const coll = collection(db, "jobs");
      const q = query(coll, where("organization", "==", id));
      const snapshot = await getCountFromServer(q);
      const count = snapshot.data().count;

      setLoad(true);
      setCount(count);
    } catch (error) {
      console.error("Error fetching", error);
    }
  };

  const fetchClient = async () => {
    try {
      setLoad(false);
      const docRef = doc(db, "clients", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        setLoad(true);
        setClient(data);
      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }
    } catch (error) {
      console.error("an error occurred while fetching", error);
    }
  };

  const fetchData = async () => {
    try {
      setLoad(false);
      const q = query(
        collection(db, "jobs"),
        where("organization", "==", id),
        limit(10)
      );
      const querySnapshot = await getDocs(q);

      const last = querySnapshot.docs[querySnapshot.docs.length - 1];
      const first = querySnapshot.docs[0];
      setLastVisible(last);
      setFirstVisible(first);

      const jobsArray = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));

      setLoad(true);
      setJobs(jobsArray);
    } catch (error) {
      console.error("an error occurred while fetching", error);
    }
  };

  async function fetchJobs(direction = "next") {
    setLoad(false);
    let newQuery;
    if (direction === "next") {
      newQuery = query(
        collection(db, "jobs"),
        where("organization", "==", id),
        startAfter(lastVisible),
        limit(10)
      );
    } else {
      // prev
      newQuery = query(
        collection(db, "jobs"),
        where("organization", "==", id),
        endBefore(firstVisible),
        limit(10)
      );
    }

    try {
      const querySnapshot = await getDocs(newQuery);
      if (!querySnapshot.empty) {
        const last = querySnapshot.docs[querySnapshot.docs.length - 1];
        const first = querySnapshot.docs[0];
        setLastVisible(last);
        setFirstVisible(first);

        const jobsArray = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }));
        setJobs(jobsArray);
      }
    } catch (error) {
      console.error("an error occurred while fetching jobs", error);
    } finally {
      setLoad(true);
    }
  }

  useEffect(() => {
    const abortController = new AbortController();

    const fetchClientAndJobs = async () => {
      try {
        setLoad(false); // Commencer le chargement
        await Promise.all([fetchClient(), fetchData(), fetchCount()]);
      } catch (error) {
        console.error("an error occurred", error);
        // Ici, gérer l'affichage d'un message d'erreur à l'utilisateur si nécessaire
      } finally {
        setLoad(true); // Terminer le chargement, que l'opération réussisse ou échoue
      }
    };

    if (!abortController.signal.aborted) {
      fetchClientAndJobs();
    }

    return () => {
      setClient({});
      setJobs([]);
      abortController.abort(); // Optionnel : Annuler les requêtes en cours si elles n'ont pas encore abouti
    };
  }, [id]);

  return (
    <div className="bg-white relative min-h-[calc(100vh-4rem)]">
      {!load && (
        <div className=" absolute inset-0 flex justify-center items-center">
          <Spinner aria-label="Extra large spinner example" size="xl" />
        </div>
      )}
      {load && (
        <div class="flex flex-col p-4">
          <span className="inline-flex space-x-4">
            <GoBack />
            <p>Back</p>
          </span>

          <div className=" my-5 ">
            <div class="inline-flex justify-end w-full gap-x-2">
              <button
                onClick={() => fetchJobs("prev")}
                type="button"
                class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
              >
                <svg
                  class="flex-shrink-0 size-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="m15 18-6-6 6-6" />
                </svg>
                Prev
              </button>

              <button
                onClick={() => fetchJobs()}
                type="button"
                class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
              >
                Next
                <svg
                  class="flex-shrink-0 size-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="m9 18 6-6-6-6" />
                </svg>
              </button>
            </div>
            <div class="inline-flex justify-end w-full gap-x-2 mt-5">
              <Link
                to={"/create-live-position"}
                class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg   bg-indigo-600 text-white shadow-sm hover:bg-indigo-700 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
              >
                Post New Position
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </Link>
            </div>
          </div>
          <div className=" grid md:grid-cols-12 gap-4">
            <div className=" md:col-span-4 p-4">
              <div className=" bg-white grid grid-cols-3 border rounded-lg p-4">
                <div className=" col-span-1 ">
                  <div className="flex  flex-col gap-4">
                    {!urlClient && !client.logo && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z"
                        />
                      </svg>
                    )}

                    {urlClient && (
                      <img
                        className="h-full w-full rounded object-cover"
                        src={urlClient}
                        alt=""
                      />
                    )}
                    {!urlClient && client.logo && (
                      <img
                        className="h-full w-full rounded object-cover"
                        src={client.logo}
                        alt=""
                      />
                    )}
                  </div>
                </div>

                <div className=" col-span-2">
                  <div className="p-4">
                    <h1 className="text-lg font-bold capitalize">
                      {client.name}
                    </h1>
                    <h1 className="text-sm text-gray-600 font-semibold">
                      {client.owner}
                    </h1>
                    <h1 className="text-sm text-gray-600 font-semibold">
                      {client.phone}
                    </h1>
                    <h1 className="text-sm text-gray-600 font-semibold">
                      {client.location}
                    </h1>
                    <h1 className="text-sm text-gray-600 font-semibold">
                      Job(s) : {count}
                    </h1>
                    <h1 className="text-sm text-gray-600 font-semibold">
                      {client.email}
                    </h1>
                  </div>
                </div>
              </div>
            </div>

            <div className=" md:col-span-8 md:grid-cols-2 grid gap-4 p-4">
              {jobs &&
                jobs.map((job) => (
                  <Item job={job} fetchData={fetchData} setLoad={setLoad} />
                ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default OneClient;
