import React, { useEffect } from "react";

import { AiOutlineUnorderedList, AiOutlineOrderedList } from "react-icons/ai";
import Editor from "@monaco-editor/react";

import useExpertiseEditor from "../../hook/useEditor";
import { Spinner, Toast } from "flowbite-react";
import GoBack from "../../components/utils/goBack";

const JobEditor = ({ getHtml, getEditorRef, hidden = true }) => {
  const {
    editorRef,
    textCol,
    textColor,
    contentHtml,
    load,
    showToast,
    jsxCode,
    showCodeInput,
    previewContent,
    tableSize,
    setTextColor,
    setLoad,
    setShowToast,
    setJsxCode,
    setShowCodeInput,
    setPreviewContent,
    setPositionSize,
    handlePublish,
    addLink,
    applyStyle,
    createList,
    setTextAlignment,
    handleColorChange,
    handleJsxPreview,
    handleJsxSubmit,
    handleEditorChange,
    dispatch,
    setOpenDi,
    handleContentChange,
    handleInsertTable,
    rows,
    setRows,
    columns,
    setColumns,
    setShowTextStyleDropdown,
    showTextStyleDropdown,
    showTextStyleDropdownToolTip,
    setShowTextStyleDropdownToolTip,
    applyTextStyle,
    setTemplate,
  } = useExpertiseEditor();

  useEffect(() => {
    if (contentHtml) {
      getHtml(contentHtml);
    }
  }, [contentHtml]);

  useEffect(() => {
    getEditorRef(editorRef);
  }, [editorRef]);

  return (
    <div className={` ${hidden ? "hidden" : ""} relative `}>
      {showToast && (
        <Toast className="fixed bottom-4  z-[10000000] right-2 bg-green-50 ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="w-6 h-6 text-green-500 -rotate-45 "
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
            />
          </svg>
          <div className="pl-4 text-sm font-normal">Published with success</div>
        </Toast>
      )}

      <div className="editor p-4 min-h-[calc(100vh-4rem)] bg-slate-50 md:grid grid-cols-12 rounded-lg ">
        {/* Menu Tooltip */}
        <div
          id="tooltip"
          className="hidden fixed  p-2 z-50 mt-10 bg-gray-200 shadow rounded"
        >
          <div className="relative">
            <button
              type="button"
              className="bg-slate-800/90 rounded-md text-gray-200 text-sm p-2 flex justify-center items-center"
              onClick={() =>
                setShowTextStyleDropdownToolTip(!showTextStyleDropdownToolTip)
              }
            >
              <span>Text Style</span>
            </button>
            {showTextStyleDropdownToolTip && (
              <div className="absolute mt-2 bg-white border border-gray-300 rounded shadow-lg z-10">
                <button
                  type="button"
                  className="block px-4 py-2 text-sm text-gray-700"
                  onClick={() => applyTextStyle("title")}
                >
                  Title
                </button>
                <button
                  type="button"
                  className="block px-4 py-2 text-sm text-gray-700"
                  onClick={() => applyTextStyle("subtitle")}
                >
                  Subtitle
                </button>
                <button
                  type="button"
                  className="block px-4 py-2 text-sm text-gray-700"
                  onClick={() => applyTextStyle("body")}
                >
                  Body
                </button>
              </div>
            )}
          </div>

          <button
            type="button"
            onClick={() => applyStyle("bold")}
            className="mr-2"
          >
            B
          </button>
          <button
            type="button"
            onClick={() => applyStyle("italic")}
            className="mr-2"
          >
            I
          </button>
          <button
            type="button"
            onClick={() => document.execCommand("underline")}
            className="mr-2"
          >
            U
          </button>
          <button type="button" onClick={() => applyStyle("normal")}>
            Normal
          </button>
        </div>
        <div className="toolbar md:col-span-2 md:flex hidden flex-col items-start gap-2 mb-4">
          <div className="">
            <h1 className="text-sm text-gray-400 ">Undo/Redo </h1>
            <div className="flex flex-wrap justify-center items-center">
              <button
                type="button"
                onClick={() => document.execCommand("undo")}
                className=" text-gray-600 flex justify-center items-center  p-4"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m15 15-6 6m0 0-6-6m6 6V9a6 6 0 0 1 12 0v3"
                  />
                </svg>
              </button>
              <button
                type="button"
                onClick={() => document.execCommand("redo")}
                className=" text-gray-600 flex justify-center items-center  p-4"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m9 9 6-6m0 0 6 6m-6-6v12a6 6 0 0 1-12 0v-3"
                  />
                </svg>
              </button>
            </div>
          </div>

          <hr className="w-full" />

          <div className="">
            <h1 className="text-sm text-gray-400 my-4 ">Text alignment</h1>
            <div className="flex gap-1 flex-wrap">
              <button
                type="button"
                onClick={() => setTextAlignment("left")}
                className="bg-slate-800/90 rounded-md text-gray-200 flex justify-center items-center  p-4"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12"
                  />
                </svg>
              </button>
              <button
                type="button"
                onClick={() => setTextAlignment("center")}
                className="bg-slate-800/90 rounded-md text-gray-200 flex justify-center items-center  p-4"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12H12m-8.25 5.25h16.5"
                  />
                </svg>
              </button>
              <button
                type="button"
                onClick={() => setTextAlignment("right")}
                className="bg-slate-800/90 rounded-md text-gray-200 flex justify-center items-center  p-4"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25"
                  />
                </svg>
              </button>
              <button
                type="button"
                onClick={() => setTextAlignment("justify")}
                className="bg-slate-800/90 rounded-md text-gray-200 flex justify-center items-center  p-4"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>
              </button>
            </div>
          </div>

          <hr className="w-full" />

          <div className="">
            <h1 className="text-sm text-gray-400 my-4 ">Text size</h1>
            <div className="flex gap-1">
              <div className="relative">
                <button
                  type="button"
                  className="bg-slate-800/90 rounded-md text-gray-200 text-sm p-2 flex justify-center items-center"
                  onClick={() =>
                    setShowTextStyleDropdown(!showTextStyleDropdown)
                  }
                >
                  <span>Text Style</span>
                </button>
                {showTextStyleDropdown && (
                  <div className="absolute mt-2 bg-white border border-gray-300 rounded shadow-lg z-10">
                    <button
                      type="button"
                      className="block px-4 py-2 text-sm text-gray-700"
                      onClick={() => applyTextStyle("title")}
                    >
                      Title
                    </button>
                    <button
                      type="button"
                      className="block px-4 py-2 text-sm text-gray-700"
                      onClick={() => applyTextStyle("subtitle")}
                    >
                      Subtitle
                    </button>
                    <button
                      type="button"
                      className="block px-4 py-2 text-sm text-gray-700"
                      onClick={() => applyTextStyle("body")}
                    >
                      Body
                    </button>
                  </div>
                )}
              </div>

              <div className="flex flex-col justify-center items-center">
                <button
                  type="button"
                  onClick={() => textCol.current.click()}
                  style={{ color: textColor }}
                  className="text-sm flex font-bold justify-center items-center"
                >
                  A
                </button>
                <input
                  type="color"
                  ref={textCol}
                  value={textColor}
                  onChange={handleColorChange}
                  className="h-4 border-none ring-0 outline-none ring-offset-0"
                />
              </div>
            </div>
          </div>

          <hr className="w-full" />

          <div className="">
            <h1 className="text-sm text-gray-400 my-4 ">Attachement</h1>
            <div className=" flex flex-wrap ">
              <button
                type="button"
                onClick={addLink}
                className=" text-gray-600 flex justify-center items-center  p-4"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244"
                  />
                </svg>
              </button>

              <button
                type="button"
                onClick={() => dispatch(setOpenDi("ed"))}
                className="text-gray-600 flex justify-center items-center  p-4"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                  />
                </svg>
              </button>
            </div>
          </div>

          <hr className="w-full" />

          <div>
            <h1 className="text-sm text-gray-400 my-4 ">Quote</h1>
            <button
              type="button"
              onClick={() => {
                const selection = window.getSelection();
                if (!selection.rangeCount) return;

                const blockquote = document.createElement("blockquote");
                blockquote.className =
                  "p-4 my-4 border-l-4 border-gray-300 bg-gray-50 dark:border-gray-500 dark:bg-gray-800";
                selection.getRangeAt(0).surroundContents(blockquote);
                handleContentChange();
              }}
              className="bg-slate-800/90 rounded-md text-gray-200 flex justify-center items-center  p-4"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M5.25 8.25h15m-16.5 7.5h15m-1.8-13.5-3.9 19.5m-2.1-19.5-3.9 19.5"
                />
              </svg>
            </button>
          </div>

          <hr className="w-full" />

          <div>
            <h1 className="text-sm text-gray-400 my-4 ">Font style</h1>
            <div className="flex gap-2 flex-wrap">
              <button
                type="button"
                onClick={() => applyStyle("bold")}
                className="bg-slate-800/90 rounded-md flex justify-center items-center  p-4 font-bold text-gray-200"
              >
                <span>B</span>
              </button>
              <button
                type="button"
                onClick={() => applyStyle("normal")}
                className="bg-slate-800/90 rounded-md  text-gray-200 flex justify-center items-center  p-4"
              >
                B
              </button>

              <button
                type="button"
                onClick={() => applyStyle("italic")}
                className="bg-slate-800/90 rounded-md italic text-gray-200 flex justify-center items-center  p-4"
              >
                i
              </button>
              <button
                type="button"
                onClick={() => applyStyle("not-italic")}
                className="bg-slate-800/90 rounded-md not-italic text-gray-200 flex justify-center items-center  p-4"
              >
                i
              </button>
              <button
                type="button"
                onClick={() => document.execCommand("underline")}
                className="bg-slate-800/90 rounded-md underline text-gray-200 flex justify-center items-center  p-4"
              >
                U
              </button>
            </div>
          </div>

          <hr className="w-full" />

          <div>
            <h1 className="text-sm text-gray-400 my-4 ">List</h1>

            <div className="flex gap-4 flex-wrap">
              <button
                type="button"
                onClick={() => createList(false)}
                className="bg-slate-800/90 rounded-md text-gray-200 text-2xl leading-none flex justify-center items-center p-4 "
              >
                <AiOutlineUnorderedList className="w-6 h-6" />
              </button>
              <button
                type="button"
                onClick={() => createList(true)}
                className="bg-slate-800/90 rounded-md text-gray-200 text-lg flex leading-none justify-center items-center p-4 "
              >
                <AiOutlineOrderedList className="w-6 h-6" />
              </button>
            </div>
          </div>
        </div>

        <div className="col-span-10 ">
          <div className="  relative ">
            <div className="md:hidden flex justify-end my-3"></div>

            <div className="toolbar md:hidden bg-slate-100 p-2 mt-4 z-[19900000000] w-full 5 rounded-lg toolbar-mobile  flex  flex-row  overflow-x-scroll gap-2 ">
              <div className="">
                <div className="flex  justify-center flex-nowrap items-center">
                  <button
                    type="button"
                    onClick={() => document.execCommand("undo")}
                    className=" text-gray-600 flex justify-center items-center  p-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={2}
                      stroke="currentColor"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m15 15-6 6m0 0-6-6m6 6V9a6 6 0 0 1 12 0v3"
                      />
                    </svg>
                  </button>
                  <button
                    type="button"
                    onClick={() => document.execCommand("redo")}
                    className=" text-gray-600 flex justify-center items-center  p-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={2}
                      stroke="currentColor"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m9 9 6-6m0 0 6 6m-6-6v12a6 6 0 0 1-12 0v-3"
                      />
                    </svg>
                  </button>
                </div>
              </div>

              <hr className="w-full" />

              <div className="">
                <div className="flex gap-1 flex-nowrap">
                  <button
                    type="button"
                    onClick={() => setTextAlignment("left")}
                    className="bg-slate-800/90 rounded-md text-gray-200 flex justify-center items-center  p-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={2}
                      stroke="currentColor"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12"
                      />
                    </svg>
                  </button>
                  <button
                    type="button"
                    onClick={() => setTextAlignment("center")}
                    className="bg-slate-800/90 rounded-md text-gray-200 flex justify-center items-center  p-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 24 24"
                      strokeWidth={2}
                      stroke="currentColor"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3.75 6.75h16.5M3.75 12H12m-8.25 5.25h16.5"
                      />
                    </svg>
                  </button>
                  <button
                    type="button"
                    onClick={() => setTextAlignment("right")}
                    className="bg-slate-800/90 rounded-md text-gray-200 flex justify-center items-center  p-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 24 24"
                      strokeWidth={2}
                      stroke="currentColor"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25"
                      />
                    </svg>
                  </button>
                  <button
                    type="button"
                    onClick={() => setTextAlignment("justify")}
                    className="bg-slate-800/90 rounded-md text-gray-200 flex justify-center items-center  p-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 24 24"
                      strokeWidth={2}
                      stroke="currentColor"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                      />
                    </svg>
                  </button>
                </div>
              </div>

              <hr className="w-full" />

              <div className="">
                <div className="flex gap-1">
                  <div className="">
                    <button
                      type="button"
                      className="bg-slate-800/90 whitespace-nowrap rounded-md text-gray-200 text-sm p-2 flex justify-center items-center"
                      onClick={() =>
                        setShowTextStyleDropdown(!showTextStyleDropdown)
                      }
                    >
                      <span>Text Style</span>
                    </button>
                    {showTextStyleDropdown && (
                      <div className="absolute mt-2 bg-white border border-gray-300 rounded shadow-lg z-10">
                        <button
                          type="button"
                          className="block px-4 py-2 text-sm text-gray-700"
                          onClick={() => applyTextStyle("title")}
                        >
                          Title
                        </button>
                        <button
                          type="button"
                          className="block px-4 py-2 text-sm text-gray-700"
                          onClick={() => applyTextStyle("subtitle")}
                        >
                          Subtitle
                        </button>
                        <button
                          type="button"
                          className="block px-4 py-2 text-sm text-gray-700"
                          onClick={() => applyTextStyle("body")}
                        >
                          Body
                        </button>
                      </div>
                    )}
                  </div>

                  <div className="flex flex-col justify-center items-center">
                    <button
                      type="button"
                      onClick={() => textCol.current.click()}
                      style={{ color: textColor }}
                      className="text-sm flex font-bold justify-center items-center"
                    >
                      A
                    </button>
                    <input
                      type="color"
                      ref={textCol}
                      value={textColor}
                      onChange={handleColorChange}
                      className="h-4 border-none ring-0 outline-none ring-offset-0"
                    />
                  </div>
                </div>
              </div>

              <hr className="w-full" />

              <div className="">
                <div className=" flex flex-nowrap ">
                  <button
                    type="button"
                    onClick={addLink}
                    className=" text-gray-600 flex justify-center items-center  p-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 24 24"
                      strokeWidth={2}
                      stroke="currentColor"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244"
                      />
                    </svg>
                  </button>

                  <button
                    type="button"
                    onClick={() => dispatch(setOpenDi("ed"))}
                    className="text-gray-600 flex justify-center items-center  p-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 24 24"
                      strokeWidth={2}
                      stroke="currentColor"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                      />
                    </svg>
                  </button>
                </div>
              </div>

              <hr className="w-full" />

              <div>
                <button
                  type="button"
                  onClick={() => {
                    const selection = window.getSelection();
                    if (!selection.rangeCount) return;

                    const blockquote = document.createElement("blockquote");
                    blockquote.className =
                      "p-4 my-4 border-l-4 border-gray-300 bg-gray-50 dark:border-gray-500 dark:bg-gray-800";
                    selection.getRangeAt(0).surroundContents(blockquote);
                    handleContentChange();
                  }}
                  className="bg-slate-800/90   rounded-md text-gray-200 flex justify-center items-center  p-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    className="w-4 h-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M5.25 8.25h15m-16.5 7.5h15m-1.8-13.5-3.9 19.5m-2.1-19.5-3.9 19.5"
                    />
                  </svg>
                </button>
              </div>

              <hr className="w-full" />

              <div>
                <div className="flex gap-2 flex-nowrap">
                  <button
                    type="button"
                    onClick={() => applyStyle("bold")}
                    className="bg-slate-800/90 rounded-md text-sm flex justify-center items-center  p-2 font-bold text-gray-200"
                  >
                    <span>B</span>
                  </button>
                  <button
                    type="button"
                    onClick={() => applyStyle("normal")}
                    className="bg-slate-800/90 rounded-md text-sm  text-gray-200 flex justify-center items-center  p-2"
                  >
                    B
                  </button>

                  <button
                    type="button"
                    onClick={() => applyStyle("italic")}
                    className="bg-slate-800/90 rounded-md text-sm italic text-gray-200 flex justify-center items-center  p-2"
                  >
                    i
                  </button>
                  <button
                    type="button"
                    onClick={() => applyStyle("not-italic")}
                    className="bg-slate-800/90 rounded-md text-sm not-italic text-gray-200 flex justify-center items-center  p-2"
                  >
                    i
                  </button>
                  <button
                    type="button"
                    onClick={() => document.execCommand("underline")}
                    className="bg-slate-800/90 rounded-md text-sm underline text-gray-200 flex justify-center items-center  p-2"
                  >
                    U
                  </button>
                </div>
              </div>

              <hr className="w-full" />

              <div>
                <div className="flex gap-4 flex-nowrap">
                  <button
                    type="button"
                    onClick={() => createList(false)}
                    className="bg-slate-800/90 rounded-md text-gray-200 text-2xl leading-none flex justify-center items-center p-2 "
                  >
                    <AiOutlineUnorderedList className="w-4 h-4" />
                  </button>
                  <button
                    type="button"
                    onClick={() => createList(true)}
                    className="bg-slate-800/90 rounded-md text-gray-200 text-lg flex leading-none justify-center items-center p-2 "
                  >
                    <AiOutlineOrderedList className="w-4 h-4" />
                  </button>
                </div>
              </div>
            </div>

            <div className="relative mt-3 mx-2">
              <div className=" flex   ">
                {/*      <button
                type="button"
                  className="bg-green-500 text-white px-4 py-2 rounded"
                  onClick={() => setShowCodeInput(!showCodeInput)}
                >
                  Insert JSX
                </button>
                {showCodeInput && (
                  <div className="absolute bg-white shadow-lg mt-2 rounded p-4 w-[40%] z-10">
                    <Editor
                      height="200px"
                      defaultLanguage="html"
                      defaultValue="// Enter JSX code"
                      value={jsxCode}
                      onChange={handleEditorChange}
                      theme="vs-dark"
                    />
                    <div className="flex space-x-2 mt-2">
                      <button
                      type="button"
                        onClick={handleJsxPreview}
                        className="bg-gray-500 text-white px-4 py-2 rounded w-full"
                      >
                        Preview
                      </button>
                      <button
                      type="button"
                        onClick={handleJsxSubmit}
                        className="bg-blue-500 text-white px-4 py-2 rounded w-full"
                      >
                        Submit
                      </button>
                    </div>
                    {previewContent && (
                      <div className="mt-2 p-2 border rounded bg-gray-50">
                        <h4 className="text-lg font-bold">Preview:</h4>
                        <div
                          dangerouslySetInnerHTML={{ __html: previewContent }}
                        ></div>
                      </div>
                    )}
                  </div>
                )} */}
                <div className="flex flex-col gap">
                  <span className=" text-gray-400">Grid</span>
                  <div className="flex  items-center ">
                    <div className="relative ">
                      <span className="absolute pl-4 top-1/2 -translate-y-1/2 transform">
                        <svg
                          viewBox="0 -21 512 512"
                          className=" size-6"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="m16 384c-1.984375 0-3.96875-.363281-5.867188-1.109375-6.121093-2.410156-10.132812-8.320313-10.132812-14.890625v-160c0-6.570312 4.011719-12.480469 10.132812-14.890625 6.144532-2.410156 13.078126-.851563 17.558594 3.96875l74.664063 80c5.761719 6.144531 5.761719 15.679687 0 21.824219l-74.664063 80c-3.09375 3.328125-7.339844 5.097656-11.691406 5.097656zm16-135.402344v78.804688l36.777344-39.402344zm0 0" />
                          <path d="m474.667969 469.332031h-330.667969c-20.585938 0-37.332031-16.746093-37.332031-37.332031v-53.332031c0-20.589844 16.746093-37.335938 37.332031-37.335938h330.667969c20.585937 0 37.332031 16.746094 37.332031 37.335938v53.332031c0 20.585938-16.746094 37.332031-37.332031 37.332031zm-330.667969-96c-2.945312 0-5.332031 2.390625-5.332031 5.335938v53.332031c0 2.945312 2.386719 5.332031 5.332031 5.332031h330.667969c2.941406 0 5.332031-2.386719 5.332031-5.332031v-53.332031c0-2.945313-2.390625-5.335938-5.332031-5.335938zm0 0" />
                          <path d="m474.667969 234.667969h-330.667969c-20.585938 0-37.332031-16.746094-37.332031-37.335938v-160c0-20.585937 16.746093-37.332031 37.332031-37.332031h330.667969c20.585937 0 37.332031 16.746094 37.332031 37.332031v160c0 20.589844-16.746094 37.335938-37.332031 37.335938zm-330.667969-202.667969c-2.945312 0-5.332031 2.390625-5.332031 5.332031v160c0 2.945313 2.386719 5.335938 5.332031 5.335938h330.667969c2.941406 0 5.332031-2.390625 5.332031-5.335938v-160c0-2.941406-2.390625-5.332031-5.332031-5.332031zm0 0" />
                          <path d="m309.332031 469.332031c-8.832031 0-16-7.167969-16-16v-96c0-8.832031 7.167969-16 16-16s16 7.167969 16 16v96c0 8.832031-7.167969 16-16 16zm0 0" />
                          <path d="m309.332031 234.667969c-8.832031 0-16-7.167969-16-16v-202.667969c0-8.832031 7.167969-16 16-16s16 7.167969 16 16v202.667969c0 8.832031-7.167969 16-16 16zm0 0" />
                          <path d="m496 133.332031h-373.332031c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h373.332031c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0" />
                        </svg>
                      </span>
                      <input
                        type="number"
                        value={rows}
                        onChange={(e) => setRows(e.target.value)}
                        className="w-32 p-1 pl-20 border border-s border-e-0 rounded-s"
                        min={1}
                        placeholder="Rows"
                      />
                    </div>

                    <div className=" relative ">
                      <span className="absolute pl-4 top-1/2 -translate-y-1/2 transform">
                        <svg
                          viewBox="-21 0 512 512"
                          className=" size-6"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="m181.332031 106.667969c-3.925781 0-7.828125-1.429688-10.921875-4.3125l-80-74.664063c-4.820312-4.480468-6.378906-11.457031-3.96875-17.558594 2.410156-6.121093 8.320313-10.132812 14.890625-10.132812h160c6.570313 0 12.480469 4.011719 14.871094 10.132812 2.410156 6.125.851563 13.078126-3.96875 17.558594l-80 74.664063c-3.070313 2.882812-6.976563 4.3125-10.902344 4.3125zm-39.421875-74.667969 39.421875 36.777344 39.425781-36.777344zm0 0" />
                          <path d="m90.667969 512h-53.335938c-20.585937 0-37.332031-16.746094-37.332031-37.332031v-330.667969c0-20.585938 16.746094-37.332031 37.332031-37.332031h53.335938c20.585937 0 37.332031 16.746093 37.332031 37.332031v330.667969c0 20.585937-16.746094 37.332031-37.332031 37.332031zm-53.335938-373.332031c-2.941406 0-5.332031 2.386719-5.332031 5.332031v330.667969c0 2.941406 2.390625 5.332031 5.332031 5.332031h53.335938c2.941406 0 5.332031-2.390625 5.332031-5.332031v-330.667969c0-2.945312-2.390625-5.332031-5.332031-5.332031zm0 0" />
                          <path d="m432 512h-160c-20.585938 0-37.332031-16.746094-37.332031-37.332031v-330.667969c0-20.585938 16.746093-37.332031 37.332031-37.332031h160c20.585938 0 37.332031 16.746093 37.332031 37.332031v330.667969c0 20.585937-16.746093 37.332031-37.332031 37.332031zm-160-373.332031c-2.945312 0-5.332031 2.386719-5.332031 5.332031v330.667969c0 2.941406 2.386719 5.332031 5.332031 5.332031h160c2.945312 0 5.332031-2.390625 5.332031-5.332031v-330.667969c0-2.945312-2.386719-5.332031-5.332031-5.332031zm0 0" />
                          <path d="m112 325.332031h-96c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h96c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0" />
                          <path d="m453.332031 325.332031h-202.664062c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h202.664062c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0" />
                          <path d="m352 512c-8.832031 0-16-7.167969-16-16v-373.332031c0-8.832031 7.167969-16 16-16s16 7.167969 16 16v373.332031c0 8.832031-7.167969 16-16 16zm0 0" />
                        </svg>
                      </span>
                      <input
                        type="number"
                        value={columns}
                        min={1}
                        max={6}
                        onChange={(e) => setColumns(e.target.value)}
                        className="w-32 p-1 border pl-20  border-e-0"
                        placeholder="Cols"
                      />
                    </div>
                    <div className=" relative ">
                      <select
                        id="template"
                        name="template"
                        onChange={(e) => setTemplate(e.target.value)}
                        className=" block w-full  border-x-0  p-1 border  pl-3 pr-10 text-gray-900 ring-1 ring-inset  focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        defaultValue="template1"
                      >
                        <option value={"template1"}>template1</option>
                        <option value={"template2"}>template2</option>
                      </select>
                    </div>
                    <button
                      type="button"
                      onClick={handleInsertTable}
                      className=" bg-indigo-600  text-white rounded-e p-1 border border-gray-950"
                    >
                      Insert Table
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              ref={editorRef}
              onInput={() => {
                const editor = editorRef.current;

                Array.from(editor.childNodes).forEach((child) => {
                  if (child.nodeType === Node.TEXT_NODE) {
                    const h1 = document.createElement("h1");
                    h1.classList.add("text-white");
                    editor.insertBefore(h1, child);
                    h1.appendChild(child);
                  } else if (child.nodeType === Node.ELEMENT_NODE) {
                    Array.from(child.childNodes).forEach((grandChild) => {
                      if (
                        grandChild.nodeType === Node.TEXT_NODE &&
                        grandChild.textContent.trim().length > 0 &&
                        grandChild.parentNode.nodeName !== "H1"
                      ) {
                        const h1 = document.createElement("h1");
                        h1.classList.add("text-white");
                        child.insertBefore(h1, grandChild);
                        h1.appendChild(grandChild);
                      } else if (grandChild.nodeName === "H1") {
                        grandChild.classList.add("text-white");
                      }
                    });
                  } else if (child.nodeName === "H1") {
                    child.classList.add("text-white");
                  }
                });
              }}
              onPaste={(event) => {
                event.preventDefault();
                const html =
                  event.clipboardData.getData("text/html") ||
                  event.clipboardData.getData("text/plain");

                const cleanHtml = (() => {
                  const parser = new DOMParser();
                  const doc = parser.parseFromString(html, "text/html");
                  const textTags = [
                    "p",
                    "h1",
                    "h2",
                    "h3",
                    "h4",
                    "h5",
                    "h6",
                    "li",
                    "span",
                    "blockquote",
                  ];

                  Array.from(doc.body.querySelectorAll("*")).forEach((el) => {
                    el.style.backgroundColor = "";
                    el.style.fontSize = "";
                    el.style.fontFamily = "";
                    el.style.color = "";

                    if (textTags.includes(el.nodeName.toLowerCase())) {
                      el.classList.add(
                        "md:text-base",
                        "text-sm",
                        "font-medium",
                        "text-white"
                      );
                    }
                  });

                  return doc.body.innerHTML;
                })();

                document.execCommand("insertHTML", false, cleanHtml);
              }}
              contentEditable
              className="shadow mt-4 caret-gray-300   rounded-md border border-indigo-800 bg-neutral-900 leading-6 md:px-8 px-4 py-4 w-full "
              style={{ minHeight: "150px" }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobEditor;
