import React, { useState } from "react";
import { InsightContent } from "../../landing/insight-section";

const CarouselBlog = ({ items }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [direction, setDirection] = useState("");

  const previousSlide = () => {
    if (isAnimating) return;
    setIsAnimating(true);
    setDirection("left");
    setTimeout(() => {
      setCurrentIndex((currentIndex - 1 + items.length) % items.length);
      setIsAnimating(false);
    }, 500); // Durée de l'animation
  };

  const nextSlide = () => {
    if (isAnimating) return;
    setIsAnimating(true);
    setDirection("right");
    setTimeout(() => {
      setCurrentIndex((currentIndex + 1) % items.length);
      setIsAnimating(false);
    }, 500); // Durée de l'animation
  };

  const getItemIndex = (position) => {
    return (currentIndex + position + items.length) % items.length;
  };
  return (
    <div className="relative w-full flex-col flex justify-center items-center">
      <div className="flex space-x-4 overflow-hidden">
        {[-1, 0, 1].map((position) => {
          const itemIndex = getItemIndex(position);

          const item = items[itemIndex];
          const isCenter = position === 0;
          const isLeft = position === -1;
          const isRight = position === 1;

          console.log(item);
          return (
            <div
              key={itemIndex}
              className={`
              transition-transform duration-500 ease-in-out h-[30rem]
              
            ${
              isAnimating && direction === "left" ? "animate-roulette-left" : ""
            } ${
                isAnimating && direction === "right"
                  ? "animate-roulette-right"
                  : ""
              }

              ${isCenter ? "" : "transform scale-80 "}  ${
                !isCenter && " translate-y-1/2"
              } ${!isCenter ? "md:block hidden" : ""}  ${
                isCenter && "md:w-4/6"
              }  `}
              style={{ left: `${(position + 1) * 33.33}%` }}
            >
              {item && (
                <InsightContent
                  isCenter={isCenter}
                  isLeft={isLeft}
                  isRight={isRight}
                  content={item}
                  previousSlide={previousSlide}
                  nextSlide={nextSlide}
                />
              )}
            </div>
          );
        })}
      </div>

      <div className=" inline-flex rounded-lg mt-4 ">
        <button
          className="p-2 z-30 text-gray-300 w-20 rounded-s-lg text-lg bg-gradient-to-tl from-orange-700/70 hover:text-gray-900  hover:bg-gray-300 transition-colors duration-300"
          onClick={previousSlide}
        >
          ‹
        </button>
        <button
          className=" z-30 -right-4 p-2 w-20 text-lg rounded-e-lg text-gray-300 bg-gradient-to-tr from-orange-700/70 hover:text-gray-900  hover:bg-gray-300 transition-colors duration-300"
          onClick={nextSlide}
        >
          ›
        </button>
      </div>
    </div>
  );
};

export default CarouselBlog;
