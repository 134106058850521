import React, { useEffect, useState } from "react";
import { FadeIn } from "./FadeIn";
import { Border } from "./Border";
import { ChevronRightIcon, EyeIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import moment from "moment";

const SpanClient = ({ organization }) => {
  const [client, setClient] = useState({});

  useEffect(() => {
    (async () => {
      try {
        const docRef = doc(db, "clients", organization);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setClient(data);
        } else {
          // docSnap.data() will be undefined in this case
          console.log("No such document!");
        }
      } catch (error) {
        console.log(error.message);
      }
    })();
  }, []);

  return <span className="uppercase">{client.name}</span>;
};

function JobTemplate({
  job,
  landing = true,
  admin = false,
  Extracomponent1 = () => <></>,
  Extracomponent = () => <></>,
}) {
  const [client, setClient] = useState({});

  useEffect(() => {
    (async () => {
      try {
        const docRef = doc(db, "clients", job.data.organization);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setClient(data);
        } else {
          // docSnap.data() will be undefined in this case
          console.log("No such document!");
        }
      } catch (error) {
        console.log(error.message);
      }
    })();
  }, []);

  return (
    <div
      className={` size-full  shadow-lg rounded-lg p-4 relative  border border-gray-300/25 ${
        !landing && "bg-black"
      }  ${admin && "bg-white"} border-l-[0.4rem] `}
    >
      <div className="absolute right-3 border border-t-0 rounded-b-md top-0 w-fit border-gray-300/25  py-1 px-2 ">
        <span
          className={`text-xs font-medium ${
            admin ? "text-gray-600" : "text-gray-300 "
          } first-letter:uppercase`}
        >
          {moment.unix(job.data.creatAt).local().fromNow()}
        </span>
      </div>

      <div class="flex   gap-x-4 mb-3 h-full flex-col gap-y-2">
        <div className={` ${landing && "sm:h-2/6"}  `}>
          <div className={landing ? " sm:h-36  overflow-hidden" : ""}>
            <h1
              className={`text-lg font-bold md:text-2xl mt-5 ${
                admin ? "" : "text-gray-50"
              }  `}
            >
              {job.data.position_name}
            </h1>
            <div
              className={` border-l pl-5 flex flex-col  gap-2 text-sm   ${
                admin ? "text-gray-600" : "text-gray-300"
              } `}
            >
              <div className="inline-flex items-center space-x-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                  />
                </svg>

                <span>
                  {" "}
                  {job.data.country ? (
                    job.data.country
                  ) : (
                    <span className=" capitalize">
                      {" "}
                      {job.data.zone.replace("_", " ")}
                    </span>
                  )}
                </span>
              </div>

              <div className="inline-flex items-center space-x-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21"
                  />
                </svg>
                <Link
                  to={
                    !landing
                      ? `/one-client/${job.data.organization}?focus=${job.id}`
                      : ""
                  }
                >
                  <SpanClient organization={job.data.organization} />
                </Link>
              </div>
            </div>
          </div>{" "}
        </div>

        <div
          className={`  ${
            landing && "sm:h-1/6"
          }   flex w-full justify-end items-center gap-4`}
        >
          <div className="">
            <div className="flex justify-end space-x-3  ">
              <span>{Extracomponent1}</span>
              <span>{Extracomponent}</span>
            </div>
          </div>

          <div className="flex  overflow-x-scroll w-full   ">
            <div className=" w-full gap-4  sm:justify-end flex overflow-x-scroll">
              {job.data.salary_shown && (
                <>
                  <div className="flex shrink-0 items-center  overflow-hidden space-x-2">
                    <h1 className="bg-orange-50 white space-x-2 font-bold text-orange-600 text-sm p-2 rounded-md">
                      <span>
                        {[
                          job.data.min_salary,
                          job.data.currency ? job.data.currency : "",
                        ].join(" ")}
                      </span>
                      <span>-</span>
                      <span>
                        {[
                          job.data.max_salary,
                          job.data.currency ? job.data.currency : "",
                        ].join(" ")}
                      </span>
                    </h1>
                  </div>
                  <h1 className="bg-orange-50 shrink-0 text-orange-600 font-bold text-sm p-2 rounded-md">
                    {job.data.frequency}
                  </h1>
                </>
              )}

              {job.data.contract_details && (
                <h1 className="bg-orange-50 shrink-0 text-orange-600 font-bold text-sm p-2 rounded-md">
                  {job.data.contract_details}
                </h1>
              )}

              {job.data.is_remote && (
                <h1 className="bg-green-50 shrink-0 text-green-600  font-semibold text-sm p-2 rounded-md">
                  Remote
                </h1>
              )}
              {!job.data.is_remote && (
                <h1 className="bg-green-50 shrink-0 text-green-600  font-semibold text-sm p-2 rounded-md">
                  On-Site
                </h1>
              )}

              {job.data.headcount && (
                <h1 className="bg-blue-50 font-bold shrink-0 text-blue-600 space-x-2 text-sm inline-flex items-center p-2 rounded-md">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z"
                    />
                  </svg>

                  <span>{job.data.headcount}</span>
                </h1>
              )}
            </div>
          </div>
        </div>

        <div className={`${landing && "sm:h-2/6"}`}>
          <div>
            <span className="text-xs text-gray-500">description</span>
            {landing && (
              <p className="text-gray-100  bg-gray-800 p-4 rounded-md text-sm    overflow-y-hidden">
                {job.data.preview_description.slice(0, 200) + "..."}
              </p>
            )}
            {!landing && (
              <p className="text-gray-100 bg-gray-800 p-4 rounded-md text-sm  overflow-y-hidden">
                {job.data.preview_description.length > 200
                  ? job.data.preview_description.slice(0, 400) + "..."
                  : job.data.preview_description}
              </p>
            )}
          </div>
        </div>

        <div className={`${landing && "sm:h-1/6 sm:overflow-hidden"}`}>
          {landing && (
            <div className="flex flex-col space-y-3 sm:gap-2 sm:space-y-0 md:space-y-0 sm:flex-row items-center mt-3 md:space-x-4 justify-end">
              <a
                href={
                  job.data.manatalLinked
                    ? `/cv-drop?name=${job.data.position_name}&id=${job.id}&organization_name=${client.name}&job_mail=${job.data.gmp}`
                    : `/cv-drop?name=${job.data.position_name}&id=${job.id}&organization_name=${client.name}`
                }
                className="inline-flex items-center justify-center w-full md:w-auto gap-x-2 rounded-md bg-sky-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
              >
                Apply now !
                <ChevronRightIcon
                  className="-mr-0.5 h-5 w-5"
                  aria-hidden="true"
                />
              </a>
              <Link
                to={`/job-details/${job.id}?organization_name=${client.name}`}
                className="inline-flex items-center  w-full md:w-auto justify-center gap-x-2 rounded-md bg-orange-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
              >
                Read more
                <EyeIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default JobTemplate;
