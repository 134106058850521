import React from "react";
import { Link } from "react-router-dom";

function Clients() {
  return (
    <div className="min-h-[calc(100vh-4rem)] flex justify-center items-center bg-gray-300">
      <div className="grid md:grid-cols-2 gap-6">
        <Link
          to="/new-client"
          className="w-96 md:h-96 h-32  rounded-lg text-center flex justify-center items-center text-3xl  hover:underline cursor-pointer text-white bg-orange-600"
        >
          New Client
        </Link>
        <Link
          to={"/client-list"}
          className="w-96 md:h-96 h-32  text-center rounded-lg flex justify-center items-center text-3xl hover:underline cursor-pointer text-white bg-orange-600"
        >
          List of clients
        </Link>
      </div>
    </div>
  );
}

export default Clients;
