import { Container } from "../utils/Container";
import { FadeIn } from "../utils/FadeIn";
import { socialMediaProfiles } from "../../components/utils/SocialMedia";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

const Logo = () => (
  <svg
    className="w-4 h-4  transition-all duration-500 ease-in-out fill-slate-100 hover:fill-orange-600"
    viewBox="0 0 81 90"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.15924 18.8315L17.0255 27.8804L42.0478 14.1848L64.2325 27.8804L75.3248 18.8315L42.0478 0L5.15924 18.8315Z" />
    <path d="M0 27.8804L12.6401 36.6848V60.8967L42.0478 76.7935L81 53.0707V66.2772L42.0478 90L0 66.2772V27.8804Z" />
    <path d="M22.7006 55.0272V43.0435L42.0478 55.0272L81 29.5924V40.3533L42.0478 66.2772L22.7006 55.0272Z" />
    <path d="M25.7962 32.7717L42.0478 23.7228L55.9777 32.7717L42.0478 43.0435L25.7962 32.7717Z" />
  </svg>
);

export function Footer() {
  const refE = useRef(null);
  const refS = useRef(null);

  useEffect(() => {
    let i1 = null;
    let i2 = null;

    if (refE.current && refS.current) {
      i1 = setInterval(() => {
        refE.current.classList.toggle("text-orange-500");
      }, 5000);
      i2 = setInterval(() => {
        refS.current.classList.toggle("text-orange-500");
      }, 10000);
    }

    return () => {
      clearInterval(i1);
      clearInterval(i2);
    };
  }, []);

  return (
    <Container as="footer" className=" w-full  flex justify-center">
      <div className=" mt-20 py-5  flex justify-center flex-wrap items-end gap-x-6 gap-y-4 border-t border-neutral-950/10 pt-12">
        <div className="text-sm inline-flex items-center font-display space-x-2 text-neutral-300">
          <Link to="/"> © eMagine solutions {new Date().getFullYear()} </Link>
        </div>
      </div>

      <div className="flex justify-center gap-4 py-4  text-neutral-300">
        <h1 className=" font-semibold">Sitemap</h1>

        <div className="flex flex-wrap gap-4 ">
          <Link
            className=" hover:underline hover:text-orange-400"
            to="/find-a-job"
          >
            Live Positions
          </Link>
          <Link
            className=" hover:underline hover:text-orange-400"
            to="/about-us"
          >
            About Us
          </Link>
          <Link
            className=" hover:underline hover:text-orange-400"
            to="/insights"
          >
            Insights
          </Link>
          <Link
            className=" hover:underline hover:text-orange-400"
            to="/our-expertise"
          >
            Our Expertise
          </Link>
          <Link
            className=" hover:underline hover:text-orange-400"
            to="/privacy-policy"
          >
            Privacy policy
          </Link>
          <Link
            className=" hover:underline hover:text-orange-400"
            to="/contact"
          >
            Contact
          </Link>
          <Link
            className=" hover:underline hover:text-orange-400"
            to="/cv-drop"
          >
            Cv Drop
          </Link>
          <Link
            className=" hover:underline hover:text-orange-400"
            to="/sign-in"
          >
            Sign in
          </Link>
        </div>
      </div>
    </Container>
  );
}
