import React, { useEffect, useRef, useState } from "react";
import { FadeIn, FadeInStagger } from "../components/utils/FadeIn";
import JobTemplate from "../components/utils/job-template";
import { motion } from "framer-motion";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
import { Spinner } from "flowbite-react";

function LatestOpportunities() {
  const scrollRef = useRef(null);
  const [jobs, setJobs] = useState([]);
  const [load, setLoad] = useState(true);

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft -= 600; // Adjust the value as needed
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft += 600; // Adjust the value as needed
    }
  };

  const fetchData = async () => {
    try {
      setLoad(false);
      const q = query(
        collection(db, "jobs"),
        where("open", "==", true),
        orderBy("creatAt", "desc"),
        limit(10)
      );
      const querySnapshot = await getDocs(q);

      const jobsArray = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));

      setLoad(true);
      setJobs(jobsArray);
    } catch (error) {
      console.error("an error occurred while fetching", error);
    }
  };

  useEffect(() => {
    const abortController = new AbortController();

    const fetchClientAndJobs = async () => {
      try {
        setLoad(false); // Commencer le chargement
        await Promise.all([fetchData()]);
      } catch (error) {
        console.error("an error occurred", error);
        // Ici, gérer l'affichage d'un message d'erreur à l'utilisateur si nécessaire
      } finally {
        setLoad(true); // Terminer le chargement, que l'opération réussisse ou échoue
      }
    };

    if (!abortController.signal.aborted) {
      fetchClientAndJobs();
    }

    return () => {
      setJobs([]);
      abortController.abort(); // Optionnel : Annuler les requêtes en cours si elles n'ont pas encore abouti
    };
  }, []);

  console.log(jobs);

  return (
    <div className="relative overflow-hidden">
      <div className="absolute w-[700px] h-[700px] rounded-full  -z-0 bg-indigo-800 opacity-30 blur-3xl" />
      <div className="absolute w-[700px] h-[700px] rounded-full -z-0  right-2  bg-orange-800 opacity-30 blur-3xl" />
      <div className="absolute w-[700px] h-[700px] rounded-full -z-0  bottom-1/2 left-1/2  bg-teal-800 opacity-30 blur-3xl" />
      <FadeIn className=" bg-gradient-to-b sm:p-8  border-t-4 border-t-orange-600  w-full     relative py-8  ">
        <div class="max-w-[85rem] px-4 xl:px-0   mx-auto">
          <div className="flex">
            <h1 className="text-3xl sm:text-4xl md:text-6xl font-semibold  bg-clip-text py-2 text-transparent bg-gradient-to-r from-orange-500 to-violet-600 ">
              Latest opportunities
            </h1>
            {/*    <div className="  sm:pl-96 pb-6 hidden md:inline-flex justify-end items-start space-x-2">
            <motion.div
              onClick={scrollLeft}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              transition={{ type: "spring", stiffness: 400, damping: 17 }}
              className=" md:w-9 w-6 h-6 md:h-9 p-1.5 bg-orange-600 rounded-full justify-center items-center inline-flex"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="md:w-6 md:h-6 w-4 h-4 relative text-white flex-col justify-start items-start flex"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 19.5 8.25 12l7.5-7.5"
                />
              </svg>
            </motion.div>
            <motion.div
              onClick={scrollRight}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              transition={{ type: "spring", stiffness: 400, damping: 17 }}
              className=" md:w-9 w-6 h-6 md:h-9 p-1.5 bg-orange-600 rounded-full justify-center items-center inline-flex"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="md:w-6 md:h-6 w-4 h-4  relative text-white flex-col justify-start items-start flex"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m8.25 4.5 7.5 7.5-7.5 7.5"
                />
              </svg>
            </motion.div>
          </div> */}
          </div>

          <div
            ref={scrollRef}
            className=" overflow-x-auto hidden-scrollbar scroll-smooth w-full py-10"
          >
            {!load && (
              <div className="h-fit w-full relative">
                <div className=" absolute  inset-0 flex justify-center items-center">
                  <Spinner aria-label="Extra large spinner example" size="xl" />
                </div>
              </div>
            )}
            <FadeInStagger
              className={`  sm:pl-12  ${
                jobs.length > 0 && "sm:h-[28rem]  h-[36rem] "
              }   justify-end items-center inline-flex mt-4 space-x-6`}
            >
              {load &&
                jobs &&
                jobs.map((opening) => (
                  <div className="w-[20rem]  h-full overflow-hidden sm:w-[40rem]">
                    <JobTemplate landing job={opening} />
                  </div>
                ))}

              {load && jobs.length === 0 && (
                <a className="w-fit text-sm  whitespace-nowrap p-4 flex justify-center items-center text-gray-200 font-bold  rounded-md border cursor-pointer hover:underline ">
                  <h1 className=" whitespace-nowrap">No jobs available. </h1>
                </a>
              )}
            </FadeInStagger>
          </div>

          <div className="mt-10 flex sm:text-sm  text-xs sm:flex-row flex-col sm:space-x-4 sm:space-y-0 space-y-5 justify-end items-center w-full">
            <motion.a
              href="/cv-drop"
              role="button"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              transition={{ type: "spring", stiffness: 400, damping: 17 }}
              className=" sm:w-52 w-full h-14 px-8 pt-5 pb-6 rounded-lg border-2 border-orange-600 justify-center items-center inline-flex"
            >
              <div className="SubmitResume text-sm w-36 h-4 text-center text-white font-medium  uppercase leading-tight tracking-widest">
                Drop Cv
              </div>
            </motion.a>
            <motion.a
              role="button"
              href="/find-a-job"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              transition={{ type: "spring", stiffness: 400, damping: 17 }}
              className=" w-full  sm:w-48 h-14 px-8 pt-5 pb-6   bg-orange-600 rounded-lg border-2 border-orange-600 justify-center items-center inline-flex"
            >
              <div className=" w-32 h-4 text-sm text-center text-orange-50 font-medium  uppercase leading-tight tracking-widest">
                More jobs
              </div>
            </motion.a>
          </div>
        </div>
      </FadeIn>
    </div>
  );
}

export default LatestOpportunities;
