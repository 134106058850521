import { Container } from "../utils/Container";
import { FadeIn } from "../utils/FadeIn";

export default function NotFound() {
  return (
    <Container className="flex h-[calc(100vh-31rem)] overflow-hidden items-center pt-24 sm:pt-32 lg:pt-40">
      <FadeIn className="flex max-w-xl  flex-col items-center text-center">
        <p className="font-display text-4xl font-semibold text-white sm:text-5xl">
          404
        </p>
        <h1 className="mt-4 font-display text-2xl font-semibold text-white">
          Page not found
        </h1>
        <p className="mt-2 text-sm text-neutral-200">
          Sorry, we couldn’t find the page you’re looking for.
        </p>
        <a
          href="/"
          className="mt-4 text-sm font-semibold text-white transition hover:text-neutral-700"
        >
          Go to the home page
        </a>
      </FadeIn>
    </Container>
  );
}
