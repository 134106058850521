import { collection, doc, getDoc, getDocs, query } from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { db } from "../firebase";
import GoBack from "../components/utils/goBack";
import { Container } from "../components/utils/Container";
import { Spinner } from "flowbite-react";
import { ContactSection } from "../components/utils/ContactSection";
import { Link, useSearchParams } from "react-router-dom";
import { FadeIn } from "../components/utils/FadeIn";

function ExpertiseTabs() {
  const [load, setLoad] = useState(true);
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    const abortController = new AbortController();

    const fetchTabs = async () => {
      try {
        setLoad(false); // Commencer le chargement
        await Promise.all([fetchData()]);
      } catch (error) {
        console.error("an error occurred", error);
        // Ici, gérer l'affichage d'un message d'erreur à l'utilisateur si nécessaire
      } finally {
        setLoad(true); // Terminer le chargement, que l'opération réussisse ou échoue
      }
    };

    if (!abortController.signal.aborted) {
      fetchTabs();
    }

    return () => {
      setTabs([]);
      abortController.abort(); // Optionnel : Annuler les requêtes en cours si elles n'ont pas encore abouti
    };
  }, []);

  const fetchData = async () => {
    try {
      setLoad(false);
      const q = query(collection(db, "expertises"));
      const querySnapshot = await getDocs(q);

      const tabsArray = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));

      setLoad(true);
      setTabs(tabsArray);
    } catch (error) {
      console.error("an error occurred while fetching", error);
    }
  };

  return !load ? (
    <div className="h-96 hidden">
      <div className=" absolute inset-0 flex justify-center items-center">
        <Spinner aria-label="Extra large spinner example" size="xl" />
      </div>
    </div>
  ) : (
    <ul className="py-2 md:w-1/3 space-y-3 z-[10000000]  hidden absolute   bg-gray-800 p-3 rounded-lg ">
      {tabs &&
        tabs.map((item) => (
          <li
            onClick={(e) =>
              e.currentTarget.parentElement.classList.toggle("hidden")
            }
          >
            <Link
              to={`/our-expertise?id=${item.id}`}
              key={item.data.name}
              className="group relative -mx-3 flex gap-6 rounded-lg p-3 text-sm leading-6 hover:bg-gray-700  sm:p-6 "
            >
              <div className="flex h-16 w-16 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                <img
                  src={item.data.imgUrl}
                  className="h-full w-full object-cover rounded-lg"
                  alt="img"
                />
              </div>
              <div>
                <h1 className="text-gray-200">{item.data.name}</h1>
                <p className="text-gray-400">
                  {item.data.description.slice(0, 50) + "..."}
                </p>
              </div>
            </Link>
          </li>
        ))}
    </ul>
  );
}

export default function OurExpertise() {
  const [exepertise, setExpertise] = useState({});
  const [load, setLoad] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();

  const Content = ({ content }) => {
    const contentRef = useRef();

    useEffect(() => {
      if (contentRef.current && content) {
        contentRef.current.innerHTML = content;
      }
    }, []);

    return (
      <div
        className="  bg-gradient-to-bl from-orange-600/20  to-violet-400/30 rounded-lg shadow-md p-4"
        ref={contentRef}
      ></div>
    );
  };

  useEffect(() => {
    const abortController = new AbortController();

    const fetchExpertise = async () => {
      try {
        setLoad(false); // Commencer le chargement
        await Promise.all([fetchData()]);
      } catch (error) {
        console.error("an error occurred", error);
        // Ici, gérer l'affichage d'un message d'erreur à l'utilisateur si nécessaire
      } finally {
        setLoad(true); // Terminer le chargement, que l'opération réussisse ou échoue
      }
    };

    if (!abortController.signal.aborted) {
      fetchExpertise();
    }

    return () => {
      setExpertise({});
      abortController.abort(); // Optionnel : Annuler les requêtes en cours si elles n'ont pas encore abouti
    };
  }, [searchParams.get("id")]);

  const fetchData = async () => {
    try {
      setLoad(false);

      const docRef = doc(db, "expertises", searchParams.get("id"));
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setLoad(true);
        setExpertise(docSnap.data());
      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }
    } catch (error) {
      console.error("an error occurred while fetching", error);
    }
  };

  return (
    <div className="relative overflow-hidden">
      <div className="absolute w-[700px] h-[700px] rounded-full  -z-0 bg-indigo-800 opacity-30 blur-3xl" />
      <div className="absolute w-[700px] h-[700px] rounded-full -z-0  right-2  bg-orange-800 opacity-30 blur-3xl" />

      <div className="absolute w-[700px] h-[700px] rounded-full -z-0  bottom-1/2 left-1/2  bg-teal-800 opacity-30 blur-3xl" />

      <div className=" py-32 relative bg-gradient-to-bl ">
        <div>
          <div className="max-w-[85rem] px-4 xl:px-0 mx-auto   mt-6  ">
            <FadeIn className=" flex items-center my-8 ">
              <div className="flex  flex-col  text-4xl md:text-8xl  ">
                <h1 className="  bg-clip-text py-2 font-bold text-transparent bg-gradient-to-r from-orange-500 to-violet-600">
                  Discover Our Expertise
                </h1>
               
              </div>
            </FadeIn>

            <div className=" relative">
              <h1
                onClick={(e) =>
                  console.log(
                    e.currentTarget.nextSibling.classList.toggle("hidden")
                  )
                }
                className="text-lg my-4 w-full justify-center  inline-flex items-center space-x-2 cursor-pointer  text-white    border rounded-xl p-4  border-orange-600"
              >
                <span>Expertise</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m19.5 8.25-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </h1>
              <ExpertiseTabs />
            </div>

            {load && searchParams.get("id") && (
              <div className="grid md:grid-cols-12 gap-6  my-20 divide-x ">
                <div className="col-span-2  ">
                  <img
                    src={exepertise.imgUrl}
                    alt=""
                    srcset=""
                    className="w-full h-32 rounded-lg border object-cover"
                  />
                  <h1 className="text-gray-100 text-2xl font-semibold mt-3">
                    {exepertise.name}
                  </h1>
                </div>
                <div className=" col-span-10 px-4 ">
                  <Content content={exepertise.content} />
                </div>
              </div>
            )}
          </div>

          {!load && (
            <div className="h-96">
              <div className="  flex justify-center items-center">
                <Spinner aria-label="Extra large spinner example" size="xl" />
              </div>
            </div>
          )}
        </div>

        <ContactSection />
      </div>
    </div>
  );
}
