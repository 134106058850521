import { ChevronRightIcon, PlusIcon } from "@heroicons/react/24/outline";
import { FadeIn, FadeInStagger } from "../components/utils/FadeIn";
import { ContactSection } from "../components/utils/ContactSection";
import JobTemplate from "../components/utils/job-template";
import SideMenu from "../components/utils/side-menu";
import { Container } from "../components/utils/Container";
import { Border } from "../components/utils/Border";
import SearchBar from "../components/utils/search-bar";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { doc, getDoc, where } from "firebase/firestore";
import { db } from "../firebase";
import GoBack from "../components/utils/goBack";
import { Spinner } from "flowbite-react";

function addClassToElements(html, tagName, className) {
  const regex = new RegExp(`<${tagName}(.*?)>`, "gi");
  return html?.replace(regex, `<${tagName} class="${className}"$1>`);
}

export default function JobsDetails() {
  const [job, setJob] = useState({});
  const { id } = useParams();
  const [load, setLoad] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const abortController = new AbortController();

    const fetchClientAndJobs = async () => {
      try {
        setLoad(false); // Commencer le chargement
        await Promise.all([fetchData()]);
      } catch (error) {
        console.error("an error occurred", error);
        // Ici, gérer l'affichage d'un message d'erreur à l'utilisateur si nécessaire
      } finally {
        setLoad(true); // Terminer le chargement, que l'opération réussisse ou échoue
      }
    };

    if (!abortController.signal.aborted) {
      fetchClientAndJobs();
    }

    return () => {
      setJob({});
      abortController.abort(); // Optionnel : Annuler les requêtes en cours si elles n'ont pas encore abouti
    };
  }, []);

  const fetchData = async () => {
    try {
      setLoad(false);

      const docRef = doc(db, "jobs", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setLoad(true);
        setJob(docSnap.data());
      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }
    } catch (error) {
      console.error("an error occurred while fetching", error);
    }
  };

  /* 
  useEffect(() => {
    (async () => {
      const response = await fetch(
        `https://api.manatal.com/open/v3/career-page/manatal/jobs/${id}`
      );

      if (response.ok) {
        const data = await response.json();
        setJob(data);
      }
    })();

    return () => setJob({});
  }, [id]); */

  return (
    <div className="relative overflow-hidden min-h-screen">
      <div className="absolute w-[700px] h-[700px] rounded-full  -z-0 bg-indigo-800 opacity-30 blur-3xl" />
      <div className="absolute w-[700px] h-[700px] rounded-full -z-0  right-1/2  bg-orange-800 opacity-30 blur-3xl" />
      <div className="absolute w-[700px] h-[700px] rounded-full -z-0  right-1/4  bg-violett-800 opacity-30 blur-3xl" />
      <div className="absolute w-[700px] h-[700px] rounded-full -z-0  bottom-1/2 left-1/2  bg-teal-800 opacity-30 blur-3xl" />
      <div className="absolute w-[700px] h-[700px] rounded-full -z-0  bottom-1/2 top-1/2 -right-20  bg-teal-800 opacity-30 blur-3xl" />
      <div className="absolute w-[900px] h-[900px] rounded-full -z-0  bottom-0 left-1/3  bg-fuchsia-800 opacity-30 blur-3xl" />

      <div className=" pt-32 overflow-hidden relative bg-gradient-to-br z-10 ">
        {!load && (
          <div className="h-96">
            <div className=" absolute inset-0 flex justify-center items-center">
              <Spinner aria-label="Extra large spinner example" size="xl" />
            </div>
          </div>
        )}

        <div className="max-w-[85rem] px-4 xl:px-0 mx-auto   mt-6  ">
          <FadeIn className="  flex flex-col  ">
            <h1 className=" text-white text-lg font-bold  inline-flex items-center space-x-2">
              {" "}
              <GoBack />
            </h1>
            <div className="flex flex-col">
              <h1 className="lg:text-8xl md:text-6xl text-4xl   font-bold   bg-clip-text py-2 text-transparent bg-gradient-to-r from-orange-500 to-violet-600">
                {job.position_name}
              </h1>

              <p className=" md:text-lg text-sm max-w-2xl text-gray-300">
                {job.preview_description}
              </p>
            </div>
            {load && (
              <div className="flex flex-col space-y-3 md:space-y-0 md:flex-row items-center mt-3 md:space-x-4 justify-end">
                <Link
                  to={
                    job.manatalLinked
                      ? `/cv-drop?name=${job.position_name}&id=${
                          id
                        }&organization_name=${searchParams.get(
                          "organization_name"
                        )}&job_mail=${job.gmp}`
                      : `/cv-drop?name=${job.position_name}&id=${
                          id
                        }&organization_name=${searchParams.get(
                          "organization_name"
                        )}`
                  }
                  className="inline-flex animate-bounce items-center justify-center w-full md:w-auto gap-x-2 rounded-md bg-sky-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
                >
                  Apply now !
                  <ChevronRightIcon
                    className="-mr-0.5 h-5 w-5"
                    aria-hidden="true"
                  />
                </Link>
              </div>
            )}

            <div
              className="px-8 my-10 py-10 relative  bg-gradient-to-bl from-orange-600/20  to-violet-400/30  shadow-md   rounded-lg"
              dangerouslySetInnerHTML={{ __html: job.description }}
            ></div>
          </FadeIn>
        </div>

        <ContactSection />
      </div>
    </div>
  );
}
