import React from "react";
import { motion } from "framer-motion";
import { FadeIn, FadeInStagger } from "../components/utils/FadeIn";
import { Container } from "../components/utils/Container";
import {
  SvgBigNetwork,
  SvgFastHiring,
  SvgKnowLedge,
  SvgPerfectMatch,
} from "../components/utils/svgFile";

function Seeking() {
  return (
    <div className="from-black border-t-4 border-t-orange-600 to-orange-800/15 bg-gradient-to-b relative min-h-[800px] sm:p-8   overflow-hidden -mt-[0.85rem] z-50">
      <div>
        <div class="max-w-[85rem] px-4 xl:px-0 py-10 lg:pt-20 lg:pb-20 mx-auto">
          <div class="max-w-5xl mb-10 lg:mb-14">
            <h2 className="text-3xl sm:text-4xl md:text-6xl font-semibold   bg-clip-text py-2 text-transparent bg-gradient-to-r from-orange-500 to-violet-600  ">
              Elevate Your Team
            </h2>
            <p class="mt-1 sm:text-lg text-sm text-neutral-400">
              eMagine is more than a recruitment agency, we see ourselves as an
              extension of your business. Working as a partner we help both
              candidates and companies find the perfect fit.
            </p>
          </div>

          <div class="grid grid-cols-1 lg:grid-cols-2 gap-10 lg:gap-16 lg:items-center">
            <div class=" aspect-square  relative">
              <img
                class="w-full h-full aspect-auto object-cover rounded-xl"
                src={require("../assets/illustrations/7943.jpg")}
                alt="Image Description"
              />
              <div className=" absolute inset-0 bg-gradient-to-b from-orange-800/80 rounded-xl" />
            </div>

            <div>
              <div class="mb-4">
                <h3 class=" text-lg font-semibold bg-clip-text py-2 text-transparent bg-gradient-to-r from-orange-500 to-violet-600  ">
                  Four Key Strategies for Hiring and Growing Your Business
                </h3>
              </div>

              <div class="flex gap-x-5 ms-1">
                <div class="relative last:after:hidden after:absolute after:top-8 after:bottom-0 after:start-4 after:w-px after:-translate-x-[0.5px] after:bg-neutral-800">
                  <div class="relative z-10 size-8 flex justify-center items-center">
                    <span class="flex flex-shrink-0 justify-center items-center size-8 border border-neutral-800 text-orange-500 font-semibold text-sm uppercase rounded-full">
                      1
                    </span>
                  </div>
                </div>

                <div class="grow pt-0.5 pb-8 sm:pb-12">
                  <p class=" flex flex-col text-neutral-400">
                    <span class="text-white text-xl">
                      Invest in employee development
                    </span>
                    <span className=" text-sm">
                      {" "}
                      Foster a culture of continuous learning by providing
                      regular training and professional development
                      opportunities. Encourage employees to pursue
                      certifications, attend workshops, and engage in
                      skill-building activities that enhance their expertise and
                      productivity.
                    </span>
                  </p>
                </div>
              </div>

              <div class="flex gap-x-5 ms-1">
                <div class="relative last:after:hidden after:absolute after:top-8 after:bottom-0 after:start-4 after:w-px after:-translate-x-[0.5px] after:bg-neutral-800">
                  <div class="relative z-10 size-8 flex justify-center items-center">
                    <span class="flex flex-shrink-0 justify-center items-center size-8 border border-neutral-800 text-orange-500 font-semibold text-sm uppercase rounded-full">
                      2
                    </span>
                  </div>
                </div>

                <div class="grow pt-0.5 pb-8 sm:pb-12">
                  <p class="flex flex-col text-neutral-400">
                    <span class="text-white text-xl">
                      Prioritise cultural fit
                    </span>
                    <span className="text-sm">
                      Ensure that new hires align with your company's values and
                      culture. A strong cultural fit promotes better teamwork,
                      higher morale, and a more cohesive work environment,
                      leading to increased overall performance and employee
                      satisfaction.
                    </span>
                  </p>
                </div>
              </div>

              <div class="flex gap-x-5 ms-1">
                <div class="relative last:after:hidden after:absolute after:top-8 after:bottom-0 after:start-4 after:w-px after:-translate-x-[0.5px] after:bg-neutral-800">
                  <div class="relative z-10 size-8 flex justify-center items-center">
                    <span class="flex flex-shrink-0 justify-center items-center size-8 border border-neutral-800 text-orange-500 font-semibold text-sm uppercase rounded-full">
                      3
                    </span>
                  </div>
                </div>

                <div class="grow pt-0.5 pb-8 sm:pb-12">
                  <p class=" flex flex-col text-neutral-400">
                    <span class="text-white text-xl">
                      Implement mentorship programmes
                    </span>
                    <span className=" text-sm">
                      Pair new employees with experienced mentors who can guide
                      them through their career journey. Mentorship helps new
                      hires acclimatise quickly, develop their skills, and gain
                      valuable insights from seasoned professionals, thereby
                      enhancing their contributions to the team.
                    </span>
                  </p>
                </div>
              </div>

              <div class="flex gap-x-5 ms-1">
                <div class="relative last:after:hidden after:absolute after:top-8 after:bottom-0 after:start-4 after:w-px after:-translate-x-[0.5px] after:bg-neutral-800">
                  <div class="relative z-10 size-8 flex justify-center items-center">
                    <span class="flex flex-shrink-0 justify-center items-center size-8 border border-neutral-800 text-orange-500 font-semibold text-sm uppercase rounded-full">
                      4
                    </span>
                  </div>
                </div>

                <div class="grow pt-0.5 pb-8 sm:pb-12">
                  <p class="  flex flex-col  text-neutral-400">
                    <span class="text-white text-xl">
                      Foster open communication
                    </span>
                    <span className="text-sm">
                      Create an environment where open communication is
                      encouraged and valued. Regular feedback sessions, team
                      meetings, and an open-door policy help build trust and
                      transparency, ensuring that everyone is aligned with the
                      company’s goals and working collaboratively towards
                      success.
                    </span>
                  </p>
                </div>
              </div>

              <motion.a
                class="group inline-flex items-center gap-x-2 py-2 px-3 border-2 border-orange-600 text-white  font-medium sm:text-xl text-sm  rounded-full focus:outline-none"
                role="button"
                href="/about-us?s=team"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                transition={{ type: "spring", stiffness: 400, damping: 17 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
                  />
                </svg>
                Join our team
              </motion.a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Seeking;
