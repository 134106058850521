import React from "react";
import { FadeIn } from "../components/utils/FadeIn";
import { motion } from "framer-motion";
import { Container } from "../components/utils/Container";

function JoinUs() {
  return (
    <FadeIn className="w-full sm:p-8 relative py-10 min-h-[50rem] z-10 border-t-4 border-t-orange-600   overflow-hidden ">
      <div
        className=" inset-0 absolute -z-10 opacity-20"
        style={{
          backgroundImage: `url(${require("../assets/illustrations/growth.jpg")})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "top",
        }}
      ></div>

      <div class="max-w-[85rem] px-4  sm:px-6 lg:px-8 lg:py-8 mx-auto">
        <h1 className=" text-3xl  md:text-6xl font-semibold flex flex-col  ">
          <span className="bg-clip-text py-2 text-transparent bg-gradient-to-r from-orange-500 to-violet-600">
            Empower Your Career at eMagine Solutions
          </span>
          <span className="text-orange-500 text-lg">
            Innovate, Excel, and Lead
          </span>
        </h1>

        <p className="text-gray-400  sm:text-lg text-sm  mt-1   block    ">
          At eMagine Solutions, we pride ourselves on being at the forefront of
          innovation in the recruitment industry. Our global team is composed of
          seasoned professionals, each bringing a wealth of knowledge,
          experience, and a unique set of skills to the table. Here's why you
          should consider joining our dynamic team:
        </p>

        <div class="relative p-6 md:p-16 mt-10 overflow-hidden">
          <div class="relative z-10 lg:grid lg:grid-cols-12 lg:gap-16 lg:items-center">
            <div class="mb-10 lg:mb-0 lg:col-span-6 lg:col-start-8 lg:order-2">
              <h2 class="sm:text-2xl  text-lg font-bold  text-neutral-200">
                Why Join{" "}
                <span className="text-orange-500">eMagine Solutions ?</span>
              </h2>

              <nav
                class="grid gap-4 mt-5 md:mt-10"
                aria-label="Tabs"
                role="tablist"
              >
                <button
                  type="button"
                  class=" hs-tab-active:shadow-md hs-tab-active:hover:border-transparent text-start  p-4 md:p-5 rounded-xl hs-tab-active:bg-neutral-700 hover:bg-neutral-700"
                  id="tabs-with-card-item-1"
                  data-hs-tab="#tabs-with-card-1"
                  aria-controls="tabs-with-card-1"
                  role="tab"
                >
                  <span class="flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      class="flex-shrink-0 mt-2 size-6 md:size-7   hs-tab-active:text-blue-500 text-neutral-200"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 18v-5.25m0 0a6.01 6.01 0 0 0 1.5-.189m-1.5.189a6.01 6.01 0 0 1-1.5-.189m3.75 7.478a12.06 12.06 0 0 1-4.5 0m3.75 2.383a14.406 14.406 0 0 1-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 1 0-7.517 0c.85.493 1.509 1.333 1.509 2.316V18"
                      />
                    </svg>

                    <span class="grow ms-6">
                      <span class="block sm:text-lg text-sm font-semibold   hs-tab-active:text-blue-500 text-neutral-100">
                        Innovate and Inspire
                      </span>
                      <span class="block mt-1 sm:text-sm text-xs  hs-tab-active:text-gray-200 text-neutral-300">
                        At eMagine Solutions, we thrive on innovation. You'll be
                        part of a dynamic team where your ideas can shape the
                        future and inspire real change
                      </span>
                      <span class="block mt-1 sm:text-sm text-xs  hs-tab-active:text-gray-200 text-orange-500">
                        We believe in pushing boundaries and thinking outside
                        the box.
                      </span>
                    </span>
                  </span>
                </button>

                <button
                  type="button"
                  class=" hs-tab-active:shadow-md hs-tab-active:hover:border-transparent text-start  p-4 md:p-5 rounded-xl hs-tab-active:bg-neutral-700 hover:bg-neutral-700"
                  id="tabs-with-card-item-2"
                  data-hs-tab="#tabs-with-card-2"
                  aria-controls="tabs-with-card-2"
                  role="tab"
                >
                  <span class="flex">
                    <svg
                      class="flex-shrink-0 mt-2 size-6 md:size-7   hs-tab-active:text-blue-500 text-neutral-200"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path d="m12 14 4-4" />
                      <path d="M3.34 19a10 10 0 1 1 17.32 0" />
                    </svg>
                    <span class="grow ms-6">
                      <span class="block sm:text-lg text-sm font-semibold   hs-tab-active:text-blue-500 text-neutral-100">
                        Career Growth
                      </span>
                      <span class="block mt-1 sm:text-sm text-xs  hs-tab-active:text-gray-200 text-neutral-300">
                        We invest in your future.With extensive training
                        programs, mentorship opportunities, and a clear path for
                        career advancement
                      </span>
                      <span class="block mt-1 sm:text-sm text-xs  hs-tab-active:text-gray-200 text-orange-500">
                        you'll have all the tools you need to grow and succeed.
                      </span>
                    </span>
                  </span>
                </button>

                <button
                  type="button"
                  class=" hs-tab-active:shadow-md hs-tab-active:hover:border-transparent text-start  p-4 md:p-5 rounded-xl hs-tab-active:bg-neutral-700 hover:bg-neutral-700"
                  id="tabs-with-card-item-3"
                  data-hs-tab="#tabs-with-card-3"
                  aria-controls="tabs-with-card-3"
                  role="tab"
                >
                  <span class="flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      class="flex-shrink-0 mt-2 size-6 md:size-7   hs-tab-active:text-blue-500 text-neutral-200"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
                      />
                    </svg>

                    <span class="grow ms-6">
                      <span class="block sm:text-lg text-sm font-semibold  hs-tab-active:text-blue-500 text-neutral-100">
                        Collaborative Culture
                      </span>
                      <span class="block mt-1 sm:text-sm text-xs hs-tab-active:text-gray-200 text-neutral-300">
                        Join a community of passionate professionals who love
                        what they do. Our open and inclusive environment
                        encourages collaboration
                      </span>
                      <span class="block mt-1 sm:text-sm text-xs hs-tab-active:text-gray-200 text-orange-500">
                        creativity, and fun. We work hard, but we also make time
                        to celebrate our successes together.
                      </span>
                    </span>
                  </span>
                </button>
                <button
                  type="button"
                  class=" hs-tab-active:shadow-md hs-tab-active:hover:border-transparent text-start  p-4 md:p-5 rounded-xl hs-tab-active:bg-neutral-700 hover:bg-neutral-700"
                  id="tabs-with-card-item-3"
                  data-hs-tab="#tabs-with-card-3"
                  aria-controls="tabs-with-card-3"
                  role="tab"
                >
                  <span class="flex">
                    <svg
                      class="flex-shrink-0 mt-2 size-6 md:size-7   hs-tab-active:text-blue-500 text-neutral-200"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
                      <path d="M5 3v4" />
                      <path d="M19 17v4" />
                      <path d="M3 5h4" />
                      <path d="M17 19h4" />
                    </svg>
                    <span class="grow ms-6">
                      <span class="block sm:text-lg text-sm font-semibold  hs-tab-active:text-blue-500 text-neutral-100">
                        Cutting-Edge Technology
                      </span>
                      <span class="block mt-1 sm:text-sm text-xs hs-tab-active:text-gray-200 text-neutral-300">
                        At eMagine we embrace tech, join us and be at the
                        forefront of technological advancements.
                      </span>
                      <span class="block mt-1 sm:text-sm text-xs hs-tab-active:text-gray-200 text-orange-500">
                        At eMagine Solutions, you'll have access to the latest
                        tools and technologies, empowering you to innovate and
                        excel in your role.
                      </span>
                    </span>
                  </span>
                </button>
              </nav>

              <div className=" flex  mt-10  justify-end relative  ">
                <div className="inset-0 absolute   rounded-s-lg  shadow-sm border-indigo-800 bg-gradient-to-r from-orange-900 to-violet-600 -right-20"></div>

                <div className=" z-50 p-4 -me-11 font-medium rounded-s-lg text-white  text-sm  ">
                  Explore endless possibilities with us at {" "}
                  <a href="" className=" text-orange-500 font-bold ">
                    eMagine Solutions
                  </a>{" "}
                  and embark on an exciting career journey that blends fun,
                  growth, and innovation.
                </div>

                {/*           <motion.a
                  role="button"
                  href="/join-us"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  transition={{ type: "spring", stiffness: 400, damping: 17 }}
                  className=""
                ></motion.a> */}
              </div>
            </div>

            <div class="lg:col-span-6">
              <div class="relative">
                <div>
                  <div
                    id="tabs-with-card-1"
                    role="tabpanel"
                    className="relative"
                    aria-labelledby="tabs-with-card-item-1"
                  >
                    <div className=" absolute inset-0 bg-gradient-to-bl  from-orange-800 rounded-xl " />
                    <img
                      class="shadow-xl  rounded-xl shadow-gray-900/20"
                      src={require("../assets/illustrations/35623.jpg")}
                      alt="Image Description"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="absolute inset-0 grid grid-cols-12 size-full">
            <div class="col-span-full lg:col-span-7 lg:col-start-6  w-full h-5/6 rounded-xl sm:h-3/4 lg:h-full bg-neutral-800"></div>
          </div>
        </div>
      </div>
    </FadeIn>
  );
}

export default JoinUs;
