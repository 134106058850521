// src/useBehaviourTracking.js
import { useEffect, useState } from "react";

import {
  doc,
  setDoc,
  updateDoc,
  arrayUnion,
  getDoc,
  increment,
  addDoc,
  collection,
  query,
  where,
  getDocs,
  runTransaction,
} from "firebase/firestore";
import { useLocation } from "react-router-dom";
import { db } from "../firebase";

const useBehaviourTracking = () => {
  const location = useLocation();
  const [startTime, setStartTime] = useState(Date.now());

  const recordSession = async () => {
    const today = new Date().toISOString().split("T")[0]; // Format YYYY-MM-DD
    const sessionDocId = `session_per_date_${today}`;
    const sessionRef = doc(db, "behaviour", sessionDocId);
  
    try {
      const docSnapshot = await getDoc(sessionRef);
  
      if (docSnapshot.exists()) {
        // Si le document existe, mettez à jour le nombre.
        await updateDoc(sessionRef, { number: increment(1) });
      } else {
        // Si le document n'existe pas, créez-le.
        await setDoc(sessionRef, {
          date: today,
          type: "session_per_date",
          number: 1,
        });
      }
    } catch (error) {
      console.error("Error recording session: ", error);
    }
  };

  const recordTimeSpent = async (timeSpent) => {
    try {
      await runTransaction(db, async (transaction) => {
        const q = query(
          collection(db, "behaviour"),
          where("type", "==", "time_spent_per_page"),
          where("page_name", "==", location.pathname)
        );

        const querySnapshot = await getDocs(q);

        if (querySnapshot.docs.length === 1) {
          // Si le document existe, mettez à jour le temps passé.
          const timeDocRef = querySnapshot.docs[0].ref;
          transaction.update(timeDocRef, {
            timeSpent: arrayUnion(timeSpent),
          });
        } else if (querySnapshot.docs.length === 0) {
          // Si le document n'existe pas, créez-le.
          const newTimeDocRef = doc(collection(db, "behaviour"));
          transaction.set(newTimeDocRef, {
            type: "time_spent_per_page",
            page_name: location.pathname,
            timeSpent: [timeSpent], // Utilisez un tableau directement puisque c'est le premier élément.
          });
        } else {
          // Gérer le cas où plusieurs documents existent pour la même page.
          console.error(
            "Multiple documents found for the same page, manual cleanup needed."
          );
        }
      });
    } catch (error) {
      console.error("Transaction failed: ", error);
    }
  };

  useEffect(() => {
    // Définir le startTime au chargement de la page
    setStartTime(Date.now());

    return () => {
      // Calculer le temps passé lorsque le composant est démonté ou la page est changée
      const endTime = Date.now();
      const timeSpent = endTime - startTime;
      recordTimeSpent(timeSpent);
    };
  }, [location.pathname]);

  const recordInteraction = async (interactionData = {}) => {
    interactionData.type = "event_listner";

    try {
      const q = query(
        collection(db, "behaviour"),
        where("type", "==", "event_listner"),
        where("page_name", "==", interactionData.page_name),
        where("format", "==", interactionData.format)
      );

      const querySnapshot = await getDocs(q);
      if (querySnapshot.docs.length > 0) {
        const id = querySnapshot.docs[0].id;
        const interactionRef = doc(db, "behaviour", id);

        await updateDoc(interactionRef, {
          number: increment(1),
        });
      } else {
        await addDoc(collection(db, "behaviour"), interactionData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAndRecordCountry = async () => {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      const { ip } = await response.json();
      const ipInfoResponse = await fetch(
        `https://ipinfo.io/${ip}?token=6cfbbde44b30f1`
      );
      const { country, region, city } = await ipInfoResponse.json();

      console.warn(country);

      const documentId = `user_per_country_${country}`;
      const documentRef = doc(db, "behaviour", documentId);

      await runTransaction(db, async (transaction) => {
        const docSnapshot = await transaction.get(documentRef);

        if (!docSnapshot.exists()) {
          // Créer le document s'il n'existe pas, avec `number` initialisé à 1.
          transaction.set(documentRef, {
            type: "user_per_country",
            country,
            region,
            city,
            number: 1,
          });
        } else {
          // Mettre à jour le nombre si le document existe déjà.
          transaction.update(documentRef, { number: increment(1) });
        }
      });
    } catch (error) {
      console.error("Transaction failed: ", error);
    }
  };

  useEffect(() => {
    recordSession();
    fetchAndRecordCountry();
  }, []);

  useEffect(() => {
    const captureInteraction = (event) => {
      const { target, type } = event;
      const interactionData = {
        format: type,
        target: target.tagName,
        text: target.innerText,
        page_name: location.pathname, // Ajout du nom de la page
        number: 1,
      };
      recordInteraction(interactionData);
    };

    document.addEventListener("click", captureInteraction);
    document.addEventListener("input", captureInteraction);

    return () => {
      document.removeEventListener("click", captureInteraction);
      document.removeEventListener("input", captureInteraction);
    };
  }, [location.pathname]); // Ajout de location.pathname aux dépendances pour réagir aux changements de chemin
};

export default useBehaviourTracking;
