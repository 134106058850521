import React, { useEffect, useRef, useState } from "react";
import { FadeIn, FadeInStagger } from "../components/utils/FadeIn";
import {
  CheckCircleIcon,
  DocumentArrowUpIcon,
} from "@heroicons/react/24/outline";

import { db, storage } from "../firebase";
import { uploadBytes, ref, getDownloadURL } from "firebase/storage";
import { useLocation } from "react-router-dom";
import { addDoc, collection } from "firebase/firestore";
import moment from "moment";

async function generateHashForFilename(filename) {
  const encoder = new TextEncoder();
  const data = encoder.encode(filename);
  const hashBuffer = await crypto.subtle.digest("SHA-256", data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
  return hashHex;
}

function JoinUsForm() {
  const form = useRef();
  const file = useRef();
  const [candidateName, setCandidateName] = useState("");
  const [candidateEmail, setCandidateEmail] = useState("");
  const [candidateContactNumber, setCandidateContactNumber] = useState("");
  const [candidateLinkedin, setCandidateLinkedin] = useState("");
  const [country, setCountry] = useState("");
  const [title, setTitle] = useState("");
  const [Comment, setComment] = useState("");
  const location = useLocation();
  const [fileName, setFileName] = useState("");
  const [sent, setSent] = useState(false);
  const [load, setLoad] = useState(true);

  const sendEmail = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    setLoad(false);

    console.log(formData.entries());
    const response = await handleUpdate();
    const downloadURL = await getDownloadURL(response.ref);

    setSent(false);
    await addDoc(collection(db, "join_team"), {
      candidate_name: candidateName,
      candidate_email: candidateEmail,
      candidate_contact_number: candidateContactNumber,
      candidate_linkedin: candidateLinkedin,
      comment: Comment,
      link: downloadURL,
      title,
      creatAt: moment.utc().unix(),
    }).then(
      (result) => {
        setSent(true);
        setLoad(true);
        setTimeout(() => {
          window.location.reload();
        }, 10000);
      },
      (error) => {}
    );
  };

  const handleUpdate = async () => {
    const hash = await generateHashForFilename(file.current.files[0].name);
    const storageRef = ref(storage, ["cvs", hash].join("/"));

    return await uploadBytes(storageRef, file.current.files[0]);
  };

  return (
    <div>
      <div className="absolute w-[700px] h-[700px] rounded-full  -z-0 bg-indigo-800 opacity-40 blur-3xl" />
      <div className="absolute w-[700px] h-[700px] rounded-full -z-0  right-2  bg-orange-800 opacity-30 blur-3xl" />
      <div className="absolute w-[700px] h-[700px] rounded-full -z-0  bottom-1/2 left-1/2  bg-teal-800 opacity-30 blur-3xl" />

      <div className=" relative ">
        <div class="max-w-[85rem] px-4 py-24  sm:px-6 lg:px-8 lg:py-36 mx-auto">
          <FadeInStagger class="grid md:grid-cols-2 items-center gap-12">
            <FadeIn>
              {sent && (
                <div className="col-span-full flex items-center justify-center">
                  <img
                    src={require("../assets/illustrations/check_12484055.png")}
                    className="h-40 w-40 my-10  animate-bounce self-center"
                    alt=""
                    srcset=""
                  />
                </div>
              )}

              <h1 class="text-3xl font-bold text-white sm:text-4xl lg:text-5xl lg:leading-tight ">
                Join us
              </h1>
              <p class="mt-1 md:text-lg text-gray-200">
                Empower Your Career at eMagine Solutions
              </p>

              <div class="mt-8">
                <h2 class="text-lg font-semibold text-gray-200">
                  What Can You Expect ?
                </h2>

                <ul class="mt-2 space-y-2">
                  <li class="flex space-x-3">
                    <svg
                      class="flex-shrink-0 mt-0.5 h-5 w-5 text-gray-100"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <polyline points="20 6 9 17 4 12" />
                    </svg>
                    <span class="text-gray-100">Innovate</span>
                  </li>

                  <li class="flex space-x-3">
                    <svg
                      class="flex-shrink-0 mt-0.5 h-5 w-5 text-gray-100"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <polyline points="20 6 9 17 4 12" />
                    </svg>
                    <span class="text-gray-100">Excel</span>
                  </li>

                  <li class="flex space-x-3">
                    <svg
                      class="flex-shrink-0 mt-0.5 h-5 w-5 text-gray-100"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <polyline points="20 6 9 17 4 12" />
                    </svg>
                    <span class="text-gray-100">Lead</span>
                  </li>
                </ul>
              </div>

              <div className="mt-8 flex  items-center justify-center flex-col ">
                <input
                  type="file"
                  onChange={(e) => setFileName(e.currentTarget.files[0].name)}
                  className="hidden"
                  ref={file}
                  name="cv"
                  id=""
                />

                <button
                  onClick={() => file.current.click()}
                  type="button"
                  className="inline-flex items-center justify-center gap-x-2 mt-10 md:w-96 rounded-lg   bg-orange-600 px-3.5 py-2.5 text-sm  font-semibold text-white animate-pulse shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                >
                  <div className="w-full inline-flex space-x-5  justify-center">
                    <span> Upload CV</span>
                    <DocumentArrowUpIcon
                      className="-mr-0.5 h-5 w-5"
                      aria-hidden="true"
                    />
                  </div>
                </button>
                {fileName && (
                  <div className="p-4 text-white bg-slate-400/20 rounded-md mt-6 w-full text-center">
                    {fileName}
                  </div>
                )}
              </div>
            </FadeIn>

            <FadeIn class="relative">
              <div class="flex flex-col  rounded-xl p-4 sm:p-6 lg:p-10 ">
                <h2 class="text-xl font-semibold text-gray-200">
                  Fill in the form
                </h2>

                <form ref={form} onSubmit={sendEmail}>
                  <div class="mt-6 grid gap-4 lg:gap-6">
                    <div class="grid sm:grid-cols-2  gap-4 lg:gap-6">
                      <div>
                        <label
                          for="hs-firstname-hire-us-1"
                          class="block mb-2 text-sm  font-medium text-white"
                        >
                          Full Name
                        </label>
                        <input
                          type="text"
                          onChange={(e) =>
                            setCandidateName(e.currentTarget.value)
                          }
                          name="candidate_name"
                          id="hs-firstname-hire-us-1"
                          class="py-3 px-4 block w-full  rounded-lg text-sm focus:border-orange-500  disabled:opacity-50 disabled:pointer-events-none bg-slate-900/20 border-gray-700 text-gray-100 focus:ring-gray-600"
                        />
                      </div>

                      <div>
                        <label
                          for="hs-title-hire-us-1"
                          class="block mb-2 text-sm  font-medium text-white"
                        >
                          Title
                        </label>
                        <input
                          type="text"
                          onChange={(e) => setTitle(e.currentTarget.value)}
                          name="title"
                          id="hs-title-hire-us-1"
                          class="py-3 px-4 block w-full  rounded-lg text-sm focus:border-orange-500  disabled:opacity-50 disabled:pointer-events-none bg-slate-900/20 border-gray-700 text-gray-100 focus:ring-gray-600"
                        />
                      </div>
                    </div>
                    <div class="grid sm:grid-cols-2  gap-4 lg:gap-6">
                      <div>
                        <label
                          for="hs-work-email-hire-us-1"
                          class="block mb-2 text-sm  font-medium text-white"
                        >
                          Email address
                        </label>
                        <input
                          type="email"
                          onChange={(e) =>
                            setCandidateEmail(e.currentTarget.value)
                          }
                          name="candidate_email"
                          id="hs-work-email-hire-us-1"
                          autocomplete="email"
                          class="py-3 px-4 block w-full  rounded-lg text-sm focus:border-orange-500  disabled:opacity-50 disabled:pointer-events-none bg-slate-900/20 border-gray-700 text-gray-100 focus:ring-gray-600"
                        />
                      </div>
                      <div>
                        <label
                          for="hs-work-email-hire-us-1"
                          class="block mb-2 text-sm  font-medium text-white"
                        >
                          Contact number
                        </label>
                        <input
                          type="tel"
                          onChange={(e) =>
                            setCandidateContactNumber(e.currentTarget.value)
                          }
                          name="candidate_contact_number"
                          id="hs-work-email-hire-us-1"
                          class="py-3 px-4 block w-full  rounded-lg text-sm focus:border-orange-500  disabled:opacity-50 disabled:pointer-events-none bg-slate-900/20 border-gray-700 text-gray-100 focus:ring-gray-600"
                        />
                      </div>
                    </div>

                    <div class="grid sm:grid-cols-2 gap-4 lg:gap-6">
                      <div>
                        <label
                          for="hs-company-website-hire-us-1"
                          class="block mb-2 text-sm  font-medium text-white"
                        >
                          Linkedin profile URL
                        </label>
                        <input
                          type="text"
                          onChange={(e) =>
                            setCandidateLinkedin(e.currentTarget.value)
                          }
                          name="candidate_linkedin"
                          id="hs-company-website-hire-us-1"
                          class="py-3 px-4 block w-full  rounded-lg text-sm focus:border-orange-500  disabled:opacity-50 disabled:pointer-events-none bg-slate-900/20 border-gray-700 text-gray-100 focus:ring-gray-600"
                        />
                      </div>
                      <div>
                        <label
                          for="hs-company-website-hire-us-1"
                          class="block mb-2 text-sm  font-medium text-white"
                        >
                          Country
                        </label>
                        <input
                          type="text"
                          onChange={(e) => setCountry(e.currentTarget.value)}
                          name="candidate_country"
                          id="hs-company-website-hire-us-1"
                          class="py-3 px-4 block w-full  rounded-lg text-sm focus:border-orange-500  disabled:opacity-50 disabled:pointer-events-none bg-slate-900/20 border-gray-700 text-gray-100 focus:ring-gray-600"
                        />
                      </div>
                    </div>

                    <div>
                      <label
                        for="hs-about-hire-us-1"
                        class="block mb-2 text-sm  font-medium text-white"
                      >
                        Comment
                      </label>
                      <textarea
                        id="hs-about-hire-us-1"
                        onChange={(e) => setComment(e.currentTarget.value)}
                        name="comment"
                        rows="4"
                        class="py-3 px-4 block w-full  rounded-lg text-sm focus:border-orange-500  disabled:opacity-50 disabled:pointer-events-none bg-slate-900/20 border-gray-700 text-gray-100 focus:ring-gray-600"
                      ></textarea>
                    </div>
                  </div>

                  <div class="mt-6 grid">
                    <button
                      type="submit"
                      disabled={!fileName}
                      class="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-orange-600 text-white hover:bg-orange-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                    >
                      {!load && (
                        <svg
                          aria-hidden="true"
                          role="status"
                          class="inline w-8 h-8 me-3 text-white animate-spin"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="#E5E7EB"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentColor"
                          />
                        </svg>
                      )}
                      {load && <span>Submit</span>}
                    </button>
                  </div>
                </form>

                <div class="mt-3 text-center">
                  <p class="text-sm text-gray-500">
                    We'll get back to you in 1-2 business days.
                  </p>
                </div>

                <h1 className="text-gray-200 text-center mt-4">
                  By registering, you confirm that you agree to the processing
                  of your personal data as described in our{" "}
                  <a
                    className=" text-blue-500 hover:text-blue-800"
                    href="/privacy-policy"
                  >
                    Privacy Policy
                  </a>
                </h1>
              </div>
            </FadeIn>
          </FadeInStagger>
        </div>
      </div>
    </div>
  );
}

export default JoinUsForm;
