import { Container } from "./Container";
import { FadeIn } from "./FadeIn";
import { Offices } from "./Offices";

export function ContactSection() {
  return (
    <div className=" relative">
      <div>
        <FadeIn
          className=" 
       z-50  
      "
        >
          <div class="max-w-[85rem] px-4 lg:px-8  z-auto mx-auto border rounded-lg mt-6 bg-gradient-to-b shadow-lg shadow-indigo-500/50 drop-shadow-2xl from-indigo-500/20 ">
            <div className=" py-16 ">
              <h2 className=" text-3xl sm:text-4xl md:text-6xl font-medium  [text-wrap:balance]  bg-clip-text py-2 text-transparent bg-gradient-to-r from-orange-500 to-violet-600">
                Tell us about your project
              </h2>
              <div className="mt-6 flex">
                <a
                  href="/contact"
                  className="inline-flex rounded-full px-4 py-1.5 text-sm font-semibold transition bg-white text-neutral-950 hover:bg-neutral-200"
                >
                  Say Hey !
                </a>
              </div>
              <div className="mt-10 border-t border-white/10 pt-10">
                <h3 className=" text-base font-semibold text-white">
                  Office Addresses
                </h3>
                <Offices
                  invert
                  className="mt-6 grid grid-cols-1 gap-8 sm:grid-cols-2"
                />
              </div>
            </div>
          </div>
        </FadeIn>
      </div>
    </div>
  );
}
