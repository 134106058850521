import React from "react";
import { Link } from "react-router-dom";

function Openning() {
  return (
    <div className="min-h-[calc(100vh-4rem)] flex justify-center items-center bg-gray-300">
      <div className="grid md:grid-cols-2 gap-6">
        <Link
          to="/create-live-position"
          className="w-96 xl:h-96 h-32  rounded-xl text-center flex justify-center items-center text-3xl  hover:underline cursor-pointer text-white bg-orange-600"
        >
          New job
        </Link>
        <Link
          to={"/live-position"}
          className="w-96 xl:h-96 h-32  text-center rounded-xl flex justify-center items-center text-3xl hover:underline cursor-pointer text-white bg-orange-600"
        >
          Live Position
        </Link>
      </div>
    </div>
  );
}

export default Openning;
