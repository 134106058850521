import React, { useRef } from "react";
import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import {
  ArrowPathIcon,
  ChevronRightIcon,
  CloudArrowUpIcon,
  LockClosedIcon,
  ServerIcon,
} from "@heroicons/react/20/solid";
import { useEffect } from "react";
import { FadeIn, FadeInStagger } from "../utils/FadeIn";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { setOpen } from "../../redux/utils";
import ButtonExpertise from "./button-expertise";

const Logo = () => (
  <svg
    className="size-10  transition-all duration-500 ease-in-out fill-slate-100 hover:fill-orange-600"
    viewBox="0 0 81 90"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.15924 18.8315L17.0255 27.8804L42.0478 14.1848L64.2325 27.8804L75.3248 18.8315L42.0478 0L5.15924 18.8315Z" />
    <path d="M0 27.8804L12.6401 36.6848V60.8967L42.0478 76.7935L81 53.0707V66.2772L42.0478 90L0 66.2772V27.8804Z" />
    <path d="M22.7006 55.0272V43.0435L42.0478 55.0272L81 29.5924V40.3533L42.0478 66.2772L22.7006 55.0272Z" />
    <path d="M25.7962 32.7717L42.0478 23.7228L55.9777 32.7717L42.0478 43.0435L25.7962 32.7717Z" />
  </svg>
);

function Header() {
  const dispatch = useDispatch();

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigation = [
    { name: "Live positions", href: "/find-a-job", popover: false },
    { name: "About us", href: "/about-us", popover: false },
    { name: "Insights", href: "/insights", popover: false },
    { name: "Our expertise", href: "/our-expertise", popover: true },
    { name: "Contact", href: "/contact", popover: false },
  ];

  const refE = useRef(null);
  const refS = useRef(null);

  useEffect(() => {
    let i1 = null;
    let i2 = null;

    if (refE.current && refS.current) {
      i1 = setInterval(() => {
        refE.current.classList.toggle("text-orange-500");
      }, 5000);
      i2 = setInterval(() => {
        refS.current.classList.toggle("text-orange-500");
      }, 10000);
    }

    return () => {
      clearInterval(i1);
      clearInterval(i2);
    };
  }, []);

  return (
    <header className="absolute   inset-x-0 top-0 z-[1000]">
      <nav
        className=" items-center w-full grid grid-cols-2 lg:grid-cols-12  b bg-neutral-950/70  lg:bg-neutral-950/0  justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <Link
          to="/"
          className=" text-orange-600 lg:col-span-2  inline-flex space-x-2 px-2 items-center "
        >
          <Logo />
          <h1 className="hidden md:inline-flex space-x-2 ">
            <div className="   text-sm font-bold ">eMagine</div>
            <div className="  text-white text-sm  font-bold "> Solutions</div>
          </h1>
        </Link>
        <div className="flex lg:hidden  justify-end items-center   ">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
            onClick={() => dispatch(setOpen())}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-10 w-10" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex col-span-9 text-sm xl:text-base font-semibold  items-center  justify-end  ">
          {navigation.map((item) => (
            <div className=" md:px-2.5 px-1 xl:px-5  ">
              {!item.popover && (
                <Link
                  key={item.name}
                  to={item.href}
                  className=" z-50  whitespace-nowrap uppercase hover:text-orange-500    text-white"
                >
                  {item.name}
                </Link>
              )}
              {item.popover && <ButtonExpertise name={item.name} />}
            </div>
          ))}
          <Link
            to="/cv-drop"
            class="relative w-40 text-center border rounded-lg border-orange-600 bg-orange-600 text-white shadow-2xl transition-all before:absolute before:left-0 before:right-0 before:top-0 before:h-0 before:w-full before:bg-white before:duration-500 after:absolute after:bottom-0 after:left-0 after:right-0 after:h-0 after:w-full after:bg-white after:duration-500 hover:text-orange-600 hover:shadow-white hover:before:h-2/4 hover:after:h-2/4"
          >
            <span class="relative whitespace-nowrap z-10">CV DROP</span>
          </Link>
        </div>
        <div className="hidden  lg:flex col-span-1  lg:justify-end">
          <motion.a
            role="button"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={() => dispatch(setOpen())}
            transition={{ type: "spring", stiffness: 400, damping: 17 }}
            className="w-16 h-16 relative"
          >
            <div className="w-16 h-16 flex justify-center items-center  bg-orange-600 rounded-full">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-11 h-10 text-white "
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25"
                />
              </svg>
            </div>
          </motion.a>
        </div>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden "
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-50" />
        <Dialog.Panel className="fixed inset-y-0 bg-black right-0 z-[1000000000000] w-full overflow-y-auto  px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link
              to="/"
              className="-m-1.5 p-1.5 text-white  text-lg font-extrabold"
            >
              <span className="sr-only">Your Company</span>
              <span className=" transition-all text-red-500 ">
                eMagine
              </span>{" "}
              <span className="transition-all"> Solutions</span>
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-white"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <FadeInStagger className="my-6 divide-y  divide-gray-500/10">
              <FadeIn className="space-y-10 py-6 flex flex-col justify-center items-center">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className="-mx-3 block rounded-lg px-3 py-2 text-3xl font-semibold leading-7 text-white hover:border-white"
                  >
                    {item.name}
                  </Link>
                ))}
              </FadeIn>
              {/*  <FadeIn className="py-6">
                <a
                  href="#"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-3xl  font-semibold leading-7 text-white hover:bg-gray-50"
                >
                  Log in
                </a>
              </FadeIn> */}
            </FadeInStagger>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}

export default Header;
