// ... autres importations nécessaires

import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { setOpenDi, setUrl } from "../redux/utils";
import { db } from "../firebase";

const useExpertiseEditor = () => {
  const editorRef = useRef(null);
  const textCol = useRef(null);
  const [textColor, setTextColor] = useState("#000000");
  const [contentHtml, setContentHtml] = useState("");
  const [load, setLoad] = useState(true);
  const [showToast, setShowToast] = useState(false);
  const dispatch = useDispatch();
  const { url, urlEditor } = useSelector((state) => state.utilsSlice);
  const [positionSize, setPositionSize] = useState(0);
  const [searchParams] = useSearchParams();
  const [jsxCode, setJsxCode] = useState("");
  const [showCodeInput, setShowCodeInput] = useState(false);
  const [previewContent, setPreviewContent] = useState("");
  const [rows, setRows] = useState(3);
  const [columns, setColumns] = useState(3);
  const [template, setTemplate] = useState("template1");
  const [activeCell, setActiveCell] = useState(null);
  const [showTextStyleDropdown, setShowTextStyleDropdown] = useState(false);
  const [showTextStyleDropdownToolTip, setShowTextStyleDropdownToolTip] =
    useState(false);

  const tableSize = [
    "text-sm",
    "text-lg",
    "text-xl",
    "text-2xl",
    "text-3xl",
    "text-4xl",
    "text-5xl",
    "text-6xl",
    "text-7xl",
  ];

  useEffect(() => {
    const id = searchParams.get("id");
    const fetchContent = async () => {
      try {
        const docRef = doc(db, "expertises", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          if (docSnap.data().content) {
            editorRef.current.innerHTML = docSnap.data().content;
          }
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching document: ", error);
      }
    };
    fetchContent();
  }, [searchParams]);

  const applyTextStyle = (style) => {
    const selection = window.getSelection();
    if (!selection.rangeCount) return;

    const range = selection.getRangeAt(0);
    let selectedNode = selection.anchorNode;

    while (selectedNode && selectedNode.nodeType !== Node.ELEMENT_NODE) {
      selectedNode = selectedNode.parentNode;
    }

    if (selectedNode) {
      selectedNode.classList.remove(
        "text-4xl",
        "text-2xl",
        "text-lg",
        "text-base",
        "md:text-4xl",
        "sm:text-2xl",
        "text-xl",
        "md:text-2xl",
        "sm:text-xl",
        "text-lg",
        "md:text-base",
        "text-sm",
        "font-bold",
        "font-semibold",
        "font-medium",
        "bg-clip-text",
        "block",
        "py-2",
        "text-transparent",
        "bg-gradient-to-r",
        "from-orange-500",
        "to-violet-600"
      );

      switch (style) {
        case "title":
          selectedNode.classList.remove("text-white");
          selectedNode.classList.add(
            "md:text-4xl",
            "sm:text-2xl",
            "text-xl",
            "font-bold",
            "bg-clip-text",
            "block",
            "py-2",
            "text-transparent",
            "bg-gradient-to-r",
            "from-orange-500",
            "to-violet-600"
          );

          break;
        case "subtitle":
          selectedNode.classList.add(
            "md:text-2xl",
            "sm:text-xl",
            "text-lg",
            "font-semibold"
          );

          break;
        case "body":
          selectedNode.classList.add("md:text-base", "text-sm", "font-medium");
          break;
        default:
          break;
      }

      setShowTextStyleDropdown(false);
    }
  };

  useEffect(() => {
    const insertImageAtCursor = () => {
      const editor = editorRef.current;
      if (!editor || !url) return;

      // Création d'un div conteneur pour l'image avec des espaces en haut et en bas
      const imageWrapper = document.createElement("div");
      imageWrapper.className =
        "relative bg-gray-800/30 w-full h-[400px] p-2 flex justify-center shadow-sm rounded-xl my-4";
      imageWrapper.contentEditable = "false"; // Rendre le conteneur non éditable

      const img = document.createElement("img");
      img.src = url;
      img.className = "h-full object-cover rounded-xl";
      img.onload = () => {
        imageWrapper.appendChild(img);
        setContentHtml(editor.innerHTML);
      };
      img.onerror = () => {
        console.error("Failed to load the image");
      };

      const removeButton = document.createElement("button");
      removeButton.innerHTML = "&times;";
      removeButton.className =
        "absolute top-2 right-2 bg-red-500 text-white rounded-full p-1 remove-button editor-control";
      removeButton.onclick = () => {
        imageWrapper.remove();
        setContentHtml(editor.innerHTML);
      };
      imageWrapper.appendChild(removeButton);

      editor.appendChild(imageWrapper);

      // Ajout d'un espace après l'image pour permettre l'écriture
      const spaceAfterImage = document.createElement("div");
      spaceAfterImage.innerHTML = "<br><br>";
      editor.appendChild(spaceAfterImage);

      // Ajout d'un espace avant l'image pour permettre l'écriture
      const spaceBeforeImage = document.createElement("div");
      spaceBeforeImage.innerHTML = "<br><br>";
      editor.insertBefore(spaceBeforeImage, imageWrapper);
    };

    insertImageAtCursor();

    return () => dispatch(setUrl(""));
  }, [url, dispatch]);

  const handleContentChange = () => {
    setContentHtml(editorRef.current.innerHTML);
  };

  useEffect(() => {
    const editor = editorRef.current;
    editor.addEventListener("input", handleContentChange);

    return () => {
      editor.removeEventListener("input", handleContentChange);
    };
  }, []);

  const handlePublish = async () => {
    try {
      setLoad(false);
      const id = searchParams.get("id");
      const docRef = doc(db, "expertises", id);

      // Cloner l'éditeur pour manipuler le contenu sans modifier le DOM directement
      const editorClone = editorRef.current.cloneNode(true);

      // Supprimer les boutons et les éléments non désirés
      const removeElements = editorClone.querySelectorAll(
        ".insert-image-button, .delete-image-button, .delete-table-button, .editor-control"
      );
      removeElements.forEach((element) => element.remove());

      // Rendre les zones de texte non éditables
      const contentElements = editorClone.querySelectorAll(
        "[contenteditable='true']"
      );
      contentElements.forEach((element) =>
        element.removeAttribute("contenteditable")
      );

      // Utiliser le contenu nettoyé pour la publication
      const cleanedContent = editorClone.innerHTML;

      await updateDoc(docRef, {
        content: cleanedContent,
      }).then(() => {
        setLoad(true);
        setShowToast(true);
        setTimeout(() => {
          window.history.back();
        }, 4000);
      });
    } catch (error) {
      console.error("Error publishing document: ", error);
    }
  };

  const addLink = () => {
    const url = prompt("Entrez l'URL du lien:");
    if (url) {
      document.execCommand("createLink", false, url);
    }
  };

  const applyStyle = (style) => {
    const selectedText = window.getSelection();
    if (!selectedText.rangeCount) return;

    const range = selectedText.getRangeAt(0);
    const selectedTextContent = range.extractContents();

    const span = document.createElement("span");
    switch (style) {
      case "bold":
        span.className = "font-bold";
        break;
      case "normal":
        span.className = "font-normal";
        break;
      case "italic":
        span.className = "italic";
        break;
      case "not-italic":
        span.className = "not-italic";
        break;
      default:
        break;
    }

    span.appendChild(selectedTextContent);
    range.insertNode(span);

    const newRange = document.createRange();
    newRange.setStartAfter(span);
    newRange.collapse(true);
    selectedText.removeAllRanges();
    selectedText.addRange(newRange);
  };

  const applyStylesToLists = (listType) => {
    const lists = editorRef.current.querySelectorAll("ul, ol");

    lists.forEach((list) => {
      // Ajoute une classe Tailwind CSS aux listes
      listType === "insertOrderedList"
        ? list.classList.add("list-decimal", "pl-10", "text-white")
        : list.classList.add("list-disc", "pl-10", "text-white");
    });
  };

  const createList = (ordered) => {
    const listType = ordered ? "insertOrderedList" : "insertUnorderedList";
    document.execCommand(listType);

    setTimeout(() => applyStylesToLists(listType), 10);
  };

  const showTooltip = () => {
    const selection = window.getSelection();
    if (!selection.rangeCount) return;

    const range = selection.getRangeAt(0);
    const rect = range.getBoundingClientRect();
    const tooltip = document.getElementById("tooltip");

    tooltip.style.top = `${rect.top - tooltip.offsetHeight}px`;
    tooltip.style.left = `${rect.left}px`;
    tooltip.classList.remove("hidden");
    tooltip.classList.add("flex", "gap-2", "item-center");
  };

  const hideTooltip = () => {
    const tooltip = document.getElementById("tooltip");
    tooltip.classList.add("hidden");
    tooltip.classList.remove("flex");
  };

  useEffect(() => {
    const editor = editorRef.current;
    const handleMouseUp = () => {
      const selection = window.getSelection();
      if (selection.isCollapsed) {
        hideTooltip();
      } else {
        showTooltip();
      }
    };

    editor.addEventListener("mouseup", handleMouseUp);

    return () => {
      editor.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  const setTextAlignment = (alignment) => {
    const selection = window.getSelection();
    if (!selection.rangeCount) return;

    let anchorNode = selection.anchorNode;
    while (
      anchorNode &&
      !(
        anchorNode instanceof HTMLElement &&
        anchorNode.nodeType === Node.ELEMENT_NODE &&
        getComputedStyle(anchorNode).display === "block"
      )
    ) {
      anchorNode = anchorNode.parentNode;
    }

    if (anchorNode) {
      anchorNode.classList.remove(
        "text-left",
        "text-center",
        "text-right",
        "text-justify"
      );
      anchorNode.classList.add(`text-${alignment}`);
    }
  };

  const applyTextColor = (color) => {
    document.execCommand("styleWithCSS", true);
    document.execCommand("foreColor", false, color);
  };

  const handleColorChange = (event) => {
    const color = event.target.value;
    setTextColor(color);
    applyTextColor(color);
  };

  const insertTable = (rows, columns, template) => {
    const editor = editorRef.current;
    if (!editor) return;
    const responsiveClasses = {
      1: "grid-cols-1",
      2: "grid-cols-1 sm:grid-cols-2",
      3: "grid-cols-1 sm:grid-cols-2 md:grid-cols-3",
      4: "grid-cols-1 sm:grid-cols-2 md:grid-cols-4",
      5: "grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5",
      6: "grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6",
    };

    const tableWrapper = document.createElement("div");
    tableWrapper.className = `grid ${
      responsiveClasses[columns] || "grid-cols-1"
    } gap-4 p-4 my-4 relative editor-table`;

    const templates = {
      template1: `
      <div class="shadow-md gap-6 rounded-lg bg-gradient-to-tl size-full from-indigo-600/25 p-4 flex flex-col justify-center items-center editor-cell">
        <button class="insert-image-button flex justify-center p-2 bg-indigo-600 text-xs mt-2 rounded-lg text-indigo-50">
          Insert Image
        </button>
        <div class="relative size-14 image-container hidden">
          <button class="absolute right-0 transform -translate-y-4 translate-x-4 delete-image-button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              class="w-5 h-5 text-red-600"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          </button>
          <img
            src=""
            alt=""
            class="w-full rounded-lg object-cover"
          />
        </div>
        <div class="col-span-6 editor-content" contenteditable="true">
          <h1 class="text-gray-200 text-sm font-medium">
            Enter your title here...
          </h1>
        </div>
      </div>
    `,
      template2: `
      <div class="shadow-md gap-4 rounded-lg bg-gradient-to-tl size-full from-indigo-600/25  p-4 grid grid-cols-8 editor-cell">
        <div class="col-span-2 flex justify-center flex-col items-center">
          <div class="size-14 relative image-container hidden">
            <button class="absolute right-0 transform -translate-y-3 translate-x-3 delete-image-button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                class="size-5 text-red-600"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </button>
            <img
              src=""
              alt=""
              class="w-full rounded-lg object-cover"
            />
          </div>
          <button class="insert-image-button flex justify-center p-2 bg-indigo-600 text-xs mt-2 rounded-lg text-indigo-50">
            Insert Image
          </button>
        </div>
        <div class="col-span-6 editor-content" contenteditable="true">
          <h1 class="text-gray-100 text-lg font-bold">
            Enter your title here...
          </h1>
          <p class="mt-5 text-gray-400 text-sm">
            Enter your paragraph here...
          </p>
        </div>
      </div>
    `,
    };

    const cellTemplate = templates[template];

    for (let i = 0; i < rows * columns; i++) {
      const cell = document.createElement("div");
      cell.className = "editor-cell relative"; // Optional: Add any additional classes if needed
      cell.innerHTML = cellTemplate;
      tableWrapper.appendChild(cell);
    }

    // Ajouter un bouton pour supprimer le tableau
    const deleteTableButton = document.createElement("button");
    deleteTableButton.innerText = "Delete Table";
    deleteTableButton.className =
      "absolute top-2 right-2 bg-red-500 text-white p-1 rounded editor-control"; // Add class editor-control
    deleteTableButton.contentEditable = "false"; // Make button non-editable
    deleteTableButton.onclick = () => {
      tableWrapper.remove();
    };
    tableWrapper.appendChild(deleteTableButton);

    editor.appendChild(tableWrapper);
    setContentHtml(editor.innerHTML);
  };

  useEffect(() => {
    const handleInsertImage = (event) => {
      const button = event.target.closest(".insert-image-button");
      if (!button) return;

      const cell = button.closest(".editor-cell");
      setActiveCell(cell);
      dispatch(setOpenDi());
    };

    const handleDeleteImage = (event) => {
      const button = event.target.closest(".delete-image-button");
      if (!button) return;

      const cell = button.closest(".editor-cell");
      const imgContainer = cell.querySelector(".image-container");
      imgContainer.classList.add("hidden");
      imgContainer.querySelector("img").src = "";
      setContentHtml(editorRef.current.innerHTML);
    };

    const editor = editorRef.current;
    editor.addEventListener("click", handleInsertImage);
    editor.addEventListener("click", handleDeleteImage);

    return () => {
      editor.removeEventListener("click", handleInsertImage);
      editor.removeEventListener("click", handleDeleteImage);
    };
  }, [dispatch]);

  const cleanStyles = () => {
    const editor = editorRef.current;
    const elements = editor.querySelectorAll("*");

    elements.forEach((element) => {
      element.style.background = "";
      element.style.fontSize = "";
      element.style.fontFamily = "";
    });
  };

  // Ajoutez un écouteur d'événements pour l'éditeur
  useEffect(() => {
    const editor = editorRef.current;
    const handleInput = () => {
      cleanStyles();
    };

    editor.addEventListener("input", handleInput);

    return () => {
      editor.removeEventListener("input", handleInput);
    };
  }, []);

  useEffect(() => {
    const editor = editorRef.current;
    const handleBlur = () => {
      cleanStyles();
    };

    editor.addEventListener("blur", handleBlur, true); // Utiliser la phase de capture

    return () => {
      editor.removeEventListener("blur", handleBlur, true);
    };
  }, []);

  useEffect(() => {
    if (urlEditor && activeCell) {
      const imgContainer = activeCell.querySelector(".image-container");
      const img = imgContainer.querySelector("img");
      img.src = urlEditor;
      imgContainer.classList.remove("hidden");

      setActiveCell(null);
      dispatch(setUrl(""));
    }
  }, [urlEditor, activeCell, dispatch]);

  const updateExistingCells = () => {
    const editor = editorRef.current;
    const cells = editor.querySelectorAll("div[contenteditable='true']");
    cells.forEach((cell) => {
      cell.classList.add("text-white");
    });
  };

  // Appelez cette fonction après avoir inséré un tableau ou chargé du contenu existant
  useEffect(() => {
    updateExistingCells();
  }, [contentHtml]);

  const handleJsxSubmit = () => {
    try {
      const editor = editorRef.current;
      if (!editor) return;

      const codeDiv = document.createElement("div");
      codeDiv.className = "jsx-code  p-2 ";
      codeDiv.contentEditable = "true";
      codeDiv.innerHTML = previewContent;
      editor.appendChild(codeDiv);

      setJsxCode("");
      setShowCodeInput(false);
    } catch (error) {
      console.error("Invalid JSX code:", error);
    }
  };

  const handleJsxPreview = () => {
    setPreviewContent(jsxCode);
  };

  const handleEditorChange = (value) => {
    setJsxCode(value);
  };

  const handleInsertTable = () => {
    insertTable(rows, columns, template);
  };

  return {
    editorRef,
    textCol,
    textColor,
    contentHtml,
    load,
    showToast,
    jsxCode,
    showCodeInput,
    previewContent,
    tableSize,
    setTextColor,
    setLoad,
    setShowToast,
    setJsxCode,
    setShowCodeInput,
    setPreviewContent,
    setPositionSize,
    handlePublish,
    addLink,
    applyStyle,
    createList,
    setTextAlignment,
    handleColorChange,
    handleJsxPreview,
    handleJsxSubmit,
    handleEditorChange,
    dispatch,
    setOpenDi,
    handleContentChange,
    handleInsertTable,
    setRows,
    setColumns,
    rows,
    columns,
    setShowTextStyleDropdown,
    showTextStyleDropdown,
    applyTextStyle,
    showTextStyleDropdownToolTip,
    setShowTextStyleDropdownToolTip,
    setTemplate,
  };
};

export default useExpertiseEditor;
