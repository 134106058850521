"use client";

import { Button, Modal, Spinner } from "flowbite-react";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCloseDi, setOpenDi, setUrl } from "../../redux/utils";
import {
  deleteObject,
  getDownloadURL,
  listAll,
  ref,
  uploadBytes,
} from "firebase/storage";
import { db, storage } from "../../firebase";
import {
  CalendarIcon,
  ChartPieIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import {
  addDoc,
  collection,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { Link, useSearchParams } from "react-router-dom";

const navigation = [
  { name: "All", href: "all", icon: HomeIcon },
  { name: "Icons", href: "icons", icon: UsersIcon },
  {
    name: "Brochures",
    href: "brochures",
    icon: FolderIcon,
   
  },
  {
    name: "Blog",
    href: "blog",
    icon: () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="size-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 0 1 .865-.501 48.172 48.172 0 0 0 3.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z"
        />
      </svg>
    ),
   
  },
  {
    name: "Expertise",
    href: "expertise",
    icon: () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="size-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
        />
      </svg>
    ),
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function ModalImage() {
  const { openDi, url } = useSelector((state) => state.utilsSlice);
  const dispatch = useDispatch();
  const fileRef = useRef();
  const [load, setLoad] = useState(true);
  const [images, setImages] = useState([]);
  const [selectedNav, setSelectedNav] = useState("all");

  const handleChangeImage = async () => {
    const files = fileRef.current.files;

    if (files.length === 0) {
      return;
    }

    setLoad(true);

    const uploadPromises = Array.from(files).map(async (file) => {
      const storageRef = ref(storage, `images/${file.name}`);
      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(storageRef);

      if (selectedNav !== "all") {
        await addDoc(collection(db, "images"), {
          url,
          type: selectedNav,
        });
      }
    });

    try {
      await Promise.all(uploadPromises);
      if (selectedNav === "all") {
        fetchData();
      } else {
        f();
      }
    } catch (error) {
      console.error("Error uploading files: ", error);
    } finally {
      setLoad(false);
    }
  };

  const fetchData = async () => {
    const listRef = ref(storage, "images");
    setLoad(false);
    await listAll(listRef)
      .then(async (data) => {
        const urls = await Promise.all(
          data.items.map(async (item) => {
            // For each item (file reference), fetch the download URL
            let url = await getDownloadURL(item);

            return {
              ref: item,
              url,
            };
          })
        );

        // Now, 'urls' is an array of download URLs for all files in the reference
        setImages(urls); // Assuming you want to store these URLs in your state
      })
      .then(() => setLoad(true));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDeleteDoc = async (ref) => {
    const result = window.confirm(
      "By confirming the delete the image will permanently removed !"
    );
    if (result) {
      await deleteObject(ref).then(async () => await fetchData());
    }
  };

  const handleMoveTo = async (url, type) => {
    try {
      setLoad(false);
      const imageRef = collection(db, "images");

      // Create a query against the collection.
      const q = query(imageRef, where("url", "==", url));

      const querySnapshot = await getDocs(q);

      if (querySnapshot.size > 0) {
        querySnapshot.forEach(async (doc) => {
          await updateDoc(doc.ref, {
            type,
          }); /* .then(() => setSelectedNav(type)); */
        });
      } else {
        await addDoc(imageRef, {
          url,
          type,
        }).then(() => {
          setSelectedNav(type);
        });
      }

      setLoad(true);
    } catch (e) {
      setLoad(true);
      throw e;
    }
  };

  const handleChange = (ref, e, url) => {
    e.stopPropagation();
    const value = e.target.value;

    if (value === "delete") {
      handleDeleteDoc(ref);
    } else {
      handleMoveTo(url, value);
    }
  };

  const f = async () => {
    setLoad(false);
    const imageRef = collection(db, "images");
    const q = query(imageRef, where("type", "==", selectedNav));
    const querySnapshot = await getDocs(q);

    const allImages = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      url: doc.data().url,
    }));

    setImages(allImages);
    setLoad(true);
  };

  useEffect(() => {
    if (selectedNav !== "all") {
      f();
    } else {
      fetchData();
    }
  }, [selectedNav]);

  console.log(images);

  return (
    <>
      <Modal
        className=" z-[100000000000000000000]"
        size={"3xl"}
        show={openDi}
        onClose={() => dispatch(setCloseDi())}
      >
        <div className="text-white ps-7 py-4 bg-orange-600">
          <h1 className="text-2xl relative font-medium ">
            Images
            <svg
              onClick={() => dispatch(setCloseDi())}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-8 h-8 right-4 top-0 absolute cursor-pointer"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          </h1>
        </div>

        <div className="p-4 relative overflow-hidden bg-neutral-800">
          {!load && (
            <div className="absolute flex inset-0 justify-center items-center  bg-gray-400/[80%] ">
              <Spinner
                size={"xl"}
                color={"purple"}
                aria-label="Default status example"
              />
            </div>
          )}

          <div className=" grid md:grid-cols-12 gap-4">
            <div className=" md:col-span-4">
              <nav className="flex flex-1 flex-col" aria-label="Sidebar">
                <ul role="list" className="-mx-2 space-y-1">
                  {navigation.map((item) => (
                    <li key={item.name}>
                      <div
                        onClick={() => setSelectedNav(item.href)}
                        className={classNames(
                          item.href === selectedNav
                            ? "bg-gray-50 text-indigo-600"
                            : "text-gray-300 hover:bg-gray-50 hover:text-indigo-600",
                          "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                        )}
                      >
                        <item.icon
                          className={classNames(
                            item.href === selectedNav
                              ? "text-indigo-600"
                              : "text-gray-400 group-hover:text-indigo-600",
                            "h-6 w-6 shrink-0"
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                        {item.count ? (
                          <span
                            className="ml-auto w-9 min-w-max whitespace-nowrap rounded-full bg-white px-2.5 py-0.5 text-center text-xs font-medium leading-5 text-gray-600 ring-1 ring-inset ring-gray-200"
                            aria-hidden="true"
                          >
                            {item.count}
                          </span>
                        ) : null}
                      </div>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>

            <div className={`${!load && "blur-lg"} md:col-span-8 `}>
              <div className="flex w-full justify-end items-center ">
                <div
                  onClick={() => fileRef.current.click()}
                  className="cursor-pointer w-fit bg-sky-800 text-white  p-4 border space-x-2  flex justify-end items-center  rounded-lg "
                >
                  <input
                    type="file"
                    onChange={handleChangeImage}
                    ref={fileRef}
                    multiple="true"
                    className="hidden"
                    id=""
                  />

                  <span className=" capitalize">
                    Upload image{" "}
                    {selectedNav !== "all" ? `for ${selectedNav}` : ""}
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 animate-pulse"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                    />
                  </svg>
                </div>
              </div>
              <div className=" grid grid-cols-2 max-h-[30rem] overflow-y-scroll gap-4 mt-4">
                {images &&
                  images.map((img) => (
                    <div
                      onClick={() => {
                        dispatch(setUrl(img.url));
                        dispatch(setCloseDi());
                      }}
                      className="border relative  p-4 pb-12  flex flex-col rounded-lg  "
                    >
                      <div className=" absolute inset-0 flex justify-center items-end">
                        <div>
                          <select
                            onClick={(e) => e.stopPropagation()}
                            id="option"
                            name="option"
                            onChange={(e) => handleChange(img.ref, e, img.url)}
                            className="mt-2 block bg-transparent w-full text-center my-2  rounded-md border-0 py-1.5 pl-3 pr-10 text-indigo-400 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            defaultValue="Option"
                          >
                            <option selected disabled>
                              Option
                            </option>
                            <option value={"icons"}>Move to Icons</option>
                            <option value={"brochures"}>
                              Move to Brochures
                            </option>
                            <option value={"blog"}>Move to Blog</option>
                            <option value={"expertise"}>
                              Move to Expertise
                            </option>
                            <option
                              value={"delete"}
                              className="text-red-600"
                              /*     onClick={(e) => handleDeleteDoc(img.ref, e)} */
                            >
                              Delete
                            </option>
                          </select>
                        </div>
                      </div>

                      <img
                        className="size-full flex-shrink  rounded-lg object-contain cursor-pointer"
                        src={img.url}
                        alt=""
                        loading="lazy"
                        srcset=""
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
