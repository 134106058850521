// ... autres importations nécessaires

import { addDoc, collection, doc, getDoc, updateDoc } from "firebase/firestore";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { setOpenDi, setType, setUrl } from "../redux/utils";
import { db } from "../firebase";
import moment from "moment";

const useBlogEditor = () => {
  const editorRef = useRef(null);
  const textCol = useRef(null);
  const [textColor, setTextColor] = useState("#000000");
  const [contentHtml, setContentHtml] = useState("");
  const [load, setLoad] = useState(true);
  const [showToast, setShowToast] = useState(false);
  const dispatch = useDispatch();
  const { url, urlEditor, urlBg } = useSelector((state) => state.utilsSlice);
  const [positionSize, setPositionSize] = useState(0);
  const [searchParams] = useSearchParams();
  const [jsxCode, setJsxCode] = useState("");
  const [showCodeInput, setShowCodeInput] = useState(false);
  const [previewContent, setPreviewContent] = useState("");
  const [rows, setRows] = useState(3);
  const [columns, setColumns] = useState(3);
  const [activeCell, setActiveCell] = useState(null);
  const [showTextStyleDropdown, setShowTextStyleDropdown] = useState(false);
  const [showTextStyleDropdownToolTip, setShowTextStyleDropdownToolTip] =
    useState(false);
  const [titleC, setTitle] = useState("");
  const [authorC, setAuthor] = useState("");
  const [previewDescription, setPreviewDescription] = useState("");

  const tableSize = [
    "text-sm",
    "text-lg",
    "text-xl",
    "text-2xl",
    "text-3xl",
    "text-4xl",
    "text-5xl",
    "text-6xl",
    "text-7xl",
  ];

  useEffect(() => {
    const id = searchParams.get("id");
    const fetchContent = async () => {
      try {
        const docRef = doc(db, "expertises", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          if (docSnap.data().content) {
            editorRef.current.innerHTML = docSnap.data().content;
          }
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching document: ", error);
      }
    };
    fetchContent();
  }, [searchParams]);

  const applyTextStyle = (style) => {
    const selection = window.getSelection();
    if (!selection.rangeCount) return;

    const range = selection.getRangeAt(0);
    let selectedNode = selection.anchorNode;

    while (selectedNode && selectedNode.nodeType !== Node.ELEMENT_NODE) {
      selectedNode = selectedNode.parentNode;
    }

    if (selectedNode) {
      selectedNode.classList.remove(
        "text-4xl",
        "text-2xl",
        "text-lg",
        "text-base",
        "md:text-4xl",
        "sm:text-2xl",
        "text-xl",
        "md:text-2xl",
        "sm:text-xl",
        "text-lg",
        "md:text-base",
        "text-sm",
        "font-bold",
        "font-semibold",
        "font-medium",
        "bg-clip-text",
        "block",
        "py-2",
        "text-transparent",
        "bg-gradient-to-r",
        "from-orange-500",
        "to-violet-600"
      );

      switch (style) {
        case "title":
          selectedNode.classList.remove("text-white");
          selectedNode.classList.add(
            "md:text-4xl",
            "sm:text-2xl",
            "text-xl",
            "font-bold",
            "bg-clip-text",
            "block",
            "py-2",
            "text-transparent",
            "bg-gradient-to-r",
            "from-orange-500",
            "to-violet-600"
          );

          break;
        case "subtitle":
          selectedNode.classList.add(
            "md:text-2xl",
            "sm:text-xl",
            "text-lg",
            "font-semibold"
          );

          break;
        case "body":
          selectedNode.classList.add("md:text-base", "text-sm", "font-medium");
          break;
        default:
          break;
      }

      setShowTextStyleDropdown(false);
    }
  };

  useEffect(() => {
    const insertImageAtCursor = () => {
      const editor = editorRef.current;
      if (!editor || !url) return;

      // Création d'un div conteneur pour l'image avec des espaces en haut et en bas
      const imageWrapper = document.createElement("div");
      imageWrapper.className =
        "relative bg-gray-800/30 w-full h-[400px] p-2 flex justify-center shadow-sm rounded-xl my-4";
      imageWrapper.contentEditable = "false"; // Rendre le conteneur non éditable

      const img = document.createElement("img");
      img.src = url;
      img.className = "h-full object-cover rounded-xl";
      img.onload = () => {
        imageWrapper.appendChild(img);
        setContentHtml(editor.innerHTML);
      };
      img.onerror = () => {
        console.error("Failed to load the image");
      };

      const removeButton = document.createElement("button");
      removeButton.innerHTML = "&times;";
      removeButton.className =
        "absolute top-2 right-2 bg-red-500 text-white rounded-full p-1 remove-button editor-control";
      removeButton.onclick = () => {
        imageWrapper.remove();
        setContentHtml(editor.innerHTML);
      };
      imageWrapper.appendChild(removeButton);

      editor.appendChild(imageWrapper);

      // Ajout d'un espace après l'image pour permettre l'écriture
      const spaceAfterImage = document.createElement("div");
      spaceAfterImage.innerHTML = "<br><br>";
      editor.appendChild(spaceAfterImage);

      // Ajout d'un espace avant l'image pour permettre l'écriture
      const spaceBeforeImage = document.createElement("div");
      spaceBeforeImage.innerHTML = "<br><br>";
      editor.insertBefore(spaceBeforeImage, imageWrapper);
    };

    insertImageAtCursor();

    return () => dispatch(setUrl(""));
  }, [url, dispatch]);

  const handleContentChange = () => {
    setContentHtml(editorRef.current.innerHTML);
  };

  useEffect(() => {
    const editor = editorRef.current;
    editor.addEventListener("input", handleContentChange);

    return () => {
      editor.removeEventListener("input", handleContentChange);
    };
  }, []);

  const handlePublish = async () => {
    // Cloner l'éditeur pour manipuler le contenu sans modifier le DOM directement
    const editorClone = editorRef.current.cloneNode(true);

    // Supprimer les boutons et les éléments non désirés
    const removeElements = editorClone.querySelectorAll(".editor-control");
    removeElements.forEach((element) => element.remove());

    console.log(editorClone);

    const removejsx = editorClone.querySelectorAll(".jsx-code");
    removejsx.forEach((element) => element.remove());

    // Rendre les zones de texte non éditables
    const contentElements = editorClone.querySelectorAll(".editor-content");
    contentElements.forEach((element) =>
      element.removeAttribute("contenteditable")
    );

    const params = {
      title: titleC,
      bg_url: urlBg,
      description: editorClone.innerHTML,
      preview_description: previewDescription,
      creatAt: moment.utc().unix(),
    };

    try {
      setLoad(false);

      if (searchParams.get("id")) {
        const id = searchParams.get("id");
        const docRef = doc(db, "blogs", id);

        await updateDoc(docRef, params).then(() => {
          setLoad(true);
          setShowToast(true);
          setTimeout(() => {
            window.history.back();
          }, 4000);
        });
      } else {
        // Cloner l'éditeur pour manipuler le contenu sans modifier le DOM directement

        const blogger = await addDoc(collection(db, "blogs"), params).then(
          () => {
            setLoad(true);
            setShowToast(true);
            setTimeout(() => {
              window.location.reload();
            }, 4000);
          }
        );
        console.log(blogger.id);
      }
    } catch (error) {
      console.error("erro", error);
    }
  };

  const addLink = () => {
    const url = prompt("Entrez l'URL du lien:");
    if (url) {
      document.execCommand("createLink", false, url);
    }
  };

  const applyStyle = (style) => {
    const selectedText = window.getSelection();
    if (!selectedText.rangeCount) return;

    const range = selectedText.getRangeAt(0);
    const selectedTextContent = range.extractContents();

    const span = document.createElement("span");
    switch (style) {
      case "bold":
        span.className = "font-bold";
        break;
      case "normal":
        span.className = "font-normal";
        break;
      case "italic":
        span.className = "italic";
        break;
      case "not-italic":
        span.className = "not-italic";
        break;
      default:
        break;
    }

    span.appendChild(selectedTextContent);
    range.insertNode(span);

    const newRange = document.createRange();
    newRange.setStartAfter(span);
    newRange.collapse(true);
    selectedText.removeAllRanges();
    selectedText.addRange(newRange);
  };

  const applyStylesToLists = (listType) => {
    const lists = editorRef.current.querySelectorAll("ul, ol");

    lists.forEach((list) => {
      // Ajoute une classe Tailwind CSS aux listes
      listType === "insertOrderedList"
        ? list.classList.add("list-decimal", "pl-10", "text-white")
        : list.classList.add("list-disc", "pl-10", "text-white");
    });
  };

  const handleTab = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();

      const selection = window.getSelection();
      const range = selection.getRangeAt(0);
      const tabNode = document.createTextNode("\u00a0\u00a0\u00a0\u00a0"); // Insère quatre espaces

      range.insertNode(tabNode);

      // Déplace le curseur après les espaces insérés
      range.setStartAfter(tabNode);
      range.setEndAfter(tabNode);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  };

  // Ajoutez un écouteur d'événements pour l'éditeur
  useEffect(() => {
    const editor = editorRef.current;
    editor.addEventListener("keydown", handleTab);

    return () => {
      editor.removeEventListener("keydown", handleTab);
    };
  }, []);

  const createList = (ordered) => {
    const listType = ordered ? "insertOrderedList" : "insertUnorderedList";
    document.execCommand(listType);

    setTimeout(() => applyStylesToLists(listType), 10);
  };

  const showTooltip = () => {
    const selection = window.getSelection();
    if (!selection.rangeCount) return;

    const range = selection.getRangeAt(0);
    const rect = range.getBoundingClientRect();
    const tooltip = document.getElementById("tooltip");

    tooltip.style.top = `${rect.top - tooltip.offsetHeight}px`;
    tooltip.style.left = `${rect.left}px`;
    tooltip.classList.remove("hidden");
    tooltip.classList.add("flex", "gap-2", "item-center");
  };

  const hideTooltip = () => {
    const tooltip = document.getElementById("tooltip");
    tooltip.classList.add("hidden");
    tooltip.classList.remove("flex");
  };

  useEffect(() => {
    const editor = editorRef.current;
    const handleMouseUp = () => {
      const selection = window.getSelection();
      if (selection.isCollapsed) {
        hideTooltip();
      } else {
        showTooltip();
      }
    };

    editor.addEventListener("mouseup", handleMouseUp);

    return () => {
      editor.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  const setTextAlignment = (alignment) => {
    const selection = window.getSelection();
    if (!selection.rangeCount) return;

    let anchorNode = selection.anchorNode;
    while (
      anchorNode &&
      !(
        anchorNode instanceof HTMLElement &&
        anchorNode.nodeType === Node.ELEMENT_NODE &&
        getComputedStyle(anchorNode).display === "block"
      )
    ) {
      anchorNode = anchorNode.parentNode;
    }

    if (anchorNode) {
      anchorNode.classList.remove(
        "text-left",
        "text-center",
        "text-right",
        "text-justify"
      );
      anchorNode.classList.add(`text-${alignment}`);
    }
  };

  const applyTextColor = (color) => {
    document.execCommand("styleWithCSS", true);
    document.execCommand("foreColor", false, color);
  };

  const handleColorChange = (event) => {
    const color = event.target.value;
    setTextColor(color);
    applyTextColor(color);
  };
  const insertTable = (rows, columns) => {
    const editor = editorRef.current;
    if (!editor) return;

    const responsiveClasses = {
      1: "grid-cols-1",
      2: "grid-cols-1 sm:grid-cols-2",
      3: "grid-cols-1 sm:grid-cols-2 md:grid-cols-3",
      4: "grid-cols-1 sm:grid-cols-2 md:grid-cols-4",
      5: "grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5",
      6: "grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6",
    };

    const tableWrapper = document.createElement("div");
    tableWrapper.className = `grid ${
      responsiveClasses[columns] || "grid-cols-1"
    } gap-4 p-4 my-4 relative editor-table`; // Add editor-table class for easier identification

    for (let i = 0; i < rows * columns; i++) {
      const cell = document.createElement("div");
      cell.className =
        "shadow-mg rounded-lg bg-gradient-to-tl from-indigo-600/25 p-2 text-white flex items-center justify-center flex-col editor-content relative"; // Initial alignment as column
      cell.contentEditable = "true";
      tableWrapper.appendChild(cell);

      // Ajouter un bouton pour insérer une image dans la cellule
      const insertImageButton = document.createElement("button");
      insertImageButton.innerText = "Insert Image";
      insertImageButton.className =
        "mt-2 bg-blue-500 text-white p-1 rounded editor-control"; // Add class editor-control
      insertImageButton.contentEditable = "false"; // Make button non-editable
      insertImageButton.onclick = () => {
        setActiveCell(cell);
        dispatch(setOpenDi()); // Open modal to select image
      };

      const textWrapper = document.createElement("div");
      textWrapper.className =
        "flex-grow p-2 text-white editor-content relative"; // Add class editor-content
      textWrapper.contentEditable = "true"; // Texte éditable
      textWrapper.innerHTML = "<p>Enter your text here...</p>"; // Placeholder text

      // Ajouter un bouton pour supprimer le texte
      const deleteTextButton = document.createElement("button");
      deleteTextButton.innerText = "Delete Text";
      deleteTextButton.className =
        "absolute top-2 right-2 bg-red-500 text-white p-1 rounded editor-control"; // Add class editor-control
      deleteTextButton.contentEditable = "false"; // Make button non-editable
      deleteTextButton.onclick = () => {
        textWrapper.remove();
      };
      textWrapper.appendChild(deleteTextButton);

      cell.appendChild(textWrapper);
      cell.appendChild(insertImageButton);

      // Ajouter un bouton pour supprimer la cellule
      const deleteCellButton = document.createElement("button");
      deleteCellButton.innerText = "Delete Cell";
      deleteCellButton.className =
        "absolute top-2 right-2 bg-red-500 text-white p-1 rounded editor-control"; // Add class editor-control
      deleteCellButton.contentEditable = "false"; // Make button non-editable
      deleteCellButton.onclick = () => {
        cell.remove();
      };
      cell.appendChild(deleteCellButton);
    }

    // Ajouter un bouton pour supprimer le tableau
    const deleteTableButton = document.createElement("button");
    deleteTableButton.innerText = "Delete Table";
    deleteTableButton.className =
      "absolute top-2 right-2 bg-red-500 text-white p-1 rounded editor-control"; // Add class editor-control
    deleteTableButton.contentEditable = "false"; // Make button non-editable
    deleteTableButton.onclick = () => {
      tableWrapper.remove();
    };
    tableWrapper.appendChild(deleteTableButton);

    editor.appendChild(tableWrapper);
    setContentHtml(editor.innerHTML);
  };

  console.log(activeCell);
  useEffect(() => {
    if (urlEditor && activeCell) {
      const imgWrapper = document.createElement("div");
      imgWrapper.className = "w-full flex justify-center my-2"; // Wrapper to ensure the same size

      const img = document.createElement("img");
      img.src = urlEditor;
      img.className = "object-cover rounded"; // Classe Tailwind pour les dimensions et le style de l'image
      img.style.maxWidth = "100%"; // Responsive
      img.contentEditable = "false"; // Image non éditable

      imgWrapper.appendChild(img);

      const wrapper = document.createElement("div");
      wrapper.className = "flex flex-col items-center justify-center w-full"; // Initial alignment as column
      wrapper.contentEditable = "false";

      const textWrapper = document.createElement("div");
      textWrapper.className = "flex-grow p-2 text-white editor-content"; // Add class editor-content
      textWrapper.contentEditable = "true"; // Texte éditable
      textWrapper.innerHTML = "<p>Enter your text here...</p>"; // Placeholder text

      wrapper.appendChild(imgWrapper);
      wrapper.appendChild(textWrapper);

      const dropdown = document.createElement("div");
      dropdown.className = "relative editor-control"; // Add class editor-control
      dropdown.innerHTML = `
      <button class="bg-gray-500 text-white p-1 rounded">Options</button>
      <div class="absolute hidden mt-1 bg-white border border-gray-300 rounded shadow-lg z-10">
        <button class="block px-4 py-2 text-sm text-gray-700 align-vertical">Align Vertical</button>
        <button class="block px-4 py-2 text-sm text-gray-700 align-horizontal">Align Horizontal</button>
        <button class="block px-4 py-2 text-sm text-gray-700 increase-size">Increase Size</button>
        <button class="block px-4 py-2 text-sm text-gray-700 decrease-size">Decrease Size</button>
        <button class="block px-4 py-2 text-sm text-gray-700 move-image-top">Move Image Above Text</button>
        <button class="block px-4 py-2 text-sm text-gray-700 move-image-bottom">Move Image Below Text</button>
        <button class="block px-4 py-2 text-sm text-gray-700 move-image-left">Move Image Left of Text</button>
        <button class="block px-4 py-2 text-sm text-gray-700 move-image-right">Move Image Right of Text</button>
        <button class="block px-4 py-2 text-sm text-red-700 delete-image">Delete Image</button>
      </div>
    `;

      const dropdownMenu = dropdown.querySelector("div");
      dropdown.querySelector("button").onclick = () => {
        dropdownMenu.classList.toggle("hidden");
      };

      dropdown.querySelector(".align-vertical").onclick = () => {
        wrapper.className = "flex flex-col items-center justify-center w-full";
        dropdownMenu.classList.add("hidden");
      };

      dropdown.querySelector(".align-horizontal").onclick = () => {
        wrapper.className = "flex flex-row items-center justify-center w-full";
        dropdownMenu.classList.add("hidden");
      };

      dropdown.querySelector(".increase-size").onclick = () => {
        img.style.maxHeight = `${
          parseInt(img.style.maxHeight || "100") + 50
        }px`;
        dropdownMenu.classList.add("hidden");
      };

      dropdown.querySelector(".decrease-size").onclick = () => {
        const newSize = parseInt(img.style.maxHeight || "100") - 50;
        img.style.maxHeight = `${newSize > 50 ? newSize : 50}px`;
        dropdownMenu.classList.add("hidden");
      };

      dropdown.querySelector(".move-image-top").onclick = () => {
        wrapper.insertBefore(imgWrapper, textWrapper);
        wrapper.className = "flex flex-col items-center justify-center w-full";
        dropdownMenu.classList.add("hidden");
      };

      dropdown.querySelector(".move-image-bottom").onclick = () => {
        wrapper.appendChild(imgWrapper);
        wrapper.className = "flex flex-col items-center justify-center w-full";
        dropdownMenu.classList.add("hidden");
      };

      dropdown.querySelector(".move-image-left").onclick = () => {
        wrapper.insertBefore(imgWrapper, textWrapper);
        wrapper.className = "flex flex-row items-center justify-center w-full";
        dropdownMenu.classList.add("hidden");
      };

      dropdown.querySelector(".move-image-right").onclick = () => {
        wrapper.appendChild(imgWrapper);
        wrapper.className = "flex flex-row items-center justify-center w-full";
        dropdownMenu.classList.add("hidden");
      };

      dropdown.querySelector(".delete-image").onclick = () => {
        imgWrapper.remove();
        dropdownMenu.classList.add("hidden");
      };

      wrapper.appendChild(dropdown);
      activeCell.appendChild(wrapper);

      setActiveCell(null); // Reset the active cell after using it
      dispatch(setUrl("")); // Reset the URL after using it
    }
  }, [urlEditor, activeCell, dispatch]);

  const updateExistingCells = () => {
    const editor = editorRef.current;
    const cells = editor.querySelectorAll("div[contenteditable='true']");
    cells.forEach((cell) => {
      cell.classList.add("text-white");
    });
  };

  // Appelez cette fonction après avoir inséré un tableau ou chargé du contenu existant
  useEffect(() => {
    updateExistingCells();
  }, [contentHtml]);

  const handleJsxSubmit = () => {
    try {
      const editor = editorRef.current;
      if (!editor) return;

      const codeDiv = document.createElement("div");
      codeDiv.className = "jsx-code  p-2 ";
      codeDiv.contentEditable = "true";
      codeDiv.innerHTML = previewContent;
      editor.appendChild(codeDiv);

      setJsxCode("");
      setShowCodeInput(false);
    } catch (error) {
      console.error("Invalid JSX code:", error);
    }
  };

  const cleanStyles = () => {
    const editor = editorRef.current;
    const elements = editor.querySelectorAll("*");

    elements.forEach((element) => {
      element.style.background = "";
      element.style.fontSize = "";
      element.style.fontFamily = "";
    });
  };

  // Ajoutez un écouteur d'événements pour l'éditeur
  useEffect(() => {
    const editor = editorRef.current;
    const handleInput = () => {
      cleanStyles();
    };

    editor.addEventListener("input", handleInput);

    return () => {
      editor.removeEventListener("input", handleInput);
    };
  }, []);

  useEffect(() => {
    const editor = editorRef.current;
    const handleBlur = () => {
      cleanStyles();
    };

    editor.addEventListener("blur", handleBlur, true); // Utiliser la phase de capture

    return () => {
      editor.removeEventListener("blur", handleBlur, true);
    };
  }, []);

  const handleJsxPreview = () => {
    setPreviewContent(jsxCode);
  };

  const handleEditorChange = (value) => {
    setJsxCode(value);
  };

  const handleInsertTable = () => {
    insertTable(rows, columns);
  };

  useEffect(() => {
    if (searchParams.get("id")) {
      (async () => {
        const id = searchParams.get("id");

        const docRef = doc(db, "blogs", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          if (docSnap.data().description) {
            editorRef.current.innerHTML = docSnap.data().description;
            setContentHtml(docSnap.data().description);
            setAuthor(docSnap.data().author);
            setTitle(docSnap.data().title);
            dispatch(setType("bg"));
            dispatch(setUrl(docSnap.data().bg_url));
            setPreviewDescription(docSnap.data().preview_description);
          }
        } else {
          // docSnap.data() will be undefined in this case
          console.log("No such document!");
        }
      })();
    }
  }, [searchParams.get("id")]);

  useEffect(() => {
    if (searchParams.get("id")) {
      (async () => {
        const id = searchParams.get("id");

        const docRef = doc(db, "blogs", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          if (docSnap.data().description) {
            editorRef.current.innerHTML = docSnap.data().description;
            setContentHtml(docSnap.data().description);
            setAuthor(docSnap.data().author);
            setTitle(docSnap.data().title);
            dispatch(setType("bg"));
            dispatch(setUrl(docSnap.data().bg_url));
            setPreviewDescription(docSnap.data().preview_description);
          }
        } else {
          // docSnap.data() will be undefined in this case
          console.log("No such document!");
        }
      })();
    }
  }, [searchParams.get("id")]);

  return {
    editorRef,
    textCol,
    textColor,
    contentHtml,
    load,
    showToast,
    jsxCode,
    showCodeInput,
    previewContent,
    tableSize,
    setTextColor,
    setLoad,
    setShowToast,
    setJsxCode,
    setShowCodeInput,
    setPreviewContent,
    setPositionSize,
    handlePublish,
    addLink,
    applyStyle,
    createList,
    setTextAlignment,
    handleColorChange,
    handleJsxPreview,
    handleJsxSubmit,
    handleEditorChange,
    dispatch,
    setOpenDi,
    handleContentChange,
    handleInsertTable,
    setRows,
    setColumns,
    rows,
    columns,
    setShowTextStyleDropdown,
    showTextStyleDropdown,
    showTextStyleDropdownToolTip,
    setShowTextStyleDropdownToolTip,
    applyTextStyle,
    setTitle,
    titleC,
    setPreviewDescription,
    previewDescription,
    urlBg,
  };
};

export default useBlogEditor;
