"use client";

import {
  Button,
  Checkbox,
  Label,
  Modal,
  Spinner,
  TextInput,
} from "flowbite-react";
import moment from "moment";
import { useRef, useState } from "react";
import { db } from "../../firebase";
import { addDoc, collection } from "firebase/firestore";

export function ModalGetData({
  brochures = false,
  brochure_id,
  openModal,
  setOpenModal,
  document,
}) {
  const emailInputRef = useRef();
  const linkRef = useRef();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [load, setLoad] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoad(false);
    const visitor = await addDoc(collection(db, "visitors"), {
      name,
      email,
      phone,
      url: document,
      creatAt: moment.utc().unix(),
      type: brochures ? brochure_id : "terms",
    }).then(() => {
      setLoad(true);
      linkRef.current.click();
    });
  };

  return (
    <>
      {/*   <Button onClick={() => setOpenModal(true)}>Toggle modal</Button> */}
      <Modal
        show={openModal}
        size="md"
        popup
        onClose={() => setOpenModal(false)}
        initialFocus={emailInputRef}
      >
        <Modal.Header className=" bg-gradient-to-r from-orange-900/70 to-violet-800" />
        <Modal.Body className=" bg-gradient-to-r from-orange-900/70 to-violet-800">
          <form className="space-y-6" onSubmit={handleSubmit}>
            {brochures && (
              <>
                <h3 class="text-xl font-medium text-white">
                  Request Your Complimentary Brochure
                </h3>
                <p class="text-sm text-gray-300">
                  We are eager to introduce you to the exciting opportunities we
                  offer. To receive your personalized brochure, kindly enter
                  your name and phone number below. You'll gain access to the
                  most current updates and vital details tailored just for you.
                </p>
              </>
            )}

            {!brochures && (
              <>
                <h3 class="text-xl font-medium text-white">
                  Download Your Terms Document
                </h3>
                <p class="text-sm text-gray-300">
                  We appreciate your interest in our services. To proceed,
                  please provide your name and phone number below. This will
                  allow us to send you the necessary documentation and keep you
                  informed with the latest information directly.
                </p>
              </>
            )}

            <div>
              <div className="mb-2 block">
                <Label htmlFor="name" value="Name" className="text-gray-300" />
              </div>
              <TextInput
                id="name"
                onChange={(e) => setName(e.target.value)}
                ref={emailInputRef}
                placeholder="John Smith"
                required
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label
                  htmlFor="email"
                  value="Your email"
                  className="text-gray-300"
                />
              </div>
              <TextInput
                id="email"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="name@company.com"
                required
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label
                  htmlFor="tel"
                  value="Phone number"
                  className="text-gray-300"
                />
              </div>
              <TextInput
                id="tel"
                type="tel"
                onChange={(e) => setPhone(e.target.value)}
                required
              />
            </div>
            <a ref={linkRef} className="hidden" href={document}>
              Get your document
            </a>
            <div className="w-full flex justify-center items-center space-x-3 text-gray-300">
              {load && (
                <>
                  <button href={document}>Get your document</button>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-5 animate-bounce"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                    />
                  </svg>
                </>
              )}
              {!load && <Spinner aria-label="Default status example" />}
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
