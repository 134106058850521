import { PlusIcon } from "@heroicons/react/24/outline";
import { FadeIn, FadeInStagger } from "../components/utils/FadeIn";
import { ContactSection } from "../components/utils/ContactSection";
import JobTemplate from "../components/utils/job-template";
import SideMenu from "../components/utils/side-menu";
import { Container } from "../components/utils/Container";
import { Border } from "../components/utils/Border";
import SearchBar from "../components/utils/search-bar";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import {
  collection,
  endBefore,
  getDocs,
  limit,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
import { Spinner } from "flowbite-react";
import { useSearchParams } from "react-router-dom";

const filter = [
  {
    title: "Experience",
    contents: [
      {
        name: "Mid Level",
        code: "mid-level",
        searchKey: "experience",
      },
      {
        name: "Director",
        code: "director",
        searchKey: "experience",
      },
      {
        name: "Senior Level",
        code: "senior-level",
        searchKey: "experience",
      },
    ],
  },

  {
    title: "Locations",
    contents: [
      {
        name: "Middle East",
        code: "middle_east",
        searchKey: "zone",
      },
      {
        name: "Europe",
        code: "europe",
        searchKey: "zone",
      },

      {
        name: "Africa",
        code: "africa",
        searchKey: "zone",
      },
    ],
  },
];

export default function Jobs() {
  const [jobs, setJobs] = useState([]);
  const [load, setLoad] = useState(true);
  const [lastVisible, setLastVisible] = useState("");
  const [firstVisible, setFirstVisible] = useState("");
  const [search, setSearch] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const filterRef = useRef();
  const [showFiler, setShowFilter] = useState(true);
  useEffect(() => {
    const abortController = new AbortController();

    const fetchClientAndJobs = async () => {
      try {
        setLoad(false); // Commencer le chargement
        await Promise.all([fetchData()]);
      } catch (error) {
        console.error("an error occurred", error);
        // Ici, gérer l'affichage d'un message d'erreur à l'utilisateur si nécessaire
      } finally {
        setLoad(true); // Terminer le chargement, que l'opération réussisse ou échoue
      }
    };

    if (!abortController.signal.aborted) {
      fetchClientAndJobs();
    }

    return () => {
      setJobs([]);
      abortController.abort(); // Optionnel : Annuler les requêtes en cours si elles n'ont pas encore abouti
    };
  }, [
    search,
    searchParams.get("search"),
    searchParams.get("zone"),
    searchParams.get("experience"),
  ]);

  async function fetchJobs(direction = "next") {
    setLoad(false);
    let newQuery;
    if (direction === "next") {
      newQuery = query(
        collection(db, "jobs"),
        where("open", "==", true),
        startAfter(lastVisible),
        limit(10)
      );
    } else {
      // prev
      newQuery = query(
        collection(db, "jobs"),
        where("open", "==", true),
        endBefore(firstVisible),
        limit(4)
      );
    }

    try {
      const querySnapshot = await getDocs(newQuery);
      if (!querySnapshot.empty) {
        const last = querySnapshot.docs[querySnapshot.docs.length - 1];
        const first = querySnapshot.docs[0];
        setLastVisible(last);
        setFirstVisible(first);

        const clientsArray = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }));
        setJobs(clientsArray);
      }
    } catch (error) {
      console.error("an error occurred while fetching jobs", error);
    } finally {
      setLoad(true);
    }
  }

  const fetchData = async () => {
    try {
      setLoad(false);
      const jobsRef = collection(db, "jobs");
      const locationFilter = searchParams.get("zone");
      const experience = searchParams.get("experience");

      let q;
      if (search) {
        // Start of the string
        const startAt = search;
        // End of the search range: Increment the last character of the searchTerm
        // and use it to define the upper limit for the query
        const endAt =
          search.slice(0, -1) +
          String.fromCharCode(search.charCodeAt(search.length - 1) + 1);

        q = query(
          jobsRef,
          where("position_name", ">=", search),
          where("position_name", "<=", search + "~"),
          where("open", "==", true),
          limit(10)
        );
      } else if (locationFilter) {
        q = query(
          jobsRef,
          where("zone", "==", locationFilter),
          where("open", "==", true),
          limit(10)
        );
      } else if (experience) {
        q = query(
          jobsRef,
          where("experience", "==", experience),
          where("open", "==", true),
          limit(10)
        );
      } else {
        q = query(jobsRef, where("open", "==", true), limit(10));
      }

      const querySnapshot = await getDocs(q);

      const last = querySnapshot.docs[querySnapshot.docs.length - 1];
      const first = querySnapshot.docs[0];
      setLastVisible(last);
      setFirstVisible(first);

      const jobsArray = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));

      setLoad(true);
      setJobs(jobsArray);
    } catch (error) {
      console.error("An error occurred while fetching", error);
    }
  };

  const handleShowFilter = () => {
    if (showFiler) {
      setShowFilter(false);
    } else {
      setShowFilter(true);
    }
  };

  return (
    <div className="relative overflow-hidden">
      <div className="absolute w-[700px] h-[700px] rounded-full  -z-0 bg-indigo-800 opacity-30 blur-3xl" />
      <div className="absolute w-[700px] h-[700px] rounded-full -z-0  right-2  bg-orange-800 opacity-30 blur-3xl" />

      <div className="absolute w-[700px] h-[700px] rounded-full -z-0  bottom-1/2 left-1/2  bg-teal-800 opacity-30 blur-3xl" />
      <div className="py-32  relative    ">
        <div className="max-w-[85rem] px-4 xl:px-0 mx-auto   mt-6  ">
          <div className="  flex justify-start items-center  ">
            <div>
              <h1 className="lg:text-8xl md:text-6xl text-4xl   font-bold   bg-clip-text py-2 text-transparent bg-gradient-to-r from-orange-500 to-violet-600">
                Find Your Dream Role
              </h1>

              <p className=" md:text-lg text-sm max-w-2xl text-gray-300">
                Immerse yourself in a realm of endless possibilities, where
                innovative roles align seamlessly with your highest aspirations.
                Embark on a journey and redefine what’s possible for your
                professional life.
              </p>
            </div>
          </div>
        </div>

        <SearchBar landing={false} search={setSearch} />

        <div className=" mt-10">
          <div className="max-w-[85rem] px-4 xl:px-0 mx-auto">
            <div
              ref={filterRef}
              className=" flex  flex-row justify-center items-center gap-4 "
            >
              {showFiler &&
                filter.map((el) => (
                  <div className="w-1/2">
                    <h1
                      onClick={(e) =>
                        console.log(
                          e.currentTarget.nextSibling.classList.toggle("hidden")
                        )
                      }
                      className="text-lg my-4 w-full justify-center  inline-flex items-center space-x-2 cursor-pointer  text-white    border rounded-xl p-4  border-orange-600"
                    >
                      <span>{el.title}</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-4 h-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m19.5 8.25-7.5 7.5-7.5-7.5"
                        />
                      </svg>
                    </h1>
                    <ul className="py-2 w-1/3 space-y-3 z-[10000000]  hidden absolute bg-gray-800 p-3 rounded-lg ">
                      {el.contents.map((sub_el) => (
                        <li className="w-full">
                          <div class="flex w-full relative">
                            <input
                              onClick={(e) =>
                                e.currentTarget.parentElement.parentElement.parentElement.classList.toggle(
                                  "hidden"
                                )
                              }
                              type="checkbox"
                              name={sub_el.searchKey}
                              id={sub_el.name}
                              checked={
                                sub_el.code ===
                                searchParams.get(sub_el.searchKey)
                              }
                              onChange={(e) =>
                                e.target.checked
                                  ? setSearchParams({
                                      [sub_el.searchKey]: sub_el.code,
                                    })
                                  : setSearchParams({})
                              }
                              class="peer hidden"
                            />
                            <label
                              for={sub_el.name}
                              class="select-none cursor-pointer w-full  
   py-3 px-6  font-medium text-gray-200 transition-colors duration-200 ease-in-out peer-checked:bg-orange-600 peer-checked:text-white peer-checked:border-gray-200 "
                            >
                              {sub_el.name}
                            </label>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
            </div>

            {load && (
              <div className=" grid   py-10 gap-x-6 px-4 ">
             
                <div className="  flex flex-col gap-x-8 ">
                  <div className="mx-auto flex w-full flex-col relative justify-between gap-16 lg:mx-0 lg:max-w-none lg:flex-row">
                    <FadeInStagger className="w-full  lg:flex-auto">
                      <h3 className="sr-only">Job openings</h3>

                      <div className="grid md:grid-cols-2  gap-4">
                        {jobs && jobs.map((job) => <JobTemplate job={job} />)}
                        {jobs.length === 0 && (
                          <div className=" p-8 flex justify-center items-center col-span-full border-dashed border-white border rounded-md">
                            <h1 className="text-gray-200 animate-pulse">
                              No more job to load
                            </h1>
                          </div>
                        )}
                      </div>
                    </FadeInStagger>
                  </div>

                  <div className=" inline-flex space-x-4 justify-end">
                    <motion.a
                      role="button"
                      onClick={() => fetchJobs("prev")}
                      whileHover={{
                        scale: 1.002,

                        color: "white",
                      }}
                      whileTap={{ scale: 0.9 }}
                      transition={{
                        type: "spring",
                        stiffness: 400,
                        damping: 17,
                      }}
                      className="   h-14 px-8 pt-5 pb-6  mt-12  bg-transparent hover:bg-orange-600 rounded border-2 border-orange-600 justify-center items-center inline-flex"
                    >
                      <div className="  h-4  text-orange-50 text-sm font-medium  uppercase ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 19.5 8.25 12l7.5-7.5"
                          />
                        </svg>
                      </div>
                    </motion.a>
                    <motion.a
                      role="button"
                      onClick={() => fetchJobs("next")}
                      whileHover={{
                        scale: 1.002,

                        color: "white",
                      }}
                      whileTap={{ scale: 0.9 }}
                      transition={{
                        type: "spring",
                        stiffness: 400,
                        damping: 17,
                      }}
                      className="   h-14 px-8 pt-5 pb-6  mt-12  bg-transparent hover:bg-orange-600 rounded justify-center items-center inline-flex border-2 border-orange-600"
                    >
                      <div className="  h-4  text-orange-50 text-sm font-medium  uppercase ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m8.25 4.5 7.5 7.5-7.5 7.5"
                          />
                        </svg>
                      </div>
                    </motion.a>
                  </div>
                </div>
              </div>
            )}

            {!load && (
              <div className="h-96 w-full relative">
                <div className=" absolute inset-0 flex justify-center items-center">
                  <Spinner aria-label="Extra large spinner example" size="xl" />
                </div>
              </div>
            )}
          </div>
        </div>

        <ContactSection />
      </div>
    </div>
  );
}
