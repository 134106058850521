import React, { useEffect, useState } from "react";
import { FadeIn, FadeInStagger } from "../../components/utils/FadeIn";

import GoBack from "../../components/utils/goBack";
import { Spinner } from "flowbite-react";
import { db } from "../../firebase";
import { collection, doc, getDoc, getDocs, query } from "firebase/firestore";
import JobTemplate from "../../components/utils/job-template";
import ToogleSwitch from "../../components/utils/toogleSwitch";

function LivePosition() {
  const [jobs, setJobs] = useState([]);
  const [load, setLoad] = useState(true);

  useEffect(() => {
    const abortController = new AbortController();

    const fetchClientAndJobs = async () => {
      try {
        setLoad(false); // Commencer le chargement
        await Promise.all([fetchData()]);
      } catch (error) {
        console.error("an error occurred", error);
        // Ici, gérer l'affichage d'un message d'erreur à l'utilisateur si nécessaire
      } finally {
        setLoad(true); // Terminer le chargement, que l'opération réussisse ou échoue
      }
    };

    if (!abortController.signal.aborted) {
      fetchClientAndJobs();
    }

    return () => {
      setJobs([]);
      abortController.abort(); // Optionnel : Annuler les requêtes en cours si elles n'ont pas encore abouti
    };
  }, []);

  const fetchData = async () => {
    try {
      setLoad(false);
      const q = query(collection(db, "jobs"));
      const querySnapshot = await getDocs(q);

      const jobsArray = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));

      setLoad(true);
      setJobs(jobsArray);
    } catch (error) {
      console.error("an error occurred while fetching", error);
    }
  };

  return (
    <div className=" bg-gradient-to-br from-gray-700/5 bg-gray-300 p-4 min-h-[calc(100vh-4rem)] relative">
      <h1 className="md:text-3xl text-lg p-4 shadow items-center space-x-3  rounded-2xl inline-flex bg-orange-600 w-fit text-white">
        <GoBack /> <span>Live position</span>
      </h1>

      {!load && (
        <div className=" absolute inset-0 flex justify-center items-center">
          <Spinner aria-label="Extra large spinner example" size="xl" />
        </div>
      )}

      {load && (
        <div className="mx-auto mt-10 grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 sm:mt-15 lg:mx-0 lg:max-w-none md:grid-cols-2 ">
          {jobs &&
            jobs.map((job) => (
              <JobTemplate
                admin
                landing={false}
                job={job}
                Extracomponent={
                  <ToogleSwitch
                    setLoad={setLoad}
                    state={job.data.open}
                    id={job.id}
                    f={fetchData}
                  />
                }
              />
            ))}
        </div>
      )}
    </div>
  );
}

export default LivePosition;
